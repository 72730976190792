/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "../../core/firebase.core";
import { signOut, onAuthStateChanged } from "firebase/auth";
import Main from "./components/ProfileMain";
import "./index.css";
import Menu from "../../components/Menu";
function Profile() {
  const auth = firebaseAuth;
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        if (user.isAnonymous == true) {
          navigate("/");
        }
      } else {
        navigate("/sign-in");
      }
    });
  }, [auth]);
  return (
    <div>
      <Menu></Menu>
      <div className="xl:container mx-auto pb-5 pt-5">
        <div className="form-container flex flex-wrap">
          <div className="form-left lg:w-4/4 sm:w-full md:w-full w-full bg-profile-bg">
            <Main />
          </div>
        </div>

        {/* <div class="container mx-auto">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">1</div>
                    <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">2</div>
                    <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">3</div>
                    <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">4</div>
                </div>
            </div> */}
      </div>
    </div>
  );
}

export default Profile;
