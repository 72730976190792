import { createAsyncThunk } from "@reduxjs/toolkit";
import AssetsService from "../../services/assets.service";
import { AssetModule } from "../share";

const assetsService = new AssetsService();

export const fetchCountryListAsset = createAsyncThunk(
  `${AssetModule}/fetchCountryListAsset`,
  async (thunkAPI) => {
    try {
      const result = await assetsService.getCountryList();
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const fetchAllPreFullPricesReserve = createAsyncThunk(
  `${AssetModule}/fetchAllPreFullPricesReserve`,
  async (thunkAPI) => {
    try {
      const result = await assetsService.getAllPreFullPricesReserve();
      return result.allPirces || [];
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);
