import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserService } from "../../services/user.service";
import { UserModule } from "../share";

const userService = new UserService();
export const fetchUserInfo = createAsyncThunk(`${UserModule}/fetchUserInfo`, async (thunkAPI) => {
  try {
    const result = await userService.getUserInfo();
    return result;
  } catch (error) {
    thunkAPI.rejectWithValue(error.toString());
  }
});
