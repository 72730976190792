import { createSlice } from "@reduxjs/toolkit";
import { StatusData } from "../../models/module";
import { fetchProductDetail, fetchProductModelAll } from "./thunk";
import { ProductModelModule } from "../share";

const initialState = {
  currentProduct: {
    status: StatusData.idle,
    data: [],
    error: null
  },
  productList: {
    status: StatusData.idle,
    data: [],
    error: null
  }
};

const productModel = createSlice({
  name: ProductModelModule,
  initialState,
  reducers: {
    updateCurrentProduct(state, action) {
      state.currentProduct.data = action.payload;
      state.currentProduct.status = StatusData.succeeded;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductModelAll.pending, (state) => {
      state.productList.status = StatusData.pending;
    }),
      builder.addCase(fetchProductModelAll.fulfilled, (state, action) => {
        state.productList.data = action.payload;
        state.productList.status = StatusData.succeeded;
      }),
      builder.addCase(fetchProductModelAll.rejected, (state, action) => {
        state.productList.error = action.payload;
        state.productList.status = StatusData.failed;
      });
    builder.addCase(fetchProductDetail.pending, (state) => {
      state.currentProduct.status = StatusData.pending;
    });
    builder.addCase(fetchProductDetail.fulfilled, (state, action) => {
      // state.currentProduct = {
      //   status: StatusData.succeeded,
      //   data: action.payload
      // };
      state.currentProduct.data = action.payload;
      state.currentProduct.status = StatusData.succeeded;
    });
    builder.addCase(fetchProductDetail.rejected, (state, action) => {
      // state.currentProduct = {
      //   status: StatusData.failed,
      //   error: action.error
      // };
      state.currentProduct.error = action.payload;
      state.currentProduct.status = StatusData.failed;
    });
  }
});

export const { updateCurrentProduct } = productModel.actions;

export default productModel.reducer;
