import { createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "../../services/order.service";
import { OrdersModule } from "../share";

const orderService = new OrderService();
export const fetchOrderInfoById = createAsyncThunk(
  `${OrdersModule}/fetchOrderInfoById`,
  async (orderId, thunkAPI) => {
    try {
      const order = await orderService.getOrderDetail(orderId);
      return order;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);
