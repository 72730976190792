/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "./assets/images/logo.png";
import { Breakpoint } from "../../theme/config.them";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../../components/Menu";
import "./index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import firebaseAuth from "../../core/firebase.core";
import { signInWithEmailAndPassword } from "firebase/auth";
import { t } from "i18next";
function ReSetPassword() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const { id } = useParams();
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorRePassword, setErrorRePassword] = useState(null);
  const [formData, setFormData] = useState({
    token: id,
    password: null,
    rePassword: null
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {}, []);
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    if (event.target.name === "password") {
      if (event.target.value === null) {
        setErrorPassword(t("This field is required."));
      } else {
        setErrorPassword(null);
      }
    }
    if (event.target.name === "rePassword") {
      if (event.target.value === null) {
        setErrorRePassword(t("This field is required."));
      } else {
        setErrorRePassword(null);
      }
    }
  };
  const submitForm = (event) => {
    event.preventDefault();
    if (!formData.password) {
      setErrorPassword(t("This field is required."));
    } else {
      setErrorPassword(null);
    }
    if (!formData.rePassword) {
      setErrorRePassword(t("This field is required."));
    } else {
      setErrorRePassword(null);
    }
    if (!id) {
      setErrorPassword(t("Token not found."));
    } else {
      setErrorPassword(null);
    }
    if (formData.password && formData.password.length < 6) {
      setErrorRePassword(t("Please fill in at least 6 characters"));
    }
    if (formData.password != formData.rePassword) {
      setErrorRePassword(t("Passwords do not match"));
    }

    if (
      formData.password &&
      id &&
      formData.password === formData.rePassword &&
      formData.password.length >= 6
    ) {
      setIsLoading(true);
      const getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/customer/changePasswordWithToken";
      const request = {
        url: getValURL,
        method: "patch",
        data: formData,
        headers: {
          "content-type": "application/json"
        }
      };
      axios(request)
        .then(function (response) {
          if (response.status == 200) {
            if (response.data.code == 1) {
              const auth = firebaseAuth;
              if (response.data.email && response.data.password) {
                signInWithEmailAndPassword(auth, response.data.email, response.data.password)
                  .then((res) => {
                    setIsLoading(false);
                    sessionStorage.setItem("emailUser", res.user.email);
                    sessionStorage.setItem("uid", res.user.uid);
                    navigate("/has-been-changed");
                  })
                  .catch(function (message) {
                    setIsLoading(false);
                    setErrorPassword(message.message);
                  });
              }
            } else {
              MySwal.fire({
                title: response.data.message,
                icon: "error",
                showConfirmButton: true
              }).then(() => {
                setIsLoading(false);
              });
            }
          }
        })
        .catch(function (error) {});
    }
  };
  return (
    <div className="h-fit bg-login ">
      <Menu></Menu>
      <div className=" px-4 md:px-14 grid grid-cols-1 md:grid-cols-1 justify-items-center  gap-6 content-center py-10 ">
        <div className="w-full max-w-md">
          <form onSubmit={submitForm} className=" shadow-md rounded px-8 md:px-10 py-10 box-login">
            <div className="flex justify-center mb-6">
              <img src={logo} alt="Logo" className="h-20" />
            </div>
            <h1 className="text-4xl font-normal text-center text-white pb-8 text-xl">
              {t("Reset password")}
            </h1>
            <div className="mb-10">
              <input
                className="dark:text-white style-input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white leading-tight focus:outline-none focus:shadow-outline"
                name="password"
                onChange={handleChange}
                value={formData.password || ""}
                type="password"
                placeholder={t("New password")}
              />
              {errorPassword && (
                <p
                  style={{ textShadow: "1px 1px 4px white" }}
                  className="text-[#7C0004] font-semibold">
                  {errorPassword}
                </p>
              )}
            </div>
            <div className="mb-20">
              <input
                className="dark:text-white style-input shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white mb-3 leading-tight focus:outline-none focus:shadow-outline"
                name="rePassword"
                onChange={handleChange}
                value={formData.rePassword || ""}
                type="password"
                placeholder={t("Confirm your new password")}
              />
              {errorRePassword && (
                <p
                  style={{ textShadow: "1px 1px 4px white" }}
                  className="text-[#7C0004] font-semibold">
                  {errorRePassword}
                </p>
              )}
            </div>

            <div>
              <button
                className="w-full btn-login text-white  py-2 px-4 focus:outline-none focus:shadow-outline"
                type="submit">
                {isLoading ? <span className="loader" /> : "Reset"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ReSetPassword;
