import http from "../core/http.core";

class ModelPropertyService {
  constructor() {
    this.path = "property-models";
  }

  async getModelPropertyListByModelId(modelId) {
    const { data } = await http.get(`${this.path}`, { params: { modelId } });
    return data;
  }
}

export default ModelPropertyService;
