export class TreeNode {
  constructor() {
    this.treeNodes = [];
  }

  recurse(nodeTree, childCount, theParentID) {
    if (typeof nodeTree !== "undefined") {
      //Process the children of this node tree
      for (let i = 0; i < childCount; i++) {
        let node = {
          name: nodeTree.children[i].name,
          type: nodeTree.children[i].type,
          instanceID: nodeTree.children[i].instanceID,
          isParent: false,
          parentID: theParentID
        };

        if (node.type === "MatrixTransform") {
          //Determine if this node is a parent
          node.isParent = this.isParent(nodeTree.children[i].children);

          //Add this node to the complete node array list we are constructing
          this.treeNodes.push(node);

          this.recurse(
            nodeTree.children[i],
            nodeTree.children[i].children.length,
            nodeTree.children[i].instanceID
          );
        }
      }
    }
  }

  isParent(children) {
    let result = false;

    for (let i = 0; i < children.length; i++) {
      if (children[i].type === "MatrixTransform") {
        result = true;
        break;
      } else {
        result = false;
      }
    }

    return result;
  }

  getAllNodes() {
    return this.treeNodes;
  }
}
