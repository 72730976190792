import { createSlice } from "@reduxjs/toolkit";
import { StatusData } from "../../models/module";
import { UserModule } from "../share";
import { fetchUserInfo } from "./thunk";

const initialState = {
  status: StatusData.idle,
  data: null,
  error: null
};

const userSlice = createSlice({
  name: UserModule,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.status = StatusData.pending;
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.status = StatusData.succeeded;
      state.data = action.payload;
    });
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.status = StatusData.failed;
      state.error = action.payload;
    });
  }
});

export default userSlice.reducer;
