import { useSelector } from "react-redux";
import SummaryItem from "../SummaryItem";
import i18next from "i18next";

const SummaryGroupSection = ({ group, options = [] }) => {
  const currency = useSelector((state) => state.currency);
  return (
    <div className="flex flex-col items-stretch space-y-1">
      <p className=" font-bold">{group}</p>
      <ul className="list-outside list-disc pl-5">
        {options.map((option, index) => (
          <li key={index}>
            <SummaryItem
              title={option.language?.translation[i18next.language]}
              currency={currency.prefix}
              value={option.price[currency.dbKey]}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SummaryGroupSection;
