import { createSlice } from "@reduxjs/toolkit";
import { Currency } from "../../models/currency";
import { CurrencyModule } from "../share";

const CurrencySlice = createSlice({
  name: CurrencyModule,
  initialState: Currency.euro,
  reducers: {
    setCurrency(state, action) {
      state = action.payload;
      return state;
    }
  }
});

export default CurrencySlice.reducer;
export const { setCurrency } = CurrencySlice.actions;
