import http from "../core/http.core";

class CustomizationTemporary {
  constructor() {
    this.path = "customization-temporary";
  }

  async getTempCustomization(params) {
    const { data } = await http.get(`${this.path}/customer`, { params });
    return data;
  }

  async updateTempCustomization(id, data) {
    const result = await http.put(`${this.path}/${id}`, data);
    return result;
  }

  async updateTempCustomizationPropertyList(_id, data) {
    const result = await http.patch(`${this.path}/${_id}/properties`, data);
    return result.data;
  }

  async createTempCustomization(data) {
    const result = await http.post(this.path, data);
    return result.data;
  }
}

export default CustomizationTemporary;
