/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import logo from "./assets/images/logo.png";
import checkLogo from "./assets/images/check_box_img.png";
import { Breakpoint } from "../../theme/config.them";
import Menu from "../../components/Menu";
import "./index.css";
import firebaseAuth from "../../core/firebase.core";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged
} from "firebase/auth";
import { authenticate } from "../../modules/authentication";
import { t } from "i18next";
function SignIn() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorUsername, setErrorUsername] = useState(null);
  const [errorPass, setErrorPass] = useState(null);
  const auth = firebaseAuth;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const getValURL =
        process.env.REACT_APP_DOMAIN_NAME + "/crm/customer/getEmailPasswordWithToken";
      const request = {
        url: getValURL,
        method: "post",
        data: { token: id },
        headers: {
          "content-type": "application/json"
        }
      };
      axios(request)
        .then(function (response) {
          const username = response.data.email;
          const password = response.data.password;
          const auth = firebaseAuth;
          if (username && password) {
            signInWithEmailAndPassword(auth, username, password)
              .then((res) => {
                // navigate("/");
                sessionStorage.setItem("emailUser", res.user.email);
                sessionStorage.setItem("uid", res.user.uid);
              })
              .catch(function (message) {
                console.log("Error signing in:", message.message);
                setErrorPass(message.message);
              });
          }
        })
        .catch(function (error) {
          //
          console.log(error);
        });
    }
    onAuthStateChanged(auth, async (user) => {
      if (user && user.uid && !id) {
        if (user.isAnonymous == false) {
          navigate("/profile");
        }
      }
    });
    // eslint-disable-next-line
    }, [auth]);
  const handleFocusOut = (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let pattern = /[#]/;
    if (event.target.name === "username") {
      if (event.target.value === null) {
        setErrorUsername(t("This field is required."));
      } else if (emailRegex.test(event.target.value) == false) {
        setErrorUsername(t("Please enter the correct email"));
      } else if (pattern.test(event.target.value) == true) {
        setErrorUsername(t("Please enter the correct email"));
      } else {
        setErrorUsername(null);
      }
    }
    if (event.target.name === "password") {
      if (event.target.value === null) {
        setErrorPass(t("This field is required."));
      } else {
        setErrorPass(null);
      }
    }
  };
  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "username") {
      setUsername(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };
  const submitLogin = (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const pattern = /[#]/;

    if (!username) {
      setErrorUsername(t("This field is required."));
    } else if (emailRegex.test(username) == false) {
      setErrorUsername(t("Please enter the correct email"));
    } else if (pattern.test(username) == true) {
      setErrorUsername(t("Please enter the correct email"));
    } else {
      setErrorUsername(null);
    }
    if (!password) {
      setErrorPass(t("This field is required."));
    } else {
      setErrorPass(null);
    }
    const auth = firebaseAuth;
    if (
      username &&
      password &&
      emailRegex.test(username) == true &&
      pattern.test(username) == false
    ) {
      setIsLoading(true);
      signInWithEmailAndPassword(auth, username, password)
        .then((res) => {
          setIsLoading(false);
          navigate("/");
          // sessionStorage.setItem("authenticationToken", auth.currentUser);
          sessionStorage.setItem("emailUser", res.user.email);
          sessionStorage.setItem("uid", res.user.uid);
        })
        .catch(function (res) {
          if (res.code == "auth/user-not-found") {
            setErrorPass("User not found.");
          } else if (res.code == "auth/wrong-password") {
            setErrorPass("Password incorrect.");
          } else {
            setErrorPass(res.code);
          }
          setIsLoading(false);
        });
    }
  };

  return (
    // /Users/ploy/งาน/zapp_frontend/src/view/Profile/components/Menu.jsx
    <div className="h-fit bg-login">
      <Menu></Menu>
      {!id && (
        <>
          <div className="px-4 md:px-14 grid grid-cols-1 md:grid-cols-1 justify-items-center  gap-6 content-center py-10">
            <div className="w-full max-w-md">
              <form
                onSubmit={submitLogin}
                className=" shadow-md rounded px-8 md:px-10 py-10 box-login"
                autoComplete="off">
                <div className="flex justify-center mb-6">
                  <img src={logo} alt="Logo" className="h-20" />
                </div>
                <h1 className="text-4xl font-normal text-center text-white pb-8 text-xl">
                  {t("Welcome Back !")}
                </h1>
                <div className="mb-4">
                  <input
                    value={username || ""}
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    autoComplete="off"
                    className="dark:text-white style-input shadow appearance-none border rounded w-full py-2 px-3 placeholder-gray-200 text-white mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    name="username"
                    type="text"
                    placeholder={t("Email Address")}
                  />
                  {errorUsername && (
                    <p
                      style={{ textShadow: "1px 1px 4px white" }}
                      className="text-[#7C0004] font-semibold">
                      {errorUsername}
                    </p>
                  )}
                </div>
                <div className="mb-10">
                  <input
                    value={password || ""}
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    autoComplete="new-password"
                    className="dark:text-white style-input shadow appearance-none border border-red-500 rounded w-full py-2 px-3 placeholder-gray-200 text-white mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    name="password"
                    type="password"
                    placeholder={t("Password")}
                  />
                  {errorPass && (
                    <p
                      style={{ textShadow: "1px 1px 4px white" }}
                      className="text-[#7C0004] font-semibold">
                      {errorPass}
                    </p>
                  )}
                </div>
                {/* <div className="mb-10 flex">
                                <label className="block text-gray-500 font-bold">
                                    <input className="mr-2 leading-tight h-4 w-4 pt-2" type="checkbox" />
                                    <span className="text-sm text-white font-normal" >
                                        Remember password
                                    </span>
                                </label>
                            </div> */}
                <div>
                  <button
                    className="w-full btn-login text-white  py-2 px-4 focus:outline-none focus:shadow-outline"
                    type="submit">
                    {isLoading ? <span className="loader" /> : t("Login")}
                  </button>
                </div>
                <div className="text-center mt-3 text-white text-sm">
                  {t("Don’t have an account yet ?")}
                  <a
                    className="hover:text-white underline ml-2 inline-block align-baseline text-center font-normal text-sm text-white "
                    href="/sign-up">
                    {t("Register here")}
                  </a>
                </div>
                <div className="text-center mt-3 text-white text-sm">
                  <a
                    className="hover:text-white underline ml-2 inline-block align-baseline text-center font-normal text-sm text-white "
                    href="/reset">
                    {t("Reset password")}
                  </a>
                </div>
                <div className="text-center mt-3 text-white text-sm">
                  <a
                    className="flex justify-center mt-10 hover:text-white underline inline-block align-baseline text-center font-normal text-sm text-white "
                    href="/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-left"
                      viewBox="0 0 16 16">
                      {" "}
                      <path
                        fillRule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />{" "}
                    </svg>{" "}
                    {t("Back to main page")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {id && (
        <div className="px-4 md:px-14 grid grid-cols-1 md:grid-cols-1 justify-items-center  gap-6 content-center py-10">
          <div className="w-full max-w-md">
            <form className=" shadow-md rounded px-8 md:px-10 py-10 box-login">
              <div className="flex justify-center mb-12">
                <img src={logo} alt="Logo" className="h-20" />
              </div>
              <div className="flex justify-center mb-6">
                <img src={checkLogo} alt="" className="mb-10" />
              </div>
              <h1 className="text-4xl font-normal text-center text-white pb-28 text-xl">
                {t("Verify successfully")}
              </h1>
              <div>
                <a
                  href="/"
                  className="w-full flex justify-center items-center btn-login text-white  py-2 px-4 focus:outline-none focus:shadow-outline">
                  {t("OK")}
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignIn;
