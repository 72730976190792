/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Address from "./ProfileAddress";
import AddressBill from "./ProfileAddressBill";
import User from "./ProfileUser";
import "../index.css";
import { t } from "i18next";

function ProfileMain() {
  const [ActionEdit, setActionEdit] = useState(false);
  const [ActiveTab, setActiveTab] = useState("address");

  const ClickActiveTab = (div) => {
    setActiveTab(div);
    // console.log('ActiveTab ', ActiveTab);
  };
  const ClickEdit = () => {
    setActionEdit(!ActionEdit);
  };

  useEffect(() => {}, []);

  return (
    <div className="xl:container mx-auto px-4 py-3 mt-3">
      <User />
      <ul className="flex tab-profile">
        <li className="mr-6">
          <button
            className={`${ActiveTab == "address" ? "active" : ""} font-bold text-lg`}
            onClick={() => ClickActiveTab("address")}>
            {t("Address")}
          </button>
        </li>
        <li className="mr-6">
          <button
            className={`${ActiveTab == "addressBill" ? "active" : ""} font-bold text-lg`}
            onClick={() => ClickActiveTab("addressBill")}>
            {t("Billing Address")}
          </button>
        </li>
        <button className="text-sm underline font-bold text-lg ml-auto mt-1" onClick={ClickEdit}>
          {ActionEdit === false && t("Edit")}
          {ActionEdit === true && t("Cancel")}
        </button>
      </ul>
      {ActiveTab === "address" && (
        <div>
          <Address status={ActionEdit} tab={ActiveTab} />
        </div>
      )}
      {ActiveTab === "addressBill" && (
        <div>
          <AddressBill status={ActionEdit} tab={ActiveTab} />
        </div>
      )}
    </div>
  );
}

export default ProfileMain;
