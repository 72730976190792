import { createSlice } from "@reduxjs/toolkit";
import { StatusData } from "../../models/module";
import { ModelPropertyNameModule } from "../share";
import { fetchPropertiesByModelId } from "./thunk";

const initialState = {
  status: StatusData.idle,
  data: [],
  error: null
};

const modelPropertySlice = createSlice({
  name: ModelPropertyNameModule,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPropertiesByModelId.pending, (state) => {
      state.status = StatusData.pending;
    });
    builder.addCase(fetchPropertiesByModelId.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = StatusData.succeeded;
    });
    builder.addCase(fetchPropertiesByModelId.rejected, (state, action) => {
      // state = {
      //   status: StatusData.failed,
      //   error: action.payload
      // };
      state.error = action.error;
      state.status = StatusData.failed;
    });
  }
});

export default modelPropertySlice.reducer;
