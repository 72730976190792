/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState, Fragment } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import logo from "./assets/images/logo.png";
import { Breakpoint } from "../../theme/config.them";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Menu from "../../components/Menu";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Listbox, Transition, Combobox } from "@headlessui/react";
import { fetchCountryListAsset } from "../../../src/modules/assets/thunk";
import "./index.css";
import { onAuthStateChanged } from "firebase/auth";
import firebaseAuth from "../../core/firebase.core";
import { t } from "i18next";

const Form = () => {
  const auth = firebaseAuth;
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [country, setCountry] = useState([]);
  const [currentForm, setCurrentForm] = useState(1);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorRePassword, setErrorRePassword] = useState(null);
  const [errorFname, setErrorFname] = useState(null);
  const [errorLname, setErrorLname] = useState(null);
  const [errorCountry, setErrorCountry] = useState(null);
  const [errorCity, setErrorCity] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const [rePassword, setRePassword] = useState(null);
  const [consent, setConsent] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [errorTerm, setErrorTerm] = useState(false);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const {
    setValue
    // getValues
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    country_name: countryName,
    consent_flag: false,
    newsletter_flag: false
  });
  function handleCheckboxChange(event) {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
    if (event.target.checked == true) {
      setErrorTerm(null);
    }
  }

  const handlePrevious = () => {
    setCurrentForm(currentForm - 1);
  };

  const handleNext = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const lettersRegex = /^[a-zA-Z]+$/;
    const pattern = /[#]/;
    // console.log(lettersRegex.test(formData.first_name));
    if (!formData.email) {
      setErrorEmail(t("This field is required."));
    } else if (pattern.test(formData.email) == true) {
      setErrorEmail(t("Please enter the correct email"));
    } else if (emailRegex.test(formData.email) == false) {
      setErrorEmail(t("Please enter the correct email"));
    }
    if (!formData.first_name) {
      setErrorFname(t("This field is required."));
    } else if (lettersRegex.test(formData.first_name) == false) {
      setErrorFname(t("Please enter characters only"));
    }
    if (!formData.last_name) {
      setErrorLname(t("This field is required."));
    } else if (lettersRegex.test(formData.last_name) == false) {
      setErrorLname(t("Please enter characters only"));
    }
    if (!formData.country_code) {
      setErrorCountry(t("This field is required."));
    } else {
      setErrorCountry("");
    }
    if (!formData.country_name) {
      setErrorCountry(t("This field is required."));
    } else {
      setErrorCountry("");
    }

    if (
      formData.email &&
      formData.first_name &&
      formData.last_name &&
      formData.country_code &&
      formData.country_name &&
      emailRegex.test(formData.email) == true &&
      pattern.test(formData.email) == false &&
      lettersRegex.test(formData.first_name) == true &&
      lettersRegex.test(formData.last_name) == true
    ) {
      setCurrentForm(currentForm + 1);
    }
  };
  useEffect(() => {
    dispatch(fetchCountryListAsset());
    const token = window.sessionStorage.getItem("authenticationToken");

    onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        if (user.isAnonymous == false) {
          navigate("/profile");
        }
      }
    });
  }, [auth, dispatch, navigate]);

  const { status: countriesStatus, data: countiesData } = useSelector(
    (state) => state.assets.countries
  );

  const filteredCountry = useMemo(() => {
    if (!countiesData) return [];
    return query === ""
      ? countiesData
      : countiesData.filter((country) =>
          country.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  }, [countiesData, query]);
  const handleFocusOut = (event) => {
    event.preventDefault();
    if (event.target.name === "rePassword") {
      if (event.target.value === null) {
        setErrorRePassword(t("This field is required."));
      } else {
        setErrorRePassword(null);
      }
    }
    const pattern = /[#]/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (event.target.name === "email") {
      if (event.target.value === null) {
        setErrorEmail(t("This field is required."));
      } else if (emailRegex.test(event.target.value) == false) {
        setErrorEmail(t("Please enter the correct email"));
      } else if (pattern.test(event.target.value) == true) {
        setErrorEmail(t("Please enter the correct email"));
      } else {
        setErrorEmail(null);
      }
    }

    if (event.target.name === "password") {
      if (event.target.value === null) {
        setErrorPassword(t("This field is required."));
      } else {
        setErrorPassword(null);
      }
    }
    const lettersRegex = /^[a-zA-Z]+$/;
    if (event.target.name === "first_name") {
      if (event.target.value === null) {
        setErrorFname(t("This field is required."));
      } else if (lettersRegex.test(event.target.value) == false) {
        setErrorFname(t("Please enter characters only"));
      } else {
        setErrorFname(null);
      }
    }
    if (event.target.name === "last_name") {
      if (event.target.value === null) {
        setErrorLname(t("This field is required."));
      } else if (lettersRegex.test(event.target.value) == false) {
        setErrorLname(t("Please enter characters only"));
      } else {
        setErrorLname(null);
      }
    }
    if (event.target.name === "country_code") {
      if (event.target.value === null) {
        setErrorCountry(t("This field is required."));
      } else {
        setErrorCountry(null);
      }
    }

    if (event.target.name === "country_name") {
      if (event.target.value === null) {
        setErrorCountry(t("This field is required."));
      } else {
        setErrorCountry(null);
      }
    }
    if (event.target.name === "rePassword") {
      if (event.target.value === null) {
        setErrorRePassword(t("This field is required."));
      } else {
        setErrorRePassword(null);
      }
    }
    if (event.target.name === "password") {
      if (event.target.value === null) {
        setErrorPassword(t("This field is required."));
      } else {
        setErrorPassword(null);
      }
    }
  };
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });

    if (event.target.name === "country_code") {
      if (event.target.value === null) {
        setErrorCountry(t("This field is required."));
      } else {
        setErrorCountry(null);
        setCountryName(event.target.options[event.target.selectedIndex].getAttribute("data-name"));
      }
    }

    if (event.target.name === "country_name") {
      if (event.target.value === null) {
        setErrorCountry(t("This field is required."));
      } else {
        setErrorCountry(null);
      }
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const pattern = /[#]/;

    if (!formData.email) {
      setErrorEmail(t("This field is required."));
    } else if (pattern.test(formData.email) == true) {
      setErrorEmail(t("Please enter the correct email"));
    } else if (emailRegex.test(formData.email) == false) {
      setErrorEmail(t("Please enter the correct email"));
    } else {
      setErrorEmail(null);
    }
    const lettersRegex = /^[a-zA-Z]+$/;
    if (!formData.first_name) {
      setErrorFname(t("This field is required."));
    } else if (lettersRegex.test(formData.first_name) == false) {
      setErrorFname(t("Please enter characters only"));
    }
    if (!formData.last_name) {
      setErrorLname(t("This field is required."));
    } else if (lettersRegex.test(formData.last_name) == false) {
      setErrorLname(t("Please enter characters only"));
    }
    if (!formData.country_code) {
      setErrorCountry(t("This field is required."));
    }
    if (!formData.country_name) {
      setErrorCountry(t("This field is required."));
    }
    if (!formData.password) {
      setErrorPassword(t("This field is required."));
    }
    if (!formData.rePassword) {
      setErrorRePassword(t("This field is required."));
    }
    if (formData.password != formData.rePassword) {
      setErrorRePassword(t("Passwords do not match"));
    }
    if (formData.password && formData.password.length < 6) {
      setErrorRePassword(t("Please fill in at least 6 characters"));
    }
    if (!formData.consent_flag) {
      setErrorTerm(t("Please accept terms of service and privacy policy."));
    }

    if (
      formData.email &&
      formData.first_name &&
      formData.last_name &&
      formData.country_code &&
      formData.country_name &&
      lettersRegex.test(formData.first_name) == true &&
      lettersRegex.test(formData.last_name) == true &&
      emailRegex.test(formData.email) == true &&
      pattern.test(formData.email) == false &&
      formData.consent_flag &&
      formData.password &&
      formData.rePassword &&
      formData.password === formData.rePassword &&
      formData.password.length >= 6
    ) {
      setIsLoading(true);
      const getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/customer/signUpWithEmailPassword";
      const token = window.sessionStorage.getItem("authenticationToken");
      const request = {
        url: getValURL,
        method: "post",
        data: formData,
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          if (response.status == 200) {
            if (response.data.code == 1) {
              navigate("/verify");
            } else {
              MySwal.fire({
                title: response.data.message,
                icon: "error",
                showConfirmButton: true
              }).then(() => {});
            }
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          MySwal.fire({
            title: error.response.data.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500
          }).then(() => {
            setIsLoading(false);
          });
        });
    }
  };

  return (
    <div className="h-fit bg-login">
      <Menu></Menu>
      {currentForm === 1 && (
        <div className="px-4 md:px-14 grid grid-cols-1 md:grid-cols-1 justify-items-center gap-6 content-center py-10 ">
          <div className="w-full max-w-md">
            <form className=" shadow-md rounded px-8 md:px-10 py-8 box-login">
              <div className="flex justify-center mb-6">
                <img src={logo} alt="Logo" className="h-20" />
              </div>
              <h1 className="text-4xl font-normal text-center text-white pb-8 text-xl">
                {t("Create Account")}
              </h1>
              <div className="mb-3">
                <input
                  autoComplete="off"
                  className="mb-3 dark:text-white style-input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleChange}
                  onBlur={handleFocusOut}
                  value={formData.email || ""}
                  name="email"
                  type="email"
                  placeholder={t("Enter your e-mail address")}
                />
                {errorEmail && (
                  <p
                    style={{ textShadow: "1px 1px 4px white" }}
                    className="text-[#7C0004] font-semibold">
                    {errorEmail}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <input
                  autoComplete="off"
                  className="mb-3 dark:text-white style-input shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleChange}
                  onBlur={handleFocusOut}
                  value={formData.first_name || ""}
                  name="first_name"
                  type="text"
                  placeholder={t("First Name")}
                />
                {errorFname && (
                  <p
                    style={{ textShadow: "1px 1px 4px white" }}
                    className="text-[#7C0004] font-semibold">
                    {errorFname}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <input
                  autoComplete="off"
                  className="dark:text-white mb-3 style-input shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleChange}
                  onBlur={handleFocusOut}
                  value={formData.last_name || ""}
                  name="last_name"
                  type="text"
                  placeholder={t("Last name")}
                />
                {errorLname && (
                  <p
                    style={{ textShadow: "1px 1px 4px white" }}
                    className="text-[#7C0004] font-semibold">
                    {errorLname}
                  </p>
                )}
              </div>
              <div className="">
                <Combobox
                  value={formData}
                  onChange={(v) => {
                    setCountry(v);
                    setValue("country", country.name);
                    setFormData({ ...formData, country_name: v.name, country_code: v.code });
                    if (v.name) {
                      setErrorCountry(null);
                    }
                  }}>
                  <div className="relative mb-3">
                    <div className=" mb-3 relative w-full cursor-default overflow-hidden rounded bg-opacity-40 text-left  ">
                      <Combobox.Input
                        className="dark:text-white style-input shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white leading-tight focus:outline-none focus:shadow-outline"
                        displayValue={(person) => person.country_name}
                        onChange={(event) => setQuery(event.target.value)}
                        placeholder={t("Country")}
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <svg
                          className="w-4 h-4 ml-1 text-white"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"></path>
                        </svg>
                        {/* <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                      </Combobox.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setQuery("")}>
                      <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredCountry.length === 0 && query !== "" ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Nothing found.
                          </div>
                        ) : (
                          filteredCountry.map((country, index) => (
                            <Combobox.Option
                              key={index}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active ? "bg-teal-600 text-white" : "text-gray-900"
                                }`
                              }
                              value={country}>
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}>
                                    {country.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                        active ? "text-white" : "text-teal-600"
                                      }`}>
                                      {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
                {errorCountry && (
                  <p
                    style={{ textShadow: "1px 1px 4px white" }}
                    className="text-[#7C0004] font-semibold">
                    {errorCountry}
                  </p>
                )}
              </div>
              <div className={`${errorCountry ? "mt-3" : " mt-6"} mb-6`}>
                <input
                  autoComplete="off"
                  className="mb-3 dark:text-white style-input shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleChange}
                  onBlur={handleFocusOut}
                  value={formData.city || ""}
                  name="city"
                  type="text"
                  placeholder={t("City (Optional)")}
                />
                {errorCity && (
                  <p
                    style={{ textShadow: "1px 1px 4px white" }}
                    className="text-[#7C0004] font-semibold">
                    {errorCity}
                  </p>
                )}
              </div>
              <div>
                <button
                  onClick={handleNext}
                  data-name={countryName}
                  className="w-full btn-login text-white  py-2 px-4  focus:outline-none focus:shadow-outline"
                  type="button">
                  {t("Continue")}
                </button>
              </div>
              <div className="text-center mt-3 mb-10 text-white  text-sm">
                {t("Already have an account?")}
                <a
                  className="hover:text-white underline ml-2 inline-block align-baseline text-center font-normal text-white \"
                  href="sign-in">
                  {t("Login")}
                </a>
              </div>
              <div className="text-center mt-6 text-white text-sm">
                <a
                  className="flex justify-center hover:text-white underline  inline-block align-baseline text-center font-normal text-sm text-white "
                  href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16">
                    {" "}
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />{" "}
                  </svg>{" "}
                  {t("Back to main page")}
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
      {currentForm === 2 && (
        <div className=" px-4 md:px-14 grid grid-cols-1 md:grid-cols-1 justify-items-center  gap-6 content-center py-10 ">
          <div className="w-full max-w-md">
            <form className=" shadow-md rounded px-8 md:px-10 py-10 box-login">
              <div className="flex justify-center mb-6">
                <img src={logo} alt="Logo" className="h-20" />
              </div>
              <h1 className="text-4xl font-normal text-center text-white pb-8 text-xl">
                {t("Set your password")}
              </h1>
              <div className="mb-10">
                <input
                  autoComplete="off"
                  className="dark:text-white style-input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white leading-tight focus:outline-none focus:shadow-outline"
                  name="password"
                  value={formData.password || ""}
                  onChange={handleChange}
                  onBlur={handleFocusOut}
                  type="password"
                  placeholder={t("Password")}
                />
                {errorPassword && (
                  <p
                    style={{ textShadow: "1px 1px 4px white" }}
                    className="text-[#7C0004] font-semibold">
                    {errorPassword}
                  </p>
                )}
              </div>
              <div className="mb-6">
                <input
                  autoComplete="off"
                  className="dark:text-white style-input shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  name="rePassword"
                  value={formData.rePassword || ""}
                  onChange={handleChange}
                  onBlur={handleFocusOut}
                  type="password"
                  placeholder={t("Confirm your password")}
                />
                {errorRePassword && (
                  <p
                    style={{ textShadow: "1px 1px 4px white" }}
                    className="text-[#7C0004] font-semibold">
                    {errorRePassword}
                  </p>
                )}
              </div>
              <div className="mb-3 ">
                <label className="flex text-gray-500 font-bold">
                  <input
                    className="mr-2 leading-tight h-5 w-5 pt-2"
                    name="consent_flag"
                    type="checkbox"
                    value={formData.consent_flag || ""}
                    onChange={handleCheckboxChange}
                  />
                  <span className="text-sm text-white font-normal">
                    {t("I Accept")}{" "}
                    <a
                      href="https://zappev.com/terms-conditions/"
                      target="_blank"
                      rel="noreferrer"
                      className="underline">
                      {t("Terms of Service")}
                    </a>{" "}
                    {t("and")}
                    <a
                      href="https://zappev.com/privacy-policy-2/"
                      target="_blank"
                      rel="noreferrer"
                      className="underline">
                      {" "}
                      {t("Privacy Policy")}
                    </a>
                  </span>
                </label>
                {errorTerm && (
                  <p
                    style={{ textShadow: "1px 1px 4px white" }}
                    className="text-[#7C0004] font-semibold">
                    {errorTerm}
                  </p>
                )}
              </div>

              <div>
                <button
                  onClick={handleClick}
                  className="w-full btn-login text-white  py-2 px-4 focus:outline-none focus:shadow-outline"
                  type="button">
                  {isLoading ? <span className="loader" /> : t("Register")}
                </button>
              </div>
              <div className="text-center mt-10 text-white text-sm flex justify-center">
                <button
                  className="flex justify-center hover:text-white underline  inline-block align-baseline text-center font-normal text-sm text-white "
                  onClick={handlePrevious}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16">
                    {" "}
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />{" "}
                  </svg>{" "}
                  {t("Back")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
