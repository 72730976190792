import { useEffect } from "react";
import { useState } from "react";
// import Countdown from "react-countdown";

const LoopCounter = ({ onComplete }) => {
  // eslint-disable-next-line no-unused-vars
  const [time, setTime] = useState(new Date());
  const tenMin = 10 * 60 * 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
      onComplete();
    }, tenMin);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
};

export default LoopCounter;
