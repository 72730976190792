export const Breakpoint = {
  xs: 640,
  md: 768,
  lg: 1024,
  xl: 1280
};

export const Color = {
  primary: "#ec2028",
  black: "#080808",
  primaryFont: "#080808",
  secondaryFont: "#777777",
  primaryBG: "#ffffff",
  secondaryBG: "#dcdcdc"
};
