import i18next, { t } from "i18next";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

const VerticalTemplateV2 = ({
  models = [],
  currency = {},
  currentModel = {},
  // currentIndex,
  onSelectModel,
  noteDetail
}) => {
  const navigate = useNavigate();

  const getModelsList = (_currency, _models) => {
    if (!_currency || !_models || _models.length < 1) return [];
    const arr = [..._models]?.sort((a, b) => {
      const priceA = a.price[_currency.dbKey];
      const priceB = b.price[_currency.dbKey];
      return priceA - priceB;
    });
    return arr;
  };

  const getIncludePart = (parts = []) => {
    const includePart = [...parts].sort((a, b) => a.order - b.order);
    return includePart;
  };

  return (
    <ul
      role="button"
      className="container mx-auto max-w-4xl flex flex-col space-y-3 h-full overflow-y-auto px-5 pb-20">
      {getModelsList(currency, models).map((model) => {
        const isActive = currentModel?.modelId === model.modelId;
        return (
          <li
            key={model.modelId}
            onClick={() => onSelectModel(model)}
            className={`flex flex-col space-y-1 p-5 rounded-lg bg-white bg-opacity-0 hover:bg-opacity-30 ${
              isActive ? "bg-opacity-30" : ""
            }`}>
            <img src={model?.image} className="object-cover w-full" />
            <p className="text-2xl md:text-3xl lg:text-4xl font-bold">{model.name}</p>
            <p className=" inline-flex md:text-xl lg:text-2xl space-x-1">
              <span>{currency.prefix}</span>
              <NumericFormat
                value={model.price[currency?.dbKey] || 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            {isActive ? (
              <div className="border-t flex flex-col pt-3 space-y-3">
                <div className="flex flex-col space-y-1">
                  <p className="text-sm">
                    {model?.extendDemoPartInformation?.description?.name[i18next.language]}
                  </p>
                  <ul
                    role="button"
                    className="flex space-x-3 whitespace-nowrap md:whitespace-normal overflow-x-auto md:overflow-hidden">
                    {getIncludePart(model?.extendDemoPartInformation?.parts).map((part, index) => (
                      <li key={index.toString()}>
                        <img
                          src={part.image}
                          className="object-cover border h-[38px] md:h-[70px]"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex space-x-3 mt-1">
                  <button
                    onClick={() =>
                      navigate({
                        pathname: `./model/${model.modelId}`,
                        search: "?start=personalize"
                      })
                    }
                    className=" bg-white  hover:bg-primary hover:text-white flex-1 py-2 lg:py-3 text-black rounded">
                    {t("Personalise")}
                  </button>
                  <button
                    className=" bg-white border-2 border-primary hover:bg-primary hover:text-white flex-1 py-2 lg:py-3  text-black rounded"
                    onClick={() =>
                      navigate({
                        pathname: `./model/${model.modelId}`
                      })
                    }>
                    <p>
                      {t("Reserve")} {currency.prefix} 100
                    </p>
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </li>
        );
      })}
      <li>
        <p className="text-center">{noteDetail}</p>
      </li>
    </ul>
  );
};

export default VerticalTemplateV2;
