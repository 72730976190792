import ModalBarrier from "../ModalBarrier";

const LoadingModal = () => {
  return (
    <ModalBarrier>
      <div className="lds-ripple z-50">
        <div></div>
        <div></div>
      </div>
    </ModalBarrier>
  );
};

export default LoadingModal;
