import http from "../core/http.core";

class CustomizationPartService {
  constructor() {
    this.path = "customization-parts";
  }
  async getCustomizationPartsByModelId(modelId) {
    const { data } = await http.get(`${this.path}`, { params: { modelId } });
    return data;
  }

  async getCustomizationCategoriesByModelId(modelId) {
    const { data } = await http.get(`${this.path}/categories`, { params: { modelId } });
    return data;
  }
}

export default CustomizationPartService;
