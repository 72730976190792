import { createSlice } from "@reduxjs/toolkit";
import { StatusData } from "../../models/module";
import { CustomizationPartsNameModule } from "../share";
import { fetchCustomizationByModelId, fetchCustomizationCategoriesByModelId } from "./thunk";

const initialNodeData = {
  status: StatusData.idle,
  data: [],
  error: null
};

const initialState = {
  parts: initialNodeData,
  categoriesPart: initialNodeData
};

export const customizationSlice = createSlice({
  name: CustomizationPartsNameModule,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomizationByModelId.pending, (state) => {
      state.parts.status = StatusData.pending;
    });
    builder.addCase(fetchCustomizationByModelId.fulfilled, (state, action) => {
      // state.parts = {
      //   status: StatusData.succeeded,
      //   data: action.payload
      // };
      state.parts.data = action.payload;
      state.parts.status = StatusData.succeeded;
    });
    builder.addCase(fetchCustomizationByModelId.rejected, (state, action) => {
      // state.parts = {
      //   status: StatusData.failed,
      //   error: action.payload
      // };
      state.parts.error = action.payload;
      state.parts.status = StatusData.failed;
    });
    builder.addCase(fetchCustomizationCategoriesByModelId.pending, (state) => {
      state.categoriesPart.status = StatusData.pending;
    });
    builder.addCase(fetchCustomizationCategoriesByModelId.fulfilled, (state, action) => {
      // state.categoriesPart = {
      //   status: StatusData.succeeded,
      //   data: action.payload
      // };
      state.categoriesPart.data = action.payload;
      state.categoriesPart.status = StatusData.succeeded;
    });
    builder.addCase(fetchCustomizationCategoriesByModelId.rejected, (state, action) => {
      // state.categoriesPart = {
      //   status: StatusData.failed,
      //   error: action.payload
      // };
      state.categoriesPart.error = action.payload;
      state.categoriesPart.status = StatusData.failed;
    });
  }
});

export default customizationSlice.reducer;
