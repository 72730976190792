import { signInWithEmailAndPassword } from "firebase/auth";
import auth from "../core/firebase.core";
class AuthenticationService {
  constructor() {
    this.auth = auth;
  }

  async signInWithEmailAndGetToken(email, password) {
    const credential = await signInWithEmailAndPassword(this.auth, email, password);
    const tokenResult = await credential.user.getIdTokenResult();
    return {
      tokenResult,
      user: credential.user
    };
  }
}

export default AuthenticationService;
