/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import logo from "./assets/images/logo.png";
import { Breakpoint } from "../../theme/config.them";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Menu from "../../components/Menu";
import "./index.css";
import { t } from "i18next";
function Forgot() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleFocusOut = (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let pattern = /[#]/;
    if (event.target.name === "email") {
      if (event.target.value === null) {
        setErrorEmail(t("This field is required."));
      } else if (emailRegex.test(event.target.value) == false) {
        setErrorEmail(t("Please enter the correct email"));
      } else if (pattern.test(event.target.value) == true) {
        setErrorEmail(t("Please enter the correct email"));
      } else {
        setErrorEmail(null);
      }
    }
  };
  const submitLogin = (event) => {
    event.preventDefault();
    if (!email) {
      setErrorEmail(t("This field is required."));
    } else {
      setErrorEmail(null);
    }

    if (email) {
      setIsLoading(true);
      const getValURL =
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_DOMAIN_NAME + "/crm/customer/verifyEmailToChangePassword";
      const request = {
        url: getValURL,
        method: "post",
        data: { email: email },
        headers: {
          "content-type": "application/json"
        }
      };
      axios(request)
        .then(function (response) {
          setIsLoading(false);
          if (response.status === 200) {
            if (response.data.code === 1) {
              navigate("/check-email");
            } else {
              setErrorEmail(response.data.message);
            }
          }
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }
  };
  return (
    <div className="h-fit bg-login ">
      <Menu></Menu>
      <div className=" px-4 md:px-14 grid grid-cols-1 md:grid-cols-1 justify-items-center  gap-6 content-center py-10 ">
        <div className="w-full max-w-md">
          <form className=" shadow-md rounded px-8 md:px-10 py-10 box-login" onSubmit={submitLogin}>
            <div className="flex justify-center mb-6">
              <img src={logo} alt="Logo" className="h-20" />
            </div>
            <h1 className="text-4xl font-normal text-center text-white pb-8 text-xl">
              {t("Reset password")}
            </h1>
            <div className="mb-10 text-white">{t("Reset Detail1")}</div>
            <div className="mb-10">
              <input
                className="dark:text-white style-input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 placeholder-gray-200 text-white leading-tight focus:outline-none focus:shadow-outline"
                value={email || ""}
                onChange={handleChange}
                onBlur={handleFocusOut}
                id="email"
                name="email"
                type="email"
                placeholder={t("Email Address")}
              />
              {errorEmail && (
                <p
                  style={{ textShadow: "1px 1px 4px white" }}
                  className="text-[#7C0004] font-semibold mt-3">
                  {errorEmail}
                </p>
              )}
            </div>
            <div>
              <button
                className="w-full btn-login text-white  py-2 px-4 focus:outline-none focus:shadow-outline"
                type="submit">
                {isLoading ? <span className="loader" /> : t("Send")}
              </button>
            </div>
            <div className="text-center mt-10 text-white text-sm">
              <a
                className="flex justify-center hover:text-white underline  inline-block align-baseline text-center font-normal text-sm text-white "
                href="/sign-in">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16">
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />{" "}
                </svg>{" "}
                {t("Back")}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
