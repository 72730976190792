import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "../core/firebase.core";
import { signOut, onAuthStateChanged } from "firebase/auth";
import logo from "../assets/images/logo.png";
import uk from "../assets/images/uk1.png";
import fr from "../assets/images/france1.png";
import it from "../assets/images/italy1.png";
import es from "../assets/images/spain1.png";
import gbp from "../assets/images/pound.png";
import euro from "../assets/images/euro.png";
import usd from "../assets/images/dolla.png";
import { useDispatch, useSelector } from "react-redux";
import { Currency } from "../models/currency";
import { setCurrency } from "../modules/currency";
import i18next from "i18next";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
function Menu() {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const [openLang, setOpenLang] = useState(false);
  const [languageText, setLanguageText] = useState("UK");
  const [languageIcon, setLanguageIcon] = useState(uk);
  // const [language, setLanguage] = useState("en");
  const [openMoney, setOpenMoney] = useState(false);
  const [MoneyIcon, setMoneyIcon] = useState(euro);
  // const [MoneyText, setMoneyText] = useState("USD");

  const navigate = useNavigate();
  const auth = firebaseAuth;
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        if (user.isAnonymous == false) {
          setUser(user);
        } else {
          setUser(null);
        }
      }
    });
  }, [auth]);

  const dispatch = useDispatch();
  const currencies = Object.keys(Currency).map((key) => Currency[key]);
  const currentCurrency = useSelector((state) => state.currency);
  // currency
  useEffect(() => {
    if (
      localStorage.getItem("currencyCurrent") === null ||
      localStorage.getItem("currencyCurrent") === undefined
    ) {
      localStorage.setItem("currencyCurrent", "EUR");
      // setMoneyText("USD");
      setMoneyIcon(euro);
    } else {
      const currency_code =
        localStorage.getItem("currencyCurrent") === "USD"
          ? "EUR"
          : localStorage.getItem("currencyCurrent");
      const currency = currencies.find((c) => c.value === currency_code);
      if (currency_code == "USD") {
        setMoneyIcon(usd);
        dispatch(setCurrency(currency));
      } else if (currency_code == "GBP") {
        setMoneyIcon(gbp);
        dispatch(setCurrency(currency));
      } else if (currency_code == "EUR") {
        setMoneyIcon(euro);
        dispatch(setCurrency(currency));
      }
      // setMoneyText(localStorage.getItem("currencyCurrent"));
    }
  }, [currencies, dispatch]);
  useEffect(() => {
    const lang = i18next.language;
    if (lang === "en-US") {
      i18n.changeLanguage("en");
      setLanguageIcon(uk);
      setLanguageText("UK");
    } else {
      if (lang === "en") {
        setLanguageIcon(uk);
        setLanguageText("UK");
      } else if (lang === "es") {
        setLanguageIcon(es);
        setLanguageText("ES");
      } else if (lang === "fr") {
        setLanguageIcon(fr);
        setLanguageText("FR");
      } else if (lang === "it") {
        setLanguageIcon(it);
        setLanguageText("IT");
      } else {
        setLanguageIcon(uk);
        setLanguageText("UK");
      }
    }
  }, []);

  const logout = () => {
    signOut(auth)
      .then(() => {
        // sessionStorage.clear();
        sessionStorage.removeItem("emailUser");
        sessionStorage.removeItem("uid");
        sessionStorage.removeItem("authenticationToken");
        sessionStorage.removeItem("isAnonymous");
        setOpen(false);
        setOpenMobile(false);
        navigate("/");
      })
      .catch((e) => {
        console.error(e);
      });
  };
  // const emailUser = window.localStorage.getItem("emailUser");

  const handleClickUser = () => {
    setOpenLang(false);
    setOpen(!open);
    setOpenMoney(false);
  };
  const handleClickLang = () => {
    setOpenLang(!openLang);
    setOpen(false);
    setOpenMoney(false);
  };
  const handleClickMoney = () => {
    setOpenMoney(!openMoney);
    setOpenLang(false);
    setOpen(false);
  };

  const handleLanguageChange = (event) => {
    event.preventDefault();
    setLanguageIcon(event.target.attributes[1].nodeValue);
    setLanguageText(event.target.value);
    setOpenLang(!openLang);
    if (event.target.value != undefined) {
      if (event.target.value.toLowerCase() == "uk") {
        i18n.changeLanguage("en");
      } else {
        i18n.changeLanguage(event.target.value.toLowerCase());
      }
    }
  };
  const handleIconLanguageChange = (event) => {
    event.preventDefault();
    setLanguageIcon(event.target.attributes[1].nodeValue);
    setOpenLang(!openLang);
    if (event.target.attributes[2].nodeValue != undefined) {
      if (event.target.attributes[2].nodeValue.toLowerCase() == "uk") {
        i18n.changeLanguage("en");
      } else {
        i18n.changeLanguage(event.target.attributes[2].nodeValue.toLowerCase());
      }
    }
  };
  const handleMoneyChange = (event) => {
    sessionStorage.removeItem("currencyCurrent");
    setMoneyIcon(event.target.attributes[1].nodeValue);
    // setMoneyText(event.target.value);
    const currency = currencies.find((c) => c.value === event.target.value);
    dispatch(setCurrency(currency));
    localStorage.setItem("currencyCurrent", event.target.value);
    setOpenMoney(!openMoney);
  };
  const handleIconMoneyChange = async (event) => {
    event.preventDefault();
    setMoneyIcon(event.target.attributes[1].nodeValue);
    // setMoneyText(event.target.attributes[3].nodeValue);
    const currency = await currencies.find((c) => c.value === event.target.attributes[3].nodeValue);
    await dispatch(setCurrency(currency));
    await localStorage.setItem("currencyCurrent", currency.value);
    setOpenMoney(!openMoney);
  };

  return (
    <nav className="bg-menu text-black">
      <div className="2xl:container mx-auto flex items-center justify-between px-4 py-2 md:py-3">
        <div className="flex items-center">
          <a href="/">
            <img src={logo} alt="Logo" className="h-8 md:h-10" />
          </a>
        </div>
        <button
          data-collapse-toggle="navbar-multi-level"
          onClick={() => setOpenMobile(!openMobile)}
          type="button"
          className="btn-menu-mobile inline-flex items-center p-1 ml-3 text-sm text-gray-500 rounded-lg  md:hidden  focus:outline-none focus:ring-2 "
          aria-controls="navbar-multi-level"
          aria-expanded="false">
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"></path>
          </svg>
        </button>

        <div className="w-full md:block md:w-auto hidden sm:hidden lg:block xl:block 2xl:block">
          <ul className="flex items-center justify-end  flex-row p-2 mt-0 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-normal">
            <li>
              {user && (
                <button
                  id="dropdownProfile"
                  data-dropdown-toggle="dropdownNavbar"
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-normal text-black  md:hover:bg-transparent md:border-0  md:p-0 md:w-auto"
                  onClick={handleClickUser}>
                  {" "}
                  <span className="ml-2"> {user.email}</span>{" "}
                  <svg
                    className="w-4 h-4 ml-1"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"></path>
                  </svg>
                </button>
              )}
              {open && (
                <div
                  className={`block hide origin-top-right absolute mt-2 w-40 rounded-md shadow-l`}>
                  <div className="bg-white rounded-md shadow-xs">
                    <div className="py-1">
                      <a
                        href="/profile"
                        className="block px-4 py-2 text-sm leading-5 text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                        {t("Profile")}
                      </a>
                      <a
                        href="/reservation"
                        className="block px-4 py-2 text-sm leading-5 text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                        {t("Reservation")}
                      </a>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm leading-5 text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                        onClick={() => logout()}>
                        {t("Logout")}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li className={!user ? "" : "hidden"}>
              {user ? (
                ""
              ) : (
                <a
                  href="/sign-up"
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-normal text-black  md:hover:bg-transparent md:border-0  md:p-0 md:w-auto">
                  {" "}
                  <span className="ml-2"> {t("Register")}</span>
                </a>
              )}
            </li>
            <li className={!user ? "" : "hidden"}>
              {user ? (
                ""
              ) : (
                <a
                  href="/sign-in"
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-normal text-black  md:hover:bg-transparent md:border-0  md:p-0 md:w-auto">
                  {" "}
                  <span className="ml-2"> {t("Login")}</span>
                </a>
              )}
            </li>
            <li>
              <button
                id="dropdownMoney"
                onClick={handleClickMoney}
                data-dropdown-toggle="dropdownNavbar"
                className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-normal text-black  md:hover:bg-transparent md:border-0  md:p-0 md:w-auto">
                <span className="ml-2 ">
                  {" "}
                  <img src={MoneyIcon} className="h-6" />
                </span>{" "}
                <svg
                  className="w-4 h-4 ml-1"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
              </button>
              {openMoney && (
                <div
                  className={`block z-10 bg-white divide-y divide-gray-100 absolute rounded-lg w-fit shadow w-40 dark:bg-gray-700`}>
                  <div className="bg-white rounded-md shadow-xs">
                    <div className="py-1">
                      <button
                        onClick={handleMoneyChange}
                        value="EUR"
                        icon={euro}
                        className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                        <img
                          src={euro}
                          icon={euro}
                          onClick={handleIconMoneyChange}
                          alt="Logo"
                          value="EUR"
                          className="h-6 mr-2"
                        />
                        EUR
                      </button>
                      <button
                        onClick={handleMoneyChange}
                        value="USD"
                        icon={usd}
                        className=" hidden px-4 py-2 text-sm leading-5 text-black  focus:outline-none items-center">
                        <img
                          src={usd}
                          icon={usd}
                          onClick={handleIconMoneyChange}
                          alt="Logo"
                          value="USD"
                          className="h-6 mr-2"
                        />
                        USD
                      </button>
                      <button
                        onClick={handleMoneyChange}
                        value="GBP"
                        icon={gbp}
                        className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                        <img
                          src={gbp}
                          icon={gbp}
                          onClick={handleIconMoneyChange}
                          alt="Logo"
                          value="GBP"
                          className="h-6 mr-2"
                        />
                        GBP
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li>
              <button
                id="dropdownLang"
                onClick={handleClickLang}
                data-dropdown-toggle="dropdownNavbar"
                className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-normal text-black  md:hover:bg-transparent md:border-0  md:p-0 md:w-auto">
                <span className="ml-2 ">
                  {" "}
                  <img src={languageIcon} className="h-6" />
                </span>{" "}
                <svg
                  className="w-4 h-4 ml-1"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
              </button>
              {openLang && (
                <div
                  className={`block z-10 bg-white divide-y divide-gray-100 absolute right-px 2xl:right-auto rounded-lg w-fit shadow w-40 dark:bg-gray-700`}>
                  <div className="bg-white rounded-md shadow-xs">
                    <div className="py-1">
                      <button
                        onClick={handleLanguageChange}
                        value="UK"
                        icon={uk}
                        className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                        <img
                          src={uk}
                          icon={uk}
                          onClick={handleIconLanguageChange}
                          value="UK"
                          alt="Logo"
                          className="h-6 mr-2"
                        />
                        English (UK)
                      </button>
                      <button
                        onClick={handleLanguageChange}
                        value="FR"
                        icon={fr}
                        className="px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                        <img
                          src={fr}
                          icon={fr}
                          onClick={handleIconLanguageChange}
                          value="FR"
                          alt="Logo"
                          className="h-6 mr-2"
                        />
                        Français
                      </button>
                      {/* <button
                        onClick={handleLanguageChange}
                        value="ES"
                        icon={es}
                        className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                        <img
                          src={es}
                          icon={es}
                          onClick={handleIconLanguageChange}
                          value="ES"
                          alt="Logo"
                          className="h-6 mr-2"
                        />
                        español
                      </button>
                      <button
                        onClick={handleLanguageChange}
                        value="IT"
                        icon={it}
                        className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                        <img
                          src={it}
                          icon={it}
                          onClick={handleIconLanguageChange}
                          value="IT"
                          alt="Logo"
                          className="h-6 mr-2"
                        />
                        italiano
                      </button> */}
                    </div>
                  </div>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`${
          openMobile ? "block" : "hidden"
        } px-4 w-full md-block md:none lg:none md:w-auto menu-mobile pb-2`}>
        <li className="main">
          <button
            id="dropdownMoney"
            onClick={handleClickMoney}
            data-dropdown-toggle="dropdownNavbar"
            className="flex items-center justify-between w-full py-2 font-normal text-black  md:hover:bg-transparent md:border-0  md:p-0 md:w-auto">
            <span className="flex">
              {" "}
              <img src={MoneyIcon} className="h-6 mr-2" />
              {currentCurrency.value}
            </span>{" "}
            <svg
              className="w-4 h-4 ml-1"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
          </button>
          {openMoney && (
            <ul className="py-1 li-mobile">
              <button
                onClick={handleMoneyChange}
                value="EUR"
                icon={euro}
                className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                <img
                  src={euro}
                  icon={euro}
                  onClick={handleIconMoneyChange}
                  alt="Logo"
                  value="EUR"
                  className="h-6 mr-2"
                />
                EUR
              </button>
              <button
                onClick={handleMoneyChange}
                value="USD"
                icon={usd}
                className="hidden px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                <img
                  src={usd}
                  icon={usd}
                  onClick={handleIconMoneyChange}
                  alt="Logo"
                  value="USD"
                  className="h-6 mr-2"
                />
                USD
              </button>
              <button
                onClick={handleMoneyChange}
                value="GBP"
                icon={gbp}
                className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                <img
                  src={gbp}
                  icon={gbp}
                  onClick={handleIconMoneyChange}
                  alt="Logo"
                  value="GBP"
                  className="h-6 mr-2"
                />
                GBP
              </button>
            </ul>
          )}
        </li>
        <li className="main">
          <button
            id="dropdownLang"
            onClick={handleClickLang}
            data-dropdown-toggle="dropdownNavbar"
            className="flex items-center justify-between w-full py-2 font-normal text-black  md:hover:bg-transparent md:border-0  md:p-0 md:w-auto">
            <span className="flex">
              {" "}
              <img src={languageIcon} className="h-6 mr-2" />
              {languageText}
            </span>{" "}
            <svg
              className="w-4 h-4 ml-1"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
          </button>
          {openLang && (
            <ul className="py-1 li-mobile">
              <button
                onClick={handleLanguageChange}
                value="UK"
                icon={uk}
                className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                <img
                  src={uk}
                  icon={uk}
                  onClick={handleIconLanguageChange}
                  value="UK"
                  alt="Logo"
                  className="h-6 mr-2"
                />
                English (UK)
              </button>
              <button
                onClick={handleLanguageChange}
                value="FR"
                icon={fr}
                className=" px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                <img
                  src={fr}
                  icon={fr}
                  onClick={handleIconLanguageChange}
                  value="FR"
                  alt="Logo"
                  className="h-6 mr-2"
                />
                Français
              </button>
              {/* <button
                onClick={handleLanguageChange}
                value="ES"
                icon={es}
                className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                <img
                  src={es}
                  icon={es}
                  onClick={handleIconLanguageChange}
                  value="ES"
                  alt="Logo"
                  className="h-6 mr-2"
                />
                español
              </button>
              <button
                onClick={handleLanguageChange}
                value="IT"
                icon={it}
                className="block px-4 py-2 text-sm leading-5 text-black  focus:outline-none flex items-center">
                <img
                  src={it}
                  icon={it}
                  onClick={handleIconLanguageChange}
                  value="IT"
                  alt="Logo"
                  className="h-6 mr-2"
                />
                italiano
              </button> */}
            </ul>
          )}
        </li>
        <li className={`${!user ? "main" : "hidden"}`}>
          {user ? (
            ""
          ) : (
            <a href="/sign-up " className="block py-2 text-black md:p-0">
              {" "}
              <span className=""> {t("Register")}</span>
            </a>
          )}
        </li>
        <li className={`${!user ? "main" : "hidden"}`}>
          {user ? (
            ""
          ) : (
            <a href="/sign-in" className="block py-2 text-black md:p-0">
              {" "}
              <span className=""> {t("Login")}</span>
            </a>
          )}
        </li>
        {user && (
          <li className="main">
            <button
              id="dropdownProfile"
              data-dropdown-toggle="dropdownNavbar"
              className="flex items-center justify-between w-full py-2 font-normal text-black  md:hover:bg-transparent md:border-0  md:p-0 md:w-auto"
              onClick={handleClickUser}>
              {" "}
              <span className="">{user.email}</span>{" "}
              <svg
                className="w-4 h-4 ml-1"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
            </button>
            {open && (
              <ul className="py-1 li-mobile">
                <li>
                  <a
                    href="/profile"
                    className="ml-4 block px-4 py-2 text-sm leading-5 text-black focus:outline-none focus:bg-transparent">
                    {t("Profile")}
                  </a>
                </li>
                <li>
                  <a
                    href="/reservation"
                    className="ml-4 block px-4 py-2 text-sm leading-5 text-black focus:outline-none focus:bg-transparent">
                    {t("Reservation")}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="ml-4 block px-4 py-2 text-sm leading-5 text-black focus:outline-none focus:bg-transparent"
                    onClick={() => logout()}>
                    {t("Logout")}
                  </a>
                </li>
              </ul>
            )}
          </li>
        )}
      </div>
    </nav>
  );
}
export default Menu;
