import axios from "axios";
import config from "./env.core";
const instance = axios.create({
  baseURL: config.zapp_crm_url
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers = {
      "Content-Type": "application/json",
      authorization: sessionStorage.getItem("authenticationToken")
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;
