import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import SummaryGroupSection from "../../components/SummaryGroupSection";
import SummaryItem from "../../components/SummaryItem";
import i18next from "i18next";
const SummaryTemplate = ({
  currentModel,
  customizationCategoryData,
  customizationPartData,
  selectedProperties,
  onConfirm,
  isOrderMode,
  reservePrices = [],
  // summaryType,
  onCustomized,
  onSaveConfiguration
  // order
}) => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.currency);

  const customizationGroup =
    customizationCategoryData?.map((category) => {
      const parts = customizationPartData?.filter((part) => part.categoryId === category.groupId);
      return {
        category: category,
        options: selectedProperties?.filter((custom) =>
          parts.some((part) => custom.customizationPartId === part.partId)
        )
      };
    }) || [];

  const vehiclePrice = useMemo(() => {
    return currentModel?.price[currency.dbKey] || 0;
  }, [currentModel, currency]);

  const addOnPrice = useMemo(() => {
    return selectedProperties?.reduce((acc, property) => acc + property.price[currency.dbKey], 0);
  }, [currency, selectedProperties]);

  const reservePrice = useMemo(() => {
    if (!reservePrices) return 0;
    const _reservePrice = reservePrices.find(
      (reservePrice) => reservePrice.currency_code === currency.code
    );
    return _reservePrice?.price || 0;
  }, [currency.code, reservePrices]);

  return (
    <div className="h-full flex flex-col px-5 py-2 md:py-5 space-y-5 overflow-y-hidden relative lg:mx-auto lg:container lg:max-w-3xl xl:max-w-none">
      <div className="flex flex-col space-y-1 pb-3 border-b">
        <p className="text-xl md:text-2xl font-bold">{t("Order Summary")}</p>
        <p className=" text-sm md:text-base">{t("Option can be modified after reservation")}</p>
      </div>
      <div className="flex-1 py-0 md:py-5 space-y-5 pr-2 overflow-y-auto">
        {customizationGroup?.map((group, index) => (
          <SummaryGroupSection
            key={index}
            group={group.category?.language?.translation[i18next.language] || ""}
            options={group.options}
          />
        ))}
      </div>
      {/* <div className=" border-b my-10" /> */}
      <div className="flex flex-col space-y-3 py-3 border-t border-b">
        <SummaryItem title={t("Vehicle Price")} currency={currency.prefix} value={vehiclePrice} />
        <SummaryItem
          title={t("Additional Options")}
          currency={currency.prefix}
          value={addOnPrice}
        />
        <SummaryItem
          title={t("Total Price")}
          currency={currency.prefix}
          value={addOnPrice + vehiclePrice}
        />
      </div>
      <div className="flex justify-between items-center text-base md:text-xl">
        <p className="font-bold">{t("RESERVATION FEE (REFUNDABLE)")}</p>
        <p className="font-bold inline-flex space-x-1">
          <span>{currency.prefix}</span>
          <NumericFormat value={reservePrice} displayType={"text"} thousandSeparator={true} />
        </p>
      </div>
      <div className="flex justify-between items-center text-base md:text-xl">
        <p className=" font-bold">{t("EST. Delivery")}</p>
        <p className="font-bold">12-14 weeks</p>
      </div>

      <div className="flex space-x-5 pb-2">
        <button
          className="bg-button-color text-black bg-opacity-40 rounded h-[47px] text-base md:text-2xl md:h-[67px] px-5"
          onClick={onCustomized}>
          {t("Back")}
        </button>
        <button
          className="flex-1 bg-button-color text-base md:text-2xl text-black bg-opacity-40 rounded h-[47px] md:h-[67px]"
          onClick={() => (isOrderMode ? onSaveConfiguration() : onConfirm())}>
          {isOrderMode ? t("Save Configuration") : t("Continue")}
        </button>
      </div>
    </div>
  );
};

export default SummaryTemplate;
