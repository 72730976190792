/* eslint-disable no-unused-vars */
import React from "react";
import { ActionType } from "../../../../models/property";

const SelectorLayout = ({
  title,
  options = [],
  values = [],
  currency,
  onSelectProperty,
  actionType
}) => {
  function active(v, _id) {
    return v == _id ? "border-grey" : " border-transparent";
  }

  function handleOnChange(v, _actionType) {
    const hasProperty = values.some((ele) => ele._id === v._id);
    if (_actionType === ActionType.selector && !hasProperty) {
      onSelectProperty(v, "add", _actionType);
      // onSelectProperty(v, hasProperty ? "remove" : "add");
    } else {
      onSelectProperty(v, hasProperty ? "remove" : "add", _actionType);
    }
  }

  return (
    <div className="flex flex-col space-y-2 pt-4 pb-8 px-3 border-b">
      <p className="font-bold  text-xl">{title}</p>
      <div className="flex justify-between pb-5">
        <p className="inline-flex space-x-2 items-center">
          <span>{values[0]?.name}</span>
        </p>
        {values[0]?.price ? (
          <p className="inline-flex space-x-1">
            <span>{currency.prefix}</span> <span>{values[0]?.price[currency.dbKey]}</span>
          </p>
        ) : (
          <></>
        )}
      </div>
      <ul className="flex flex-wrap gap-3">
        {options.map((item, index) => {
          const isActive = values?.some((v) => v._id === item._id);
          return (
            <li onClick={() => handleOnChange(item, actionType)} className="relative" key={index}>
              <div
                className={`relative flex flex-col space-y-2 items-center hover:bg-white hover:bg-opacity-40 p-2 rounded-lg cursor-pointer ${
                  isActive ? "bg-white bg-opacity-40" : ""
                }`}>
                <img src={item?.image} className={`object-cover rounded-lg w-[80px] h-[80px] `} />
                <p className="text-center text-sm">{item.name}</p>
              </div>
              <div className="absolute bottom-0 w-full flex justify-center ">
                {isActive ? <div className="h-1 w-1/2 bg-white rounded-md"></div> : <></>}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SelectorLayout;
