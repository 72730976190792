import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import customizationReducer from "./customization/index";
import propertyReducer from "./property/index";
import summaryReducer from "./summary/index";
import authenticationReducer from "./authentication/index";
import productModelReducer from "./productModel/index";
import tempCustomizationReducer from "./tempCustomization/index";
import currencyReducer from "./currency";
import assetsReducer from "./assets/index";
import sharedCustomizationReducer from "./sharedCustomization/index";
import orderReducer from "./order/index";
import userReducer from "./user/index";
import {
  AssetModule,
  AuthenticationModule,
  CurrencyModule,
  CustomizationPartsNameModule,
  ModelPropertyNameModule,
  OrdersModule,
  ProductModelModule,
  ShardCustomizationModule,
  SummaryModule,
  TempCustomizationModule,
  UserModule
} from "./share";

export const store = configureStore({
  reducer: {
    [CustomizationPartsNameModule]: customizationReducer,
    [ModelPropertyNameModule]: propertyReducer,
    [SummaryModule]: summaryReducer,
    [AuthenticationModule]: authenticationReducer,
    [ProductModelModule]: productModelReducer,
    [TempCustomizationModule]: tempCustomizationReducer,
    [CurrencyModule]: currencyReducer,
    [AssetModule]: assetsReducer,
    [ShardCustomizationModule]: sharedCustomizationReducer,
    [OrdersModule]: orderReducer,
    [UserModule]: userReducer
  }
});

setupListeners(store.dispatch);
