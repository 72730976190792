import http from "../core/http.crm.core";
class AssetsService {
  constructor() {
    this.countryPath = "countryCode";
    this.allPreFullPricesPath = "payment/allPreFullPrices";
  }

  async getCountryList() {
    const { data } = await http.get(`${this.countryPath}/all`);
    return data;
  }

  async getAllPreFullPricesReserve() {
    const { data } = await http.get(`${this.allPreFullPricesPath}/reserve`);
    return data;
  }
}

export default AssetsService;
