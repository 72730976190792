import Loading from "../../components/Loading";

const LoadingTemplate = () => {
  return (
    <div className="flex flex-col h-screen w-full items-center justify-center bg-black bg-opacity-50">
      <Loading />
    </div>
  );
};

export default LoadingTemplate;
