const ModalBarrier = ({ children, onClose }) => {
  return (
    <div
      onClick={() => onClose}
      className="fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 z-50"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="relative h-full  flex flex-col justify-center items-center pointer-events-none z-50">
        {children}
      </div>
    </div>
  );
};

export default ModalBarrier;
