import { t } from "i18next";
import { NumericFormat } from "react-number-format";

const TotalPriceHorizontalSection = ({
  totalAdditionalOptional,
  totalPrice,
  currency,
  isOrderMode,
  onConfirm
}) => {
  return (
    <div className="flex items-stretch border-t">
      <div className="flex-1 flex flex-col  lg:max-w-3xl lg:mx-auto justify-center px-3 space-y-3 font-semibold">
        <div className="flex items-center justify-between">
          <p className=" text-xs">{t("Additional Options")}</p>
          <p className="inline-flex space-x-1 text-xs">
            <span>{currency.prefix}</span>
            <NumericFormat
              value={totalAdditionalOptional || 0}
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-xs">{t("Total Price")}</p>
          <p className="text-xs inline-flex space-x-1">
            <span>{currency.prefix}</span>
            <NumericFormat value={totalPrice || 0} displayType={"text"} thousandSeparator={true} />
          </p>
        </div>
      </div>
      <button onClick={onConfirm} className=" bg-white bg-opacity-40 h-[62px] px-5">
        {isOrderMode ? <p>{t("Save Configuration")}</p> : <p>{t("Check out")}</p>}
      </button>
    </div>
  );
};

export default TotalPriceHorizontalSection;
