import { createSlice } from "@reduxjs/toolkit";
import { StatusData } from "../../models/module";
import { TempCustomizationModule } from "../share";
import { createAndFetchTempCustomization } from "./thunk";

const initialState = {
  status: StatusData.idle,
  data: null,
  error: null
};

const tempCustomizationSlice = createSlice({
  name: TempCustomizationModule,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createAndFetchTempCustomization.pending, (state) => {
      state.status = StatusData.pending;
    });
    builder.addCase(createAndFetchTempCustomization.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = StatusData.succeeded;
    });
    builder.addCase(createAndFetchTempCustomization.rejected, (state, action) => {
      state.error = action.payload;
      state.data = {};
      state.status = StatusData.failed;
    });
  }
});

export default tempCustomizationSlice.reducer;
