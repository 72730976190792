/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { convertHexToRGB, covertHexToGRBNormal } from "../../utils/color.util";

import { useDispatch, useSelector } from "react-redux";
import { fetchPropertiesByModelId } from "../../modules/property/thunk";
import {
  fetchCustomizationByModelId,
  fetchCustomizationCategoriesByModelId
} from "../../modules/customization/thunk";
import { PerformType, PropertyType } from "../../models/property";
import { StatusData } from "../../models/module";
import { TreeNode } from "../../utils/treeNode.util";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SharedCustomization from "../../services/sharedCustomization.service";
import { SketchFabUIConfig } from "../../models/skethfab";
import { createAndFetchTempCustomization } from "../../modules/tempCustomization/thunk";
import { fetchProductDetail } from "../../modules/productModel/thunk";
import CustomizationTemplate from "./Template/Customization";
import ThreeDScreen from "./components/threeDScreen";
import StepperHeader from "./components/StepperHeader";
import SummaryTemplate from "./Template/Summary";
import PaymentTemplate from "./Template/Payment";
import { StepperPage } from "../../models/stepper";
import ShareLinkModal from "../shared/components/ShareLinkModal";
import env from "../../core/env.core";
import LoadingModal from "../shared/components/LoadingModal";
import { t } from "i18next";
import ReserveDefaultTemplate from "./Template/ReserveDefault";
import OrderService from "../../services/order.service";
import { ModelTemplate } from "../../models/modelTemplate";
import { fetchSharedCustomization } from "../../modules/sharedCustomization/thunk";
import { Breakpoint } from "../../theme/config.them";
import { fetchOrderInfoById } from "../../modules/order/thunk";
import { FileService } from "../../services/file.service";
import { dataURLtoFile } from "../../utils/base64ToFile";
import { ResponseCode } from "../../models/response";
import ErrorDialog from "../shared/components/ErrorDialog";
import AuthenticationService from "../../services/authentication.service";
import { SessionService } from "../../services/session.service";
import { fetchAllPreFullPricesReserve } from "../../modules/assets/thunk";
import i18next from "i18next";
import RootTemplate from "../shared/templates/RootTemplate";
import CustomizationTemporary from "../../services/customizationTemporary.service";

const ModelTemplatePage = ({ templateType = ModelTemplate.reserved }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const sharedCustomization = new SharedCustomization();

  const { modelId, shareId, orderId } = useParams();

  const authenticationState = useSelector((state) => state.authentication);

  const {
    status: modelPropertyStatus,
    data: modelPropertyData
    // error: modelPropertyError
  } = useSelector((state) => state.modelProperty);

  const { status: reservePriceStatus, data: reservePriceData } = useSelector(
    (state) => state.assets.allPreFullPricesReserve
  );

  const { status: customizationStatus, data: customizationData } = useSelector(
    (state) => state.customization.parts
  );

  const { status: categoryStatus, data: categoryData } = useSelector(
    (state) => state.customization.categoriesPart
  );

  const { status: currentProductStatus, data: currentProductData } = useSelector(
    (state) => state.productModel.currentProduct
  );

  const { status: tempCustomizationStatus, data: tempCustomizationData } = useSelector(
    (state) => state.tempCustomization
  );

  const { status: sharedCustomizationStatus, data: sharedCustomizationData } = useSelector(
    (state) => state.sharedCustomization
  );

  const { status: currentOrderStatus, data: currentOrderData } = useSelector(
    (state) => state.order.currentOrderInfo
  );

  const currentCurrency = useSelector((state) => state.currency);

  const { data: currentUserInfoData } = useSelector((state) => state.user);

  const iframeRef = useRef(null);
  const materialList = useRef([]);

  const [isReadyView, setIsReadyView] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [group, setGroup] = useState();
  const [activeGroupScroll, setActiveGroupScroll] = useState();

  const [initialSelectedProperties, setInitialSelectedProperty] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [stepper, setStepper] = useState(StepperPage.selectModel);
  const [showShareLinkModal, setShowShareLinkModal] = useState({ open: false, link: "" });

  const [selectedCustomizedProperties, setSelectedCustomizedProperties] = useState([]);
  const [isReserveDefault, setIsReserveDefault] = useState(true);

  // theme
  const [cssCardBackground, setCssCardBackground] = useState("#dcdcdc");
  const [foregroundColor, setForegroundColor] = useState("text-white");
  const [menuButtonTheme, setMenuButtonTheme] = useState("");

  const officialNodes = useRef([]);
  const modelTextures = useRef({});
  const textureList = useRef([]);

  const currentConfigurationEnvironment = useRef();

  const initialCameraPosition = useRef();
  const screenResponsiveEvent = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };

  const [errorDialog, setErrorDialog] = useState({ isOpen: false, message: "" });

  useEffect(() => {
    if (searchParams.get("start") === "personalize") {
      setIsReserveDefault(false);
      setStepper(StepperPage.customization);
    }
  }, [searchParams]);

  useEffect(() => {
    screenResponsiveEvent();
    if (reservePriceStatus === StatusData.idle) {
      dispatch(fetchAllPreFullPricesReserve());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!authenticationState.status || !authenticationState.token) return;
    if (
      templateType === ModelTemplate.shared &&
      (!sharedCustomizationData || sharedCustomizationStatus !== StatusData.succeeded)
    ) {
      return;
    }

    if (
      templateType === ModelTemplate.order &&
      (!currentOrderData || currentOrderStatus !== StatusData.succeeded)
    ) {
      return;
    }

    const currentModelId =
      currentOrderData?.model?.modelId || sharedCustomizationData?.modelId || modelId;

    dispatch(fetchProductDetail(currentModelId));

    dispatch(createAndFetchTempCustomization(currentModelId));
    dispatch(fetchPropertiesByModelId(currentModelId));
    dispatch(fetchCustomizationByModelId(currentModelId));
    dispatch(fetchCustomizationCategoriesByModelId(currentModelId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authenticationState.token,
    authenticationState.status,
    sharedCustomizationData,
    sharedCustomizationStatus,
    currentOrderStatus,
    currentOrderData,
    modelId
  ]);

  // Get shared
  useEffect(() => {
    if (!authenticationState.status || !authenticationState.token) return;
    if (templateType !== ModelTemplate.shared) return;
    if (templateType === ModelTemplate.shared && !shareId) {
      navigate("/");
      return;
    }
    if (sharedCustomizationStatus === StatusData.idle || sharedCustomizationData?._id !== shareId) {
      dispatch(fetchSharedCustomization(shareId));
    }
    setIsReserveDefault(false);
    setStepper(StepperPage.customization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationState.status, authenticationState.token, shareId, templateType]);

  // Order Type
  useEffect(() => {
    if (!authenticationState.status || !authenticationState.token) return;
    if (templateType !== ModelTemplate.order) return;
    if (templateType === ModelTemplate.order && !orderId) {
      navigate("/");
      return;
    }

    // dispatch(fetchOrderInfoById(orderId));
    if (currentOrderStatus === StatusData.idle || currentOrderData?._id !== orderId) {
      dispatch(fetchOrderInfoById(orderId));
    }
    setIsReserveDefault(false);
    setStepper(StepperPage.customization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationState.status, authenticationState.token, orderId, templateType]);

  useEffect(() => {
    if (!categoryData) return;

    const firstCategory = categoryData[0];
    setGroup(firstCategory);

    setActiveGroupScroll(firstCategory);
  }, [categoryData]);

  const getConfiguredModelProperty = () => {
    if (templateType === ModelTemplate.shared) return sharedCustomizationData;
    if (templateType === ModelTemplate.reserved) return tempCustomizationData;
    if (templateType === ModelTemplate.order)
      return {
        ...currentOrderData,
        properties: currentOrderData.properties.map((p) => p.propertyId)
      };
  };

  const initialSketchFabWidget = useCallback(() => {
    if (
      templateType === ModelTemplate.reserved &&
      (modelPropertyStatus !== StatusData.succeeded ||
        tempCustomizationStatus !== StatusData.succeeded ||
        !tempCustomizationData ||
        currentProductStatus !== StatusData.succeeded)
    ) {
      return;
    }

    if (
      templateType === ModelTemplate.shared &&
      (modelPropertyStatus !== StatusData.succeeded ||
        sharedCustomizationStatus !== StatusData.succeeded ||
        !sharedCustomizationData ||
        currentProductStatus !== StatusData.succeeded)
    )
      return;

    if (
      templateType === ModelTemplate.order &&
      (modelPropertyStatus !== StatusData.succeeded ||
        currentOrderStatus !== StatusData.succeeded ||
        !currentOrderData ||
        currentProductStatus !== StatusData.succeeded)
    ) {
      return;
    }

    const selectedPropertyId = getConfiguredModelProperty();
    const defaultConfiguration = initialCustomizationList(
      modelPropertyData,
      selectedPropertyId.properties
    );

    // console.log("default config: ", defaultConfiguration);

    const initialEnvironment = defaultConfiguration.find(
      (property) => property.environmentConfiguration
    )?.environmentConfiguration;
    setEnvironment(initialEnvironment);

    // console.log("default configuration: ", defaultConfiguration);
    setInitialSelectedProperty(defaultConfiguration);
    setSelectedCustomizedProperties(defaultConfiguration);

    const client = new window.Sketchfab(iframeRef.current);
    client.init(currentProductData.sketchFabModelUid, {
      success: onSuccessRender,
      error: function onError() {
        console.log("Viewer error");
      },
      ...SketchFabUIConfig
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modelPropertyStatus,
    modelPropertyData,
    tempCustomizationStatus,
    tempCustomizationData,
    currentProductStatus,
    currentProductData
  ]);

  useEffect(() => {
    initialSketchFabWidget();
  }, [initialSketchFabWidget]);

  const onSuccessRender = (api) => {
    iframeRef.current = api;
    iframeRef.current.start();

    iframeRef.current.addEventListener("viewerready", () => {
      setIsReadyView(true);

      setTimeout(() => {
        iframeRef.current.getCameraLookAt(function (err, camera) {
          if (err) return;

          const { position, target } = camera;
          initialCameraPosition.current = { position, target };
        });
      }, 2000);

      iframeRef.current.getMaterialList(function (_err, materials) {
        if (_err) return;
        materialList.current = materials;
        for (const m of materials) {
          modelTextures.current[m.name] = m.channels.AlbedoPBR.texture;
        }

        iframeRef.current.getTextureList(function (err, textures) {
          if (err) {
            console.log("get texture error");
            return;
          }
          // console.log("texture: ", textures);
          // textureList.current = textures.map((texture) => Object.values(texture));
          textureList.current = textures;
          initialMaterialModelWithOwnCustomization();
        });
      });

      iframeRef.current.getNodeMap(function (err, nodes) {
        if (err) {
          console.log("err get node map");
          return;
        }
        // console.log("nodes map: ", nodes);
        const myNodesByNameFromMap = {};
        let idxNodes = 0;

        for (const instanceID in nodes) {
          let node = nodes[instanceID];
          let name = node.name;
          if (!name) name = "noname_" + idxNodes++;
          myNodesByNameFromMap[name] = node;
        }

        //attempt to look for a 'RootNode' - this seems to be present for FBX uploaded models
        let rootNodeTree = myNodesByNameFromMap["RootNode"];
        if (rootNodeTree === undefined) {
          //attempt to look for a 'root' - this seems to be present for OBJ or single object models
          rootNodeTree = myNodesByNameFromMap["root"];
        }

        if (rootNodeTree !== undefined) {
          const treeNode = new TreeNode();
          treeNode.recurse(rootNodeTree, rootNodeTree.children.length, 0);
          officialNodes.current = treeNode.getAllNodes();
          initialNodesModelWithOwnCustomization();
        }
      });
    });
  };

  const getConfigurationFromCustomization = useCallback(
    (allPropertiesList, tempCustomizationList) => {
      const initialProperties = initialCustomizationList(allPropertiesList, tempCustomizationList);
      const configures = initialProperties.flatMap((property) => property.configures);
      return { defaultProperties: initialProperties, configures };
    },
    []
  );

  const initialMaterialModelWithOwnCustomization = useCallback(() => {
    if (!modelPropertyData || !modelPropertyData.length) return;
    if (templateType === ModelTemplate.reserved && !tempCustomizationData) return;
    if (templateType === ModelTemplate.shared && !sharedCustomizationData) return;
    if (templateType === ModelTemplate.order && !currentOrderData) return;

    const selectedModelProperty = getConfiguredModelProperty();
    const { defaultProperties, configures } = getConfigurationFromCustomization(
      modelPropertyData,
      selectedModelProperty?.properties || []
    );
    defaultProperties.forEach((property) => setEnvironment(property.environmentConfiguration));

    configures
      .filter(
        (config) =>
          config.performType === PerformType.active && config.type !== PropertyType.Visibility
      )
      .forEach((config) => {
        setPropertyMaterialOnModel(config);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempCustomizationData, modelPropertyData]);

  const initialNodesModelWithOwnCustomization = useCallback(() => {
    if (!modelPropertyData || !modelPropertyData.length) return;

    if (templateType === ModelTemplate.reserved && !tempCustomizationData) return;
    if (templateType === ModelTemplate.shared && !sharedCustomizationData) return;
    if (templateType === ModelTemplate.order && !currentOrderData) return;

    const selectedModelProperty = getConfiguredModelProperty();
    const { configures } = getConfigurationFromCustomization(
      modelPropertyData,
      selectedModelProperty?.properties
    );

    configures
      .filter(
        (config) =>
          config.performType === PerformType.active && config.type === PropertyType.Visibility
      )
      .forEach((config) => {
        setPropertyNodeOnModel(config);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempCustomizationData, modelPropertyData, modelPropertyData.length]);

  const initialCustomizationList = (allProperties, selectedIdProperties) => {
    const selectedProperties = allProperties.filter((property) =>
      selectedIdProperties.includes(property.propertyId)
    );

    const defaultProperties = allProperties.filter(
      (property) =>
        property.default &&
        !selectedProperties.some((sp) => sp.customizationPartId === property.customizationPartId)
    );

    return [...defaultProperties, ...selectedProperties];
  };

  const setPropertyMaterialOnModel = (data) => {
    const m = materialList.current.find((m) => m.name === data.materialName);
    if (!m) return;
    // console.log("m input: ", m);
    if (data.type === PropertyType.Color) {
      m.channels.AlbedoPBR.texture = false;
      m.channels.AlbedoPBR.enable = true;
      m.channels.AlbedoPBR.color = convertHexToRGB(data.color);
    } else if (data.type === PropertyType.Texture) {
      m.channels.AlbedoPBR.color = false;
      m.channels.AlbedoPBR.enable = true;
      // m.channels.AlbedoPBR.texture = true;
      // console.log("data: ", data);
      // console.log("texture list: ", textureList.current);
      const texture = textureList.current.find((texture) => texture.name === data.texture.name);
      if (!texture) return;
      // console.log("tttt: ", texture);
      // console.log("texture selected: ", { uid: texture.uid, ...data.texture });
      m.channels.AlbedoPBR.texture = { uid: texture.uid, ...data.texture };
      // m.channels.AlbedoPBR.texture = modelTextures.current[m.name];
    } else if (data.type === PropertyType.Glossiness) {
      m.channels.RoughnessPBR.enable = false;

      m.channels.GlossinessPBR.enable = true;
      m.channels.GlossinessPBR.factor = data.glossiness;
    } else if (data.type === PropertyType.Roughness) {
      m.channels.GlossinessPBR.enable = false;

      m.channels.RoughnessPBR.enable = true;
      m.channels.RoughnessPBR.factor = data.roughness;
    } else if (data.type === PropertyType.Metalness) {
      m.channels.MetalnessPBR.enable = true;
      m.channels.MetalnessPBR.factor = data.metalness;
    }
    // console.log("m output: ", m);

    iframeRef.current.setMaterial(m);
  };

  const setPropertyNodeOnModel = (data) => {
    // console.log("set node ");
    const instanceID = officialNodes.current.find(
      (node) => node.name === data.materialName
    )?.instanceID;

    if (typeof instanceID === "undefined") return;

    if (!data.visible) {
      iframeRef.current.hide(instanceID);
    } else {
      iframeRef.current.show(instanceID);
    }
  };

  const handleSharedModel = async () => {
    try {
      setIsLoading(true);

      const { modelId } = currentProductData;
      const shareData = await sharedCustomization.createShareCustomization({
        modelId: modelId,
        properties: selectedCustomizedProperties.map((custom) => custom.propertyId)
      });

      const link = `${env.domain_front_end}/model/share/${shareData._id}`;
      setShowShareLinkModal({ open: true, link });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setEnvironment = (envConfig) => {
    if (!envConfig) return;

    currentConfigurationEnvironment.current = envConfig;

    if (envConfig?.backgroundColor && iframeRef.current?.setBackground) {
      iframeRef.current.setBackground({
        color: covertHexToGRBNormal(envConfig.backgroundColor)
      });
    }

    if (envConfig?.backgroundCard) {
      const [from, to] = envConfig.backgroundCard;
      // console.log("backgroundCard: ", envConfig.backgroundCard);
      // const css = `bg-gradient-to-r from-[${from}] to-[${to}]`;
      const css = `linear-gradient(315deg, ${from} 0%, ${to} 100%)`;
      setCssCardBackground(css);
    }

    if (envConfig.primaryFontColor) {
      setForegroundColor(envConfig.primaryFontColor);
    }

    if (envConfig.button) {
      setMenuButtonTheme(envConfig.button);
    }
  };

  const handleOnUpdateProperty = (newProperty, oldProperty) => {
    if (oldProperty) {
      oldProperty.configures
        ?.filter((config) => config.performType === PerformType.inactive)
        ?.forEach((config) => {
          if (config.type === PropertyType.Visibility) {
            setPropertyNodeOnModel(config);
          } else {
            setPropertyMaterialOnModel(config);
          }
        });
    }

    newProperty.configures
      ?.filter((config) => config.performType === PerformType.active)
      ?.forEach((config) => {
        if (config.type === PropertyType.Visibility) {
          setPropertyNodeOnModel(config);
        } else {
          setPropertyMaterialOnModel(config);
        }
      });

    setEnvironment(newProperty?.environmentConfiguration);
  };

  const handleOnRemoveProperty = (property) => {
    console.log("on remove");
    const configuration = property.configures.filter(
      (config) => config.performType === PerformType.inactive
    );
    for (const config of configuration) {
      if (config.type === PropertyType.Visibility) {
        setPropertyNodeOnModel(config);
      } else {
        setPropertyMaterialOnModel(config);
      }
    }
  };

  const recenter = async () => {
    const { position, target } = initialCameraPosition.current;
    await new Promise((resolve, reject) => {
      iframeRef.current.setCameraLookAt(position, target, 0, function (err) {
        if (!err) {
          window.console.log("Camera moved");
          resolve();
        } else {
          reject("camera not move");
        }
      });
    });
  };

  const captureImage = async () => {
    const result = await new Promise((resolve, reject) => {
      iframeRef.current.getScreenShot("image/png", function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
    return result;
  };

  const getImageModel = async () => {
    await recenter();
    const base64 = await captureImage();
    const file = dataURLtoFile(base64);
    const fileService = new FileService();

    const { img_url } = await fileService.uploadFile(file);
    return img_url;
  };

  const getOrderStyle = () => {
    const isDefault = selectedCustomizedProperties.every((property) => property.default === true);
    return isDefault ? "default" : "customized";
  };

  const handleOnSubmitPayment = async (data) => {
    try {
      setIsLoading(true);
      const { modelId } = currentProductData;
      const imageUrl = await getImageModel();
      const props = {
        email: authenticationState.user.email || data.email,
        dial_code: data.dialCode,
        phone: data.phoneNumber,
        modelId: modelId,
        properties: selectedCustomizedProperties.map((property) => property.propertyId),
        first_name: data.first_name,
        last_name: data.last_name,
        country_code: data?.countryInfo?.code || "",
        country_name: data.country,
        street_address: data.streetAddress,
        city: data.city,
        zip_code: data.postalCode,
        company_name: null,
        state: data.state,
        customization_img: imageUrl,
        order_style: getOrderStyle(),
        env_config: currentConfigurationEnvironment.current,
        payment_object: {
          payment_method: "card",
          card_name: data.cardName,
          card_number: data.cardNumber,
          card_exp_month: new Date(data.expireDate).getMonth() + 1,
          card_exp_year: Number(String(new Date(data.expireDate).getFullYear()).slice(2)),
          card_cvc: data.cvc,
          currency_code: currentCurrency.dbKey
        }
      };

      const orderService = new OrderService();

      const result = authenticationState.user.isAnonymous
        ? await orderService.reserveOrderAndSignUp(props)
        : await orderService.reserveOrder(props);

      if (result.code === ResponseCode.Success) {
        console.log("success");
        const { email, password, _id: orderId } = result;
        if (authenticationState.user.isAnonymous) {
          const authenticationService = new AuthenticationService();

          const { tokenResult } = await authenticationService.signInWithEmailAndGetToken(
            email,
            password
          );

          const sessionService = new SessionService();
          sessionService.saveToken(tokenResult.token);
        }
        navigate(`../reserve/complete/${orderId}`);
      } else if (result.code == ResponseCode.Customer_Existed) {
        setErrorDialog({
          isOpen: true,
          message: t("This email has already been used. Please change to other email to reserve.")
        });
      } else if (
        result.code === ResponseCode.Payment_Failed ||
        result.code === ResponseCode.Payment_Record_Failed ||
        result.code === ResponseCode.Reject
      ) {
        setErrorDialog({
          isOpen: true,
          message: t("Cannot no use this credit card. Please change to others")
        });
      } else {
        setErrorDialog({
          isOpen: true,
          message: t("Cannot reserve your model. please refresh your browser again")
        });
      }
      // navigate(`reserve/complete/${result._id}`);

      // const { email, password } = result;
    } catch (error) {
      setErrorDialog({
        isOpen: true,
        message: t("Cannot reserve your model. please refresh your browser again")
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChangeProperty = useCallback(
    (properties) => {
      setSelectedCustomizedProperties(properties);

      if (templateType === ModelTemplate.order) return;

      if (!properties || !tempCustomizationData) return;
      const customizationTemporary = new CustomizationTemporary();
      const { _id } = tempCustomizationData;
      customizationTemporary.updateTempCustomizationPropertyList(_id, {
        properties: properties?.map((ele) => ele.propertyId)
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modelId, templateType]
  );

  const handleEventReserverTemplate = useCallback((v) => {
    setIsReserveDefault(false);
    setStepper(v);
  }, []);

  const onChangeRang = useCallback(
    (v) => {
      const { startIndex } = v;
      const groupId = customizationData[startIndex].categoryId;
      const category = categoryData.find((data) => data.groupId === groupId);
      setGroup(category);
    },
    [categoryData, customizationData]
  );

  const zoom = (factor, duration, minRadius, maxRadius) => {
    iframeRef.current.getCameraLookAt(function (err, camera) {
      if (!err) {
        var currentPos = camera.position,
          x = currentPos[0],
          y = currentPos[1],
          z = currentPos[2],
          target = camera.target,
          rho = Math.sqrt(x * x + y * y + z * z),
          phi,
          theta;

        if (isNaN(minRadius)) {
          minRadius = 0.1;
        }

        if (isNaN(maxRadius)) {
          maxRadius = Infinity;
        }

        if (rho === minRadius || rho === maxRadius) {
          return;
        }

        rho = rho * factor;

        if (rho < minRadius && factor < 1) {
          rho = minRadius;
        } else if (rho > maxRadius && factor > 1) {
          rho = maxRadius;
        }

        phi = Math.atan2(y, x);
        theta = Math.atan2(Math.sqrt(x * x + y * y), z);

        x = rho * Math.sin(theta) * Math.cos(phi);
        y = rho * Math.sin(theta) * Math.sin(phi);
        z = rho * Math.cos(theta);

        iframeRef.current.setCameraLookAt([x, y, z], target, duration);
      }
    });
  };

  const zoomIn = () => {
    const duration = 1,
      factor = 0.5,
      minRadius = 2,
      maxRadius = 10;
    zoom(1 - factor, duration, minRadius, maxRadius);
  };

  const zoomOut = () => {
    const duration = 1,
      factor = 0.5,
      minRadius = 2,
      maxRadius = 10;
    zoom(1 + factor, duration, minRadius, maxRadius);
  };

  const handleSaveConfiguration = async () => {
    try {
      setIsLoading(true);
      const imageUrl = await getImageModel();

      const params = {
        modelId: currentOrderData.model.modelId,
        properties: selectedCustomizedProperties.map((property) => property.propertyId),
        env_config: currentConfigurationEnvironment.current,
        customization_img: imageUrl,
        order_style: getOrderStyle()
      };
      const orderService = new OrderService();

      await orderService.updateConfiguration(currentOrderData._id, params);
      navigate("../reservation");
    } catch (error) {
      console.log(error);
      setErrorDialog({
        isOpen: true,
        message: t("Cannot reserve the model. Please refresh your browser and reprocess again.")
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RootTemplate>
      <div className="h-full flex flex-col h-tablet:flex-row items-stretch">
        <div
          className={`flex flex-col z-10 bg-gray-500 ${
            stepper > StepperPage.customization
              ? "h-0 flex-none invisible h-tablet:h-full h-tablet:flex-1 h-tablet:visible "
              : "h-1/3 md:h-1/2  h-tablet:h-full h-tablet:flex-1"
          }`}>
          <div className="flex-1 relative">
            <ThreeDScreen
              ref={iframeRef}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              isReadyView={isReadyView}
              onSharedModel={handleSharedModel}
              zoomIn={() => zoomIn()}
              zoomOut={() => zoomOut()}
              menuButtonTheme={menuButtonTheme}
            />
            <div
              style={{ color: foregroundColor }}
              className="absolute top-0 left-0 w-full container mx-auto">
              {stepper === StepperPage.customization ? (
                <ul
                  role="button"
                  className={`flex divide-x-2 ${
                    foregroundColor ? ` divide-[${foregroundColor}]` : ""
                  }`}>
                  {categoryData?.map((data) => (
                    <li
                      key={data?._id}
                      onClick={() => {
                        setGroup(data);
                        setActiveGroupScroll(data);
                      }}
                      className={`py-2 flex-1 text-center  text-[10px] font-normal bg-white md:text-xl ${
                        group?.groupId === data?.groupId ? " bg-opacity-40" : "bg-opacity-0"
                      }`}>
                      {data?.language?.translation[i18next.language]}
                    </li>
                  ))}
                  <li
                    onClick={() => {
                      setStepper(StepperPage.personalize);
                      setInitialSelectedProperty(selectedCustomizedProperties);
                    }}
                    className={`py-2 flex-1 text-center text-[10px] md:text-xl text-white `}>
                    {t("Summary")}
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ background: cssCardBackground, color: foregroundColor }}
          className={`${
            stepper > StepperPage.customization
              ? " h-rootMobileBody sm:h-rootBody"
              : "h-2/3 md:h-1/2"
          } w-full  h-tablet:w-[405px] xl:w-[507px] md:h-full relative shadow-none`}>
          {currentProductData && modelPropertyData && reservePriceData ? (
            <div className="h-full overflow-auto relative pb-10">
              <ReserveDefaultTemplate
                productDetail={currentProductData}
                modelProperties={modelPropertyData}
                reservePrices={reservePriceData || []}
                onPersonalize={() => handleEventReserverTemplate(StepperPage.customization)}
                onCheckOut={() => handleEventReserverTemplate(StepperPage.reserve)}
              />
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              background: cssCardBackground,
              color: foregroundColor
            }}
            className={`absolute left-0 w-full flex flex-col h-full overflow-hidden ${foregroundColor} items-stretch transform duration-300 ${
              isReserveDefault ? " bottom-full" : "bottom-0"
            }`}>
            {templateType !== ModelTemplate.order ? (
              <StepperHeader
                value={stepper}
                onChange={(v) => setStepper(v)}
                onClickSelectModel={() => {
                  navigate("/");
                }}
              />
            ) : (
              <div className="flex h-20 justify-between items-center px-3">
                <p className="font-bold text-2xl">{t("Customize Order")}</p>
                {currentOrderData ? (
                  <p className="inline-flex space-x-2">
                    <span>{t("Order")}</span> <span>{currentOrderData.order_code}</span>{" "}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div className="flex-1 overflow-hidden">
              {stepper === StepperPage.customization && (
                <>
                  {group &&
                  customizationStatus === StatusData.succeeded &&
                  modelPropertyStatus === StatusData.succeeded ? (
                    <CustomizationTemplate
                      model={currentProductData}
                      customizations={customizationData}
                      initialSelectedProperties={initialSelectedProperties}
                      properties={modelPropertyData}
                      onUpdateProperty={handleOnUpdateProperty}
                      onRemoveProperty={handleOnRemoveProperty}
                      onChangeProperty={handleOnChangeProperty}
                      isOrderMode={templateType === ModelTemplate.order}
                      onConfirm={() => {
                        setInitialSelectedProperty(selectedCustomizedProperties);
                        setStepper(StepperPage.personalize);
                      }}
                      selectedGroupCustomizationPart={activeGroupScroll}
                      onChangeRang={onChangeRang}
                      onSaveOrderConfiguration={() => handleSaveConfiguration}
                    />
                  ) : (
                    <div></div>
                  )}
                </>
              )}
              {stepper === StepperPage.personalize && (
                <div className="h-full overflow-hidden">
                  {categoryStatus === StatusData.succeeded &&
                  customizationStatus === StatusData.succeeded ? (
                    <SummaryTemplate
                      currentModel={currentProductData}
                      customizationCategoryData={categoryData}
                      customizationPartData={customizationData}
                      reservePrices={reservePriceData || []}
                      isOrderMode={templateType === ModelTemplate.order}
                      selectedProperties={selectedCustomizedProperties || []}
                      onConfirm={() => setStepper(StepperPage.reserve)}
                      onCustomized={() => setStepper(StepperPage.customization)}
                      onSaveConfiguration={() => handleSaveConfiguration()}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
              {stepper === StepperPage.reserve && (
                <div className="h-full overflow-auto">
                  <PaymentTemplate
                    isSignedIn={!authenticationState.user.isAnonymous}
                    onSubmitPayment={handleOnSubmitPayment}
                    userInfo={currentUserInfoData || null}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showShareLinkModal.open ? (
        <ShareLinkModal
          link={showShareLinkModal.link}
          onClose={() => setShowShareLinkModal({ open: false, link: null })}
        />
      ) : (
        <></>
      )}
      {isLoading ? <LoadingModal /> : <></>}
      <ErrorDialog
        isOpen={errorDialog.isOpen}
        message={errorDialog.message}
        closeModal={() => setErrorDialog({ isOpen: false })}
      />
    </RootTemplate>
  );
};

export default ModelTemplatePage;
