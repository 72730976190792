/* eslint-disable no-unused-vars */
import { Dialog, Transition } from "@headlessui/react";
import i18next, { t } from "i18next";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../core/env.core";

import { StatusData } from "../../models/module";
import { fetchProductModelAll } from "../../modules/productModel/thunk";
import BannerModel from "../shared/components/BannerModel";
import PageSegment from "../shared/components/pageSegment";
import RootTemplate from "../shared/templates/RootTemplate";
import HorizontalTemplate from "./HorizontalTemplate";
import VerticalTemplateV2 from "./VerticalTemplateV2";

const AllModelSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentModel, setCurrentModel] = useState();
  const templateRef = useRef();

  const { status: productModelStatus, data: productModelData } = useSelector(
    (state) => state.productModel.productList
  );
  const currency = useSelector((state) => state.currency);
  const authenticationState = useSelector((state) => state.authentication);

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(window.innerWidth);
  const [heightTemplate, setHeightTemplate] = useState(0);
  const [dialog, setDialog] = useState({ image: "", isOpen: false });

  const screenResponsiveEvent = useCallback(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    screenResponsiveEvent();
  }, [screenResponsiveEvent]);

  const handleOnSelectModel = useCallback((model) => {
    setCurrentModel(model);
  }, []);

  // const onPrev = useCallback(() => {
  //   const currentIndex = currentModel.index;
  //   const page = currentIndex === 0 ? productModelData.length - 1 : Math.max(currentIndex - 1, 0);
  //   setCurrentModel({ index: page, model: productModelData[page] });
  // }, [currentModel?.index, productModelData]);

  // const onNext = useCallback(() => {
  //   const total = productModelData.length - 1;
  //   const currentIndex = currentModel.index;
  //   const page = total === currentIndex ? 0 : Math.min(currentIndex + 1, total);
  //   setCurrentModel({ index: page, model: productModelData[page] });
  // }, [currentModel?.index, productModelData]);

  const onSelectModel = useCallback((model) => {
    setCurrentModel(model);
  }, []);

  const noteDetail = useMemo(() => {
    if (!currentModel) return "";
    return currentModel?.extendDemoPartInformation?.noteDetail[i18next.language] || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModel, i18next.language]);

  const backgroundScene = useMemo(() => {
    return currentModel?.environment?.backgroundScene || "";
  }, [currentModel]);

  const foreGround = useMemo(() => {
    return currentModel?.environment?.foreground || "";
  }, [currentModel]);

  useEffect(() => {
    if (!authenticationState.status || !authenticationState.token) return;
    if (productModelStatus !== StatusData.idle) return;
    dispatch(fetchProductModelAll());
  }, [authenticationState.status, authenticationState.token, dispatch, productModelStatus]);

  useEffect(() => {
    if (!productModelData || !currency) return;
    const sortedProducts = [...productModelData].sort(
      (a, b) => a.price[currency.dbKey] - b.price[currency.dbKey]
    );
    setCurrentModel(sortedProducts[0]);
  }, [productModelData, currency]);

  useEffect(() => {
    setHeightTemplate(templateRef.current.clientHeight);
  }, [width]);

  return (
    <>
      <RootTemplate>
        <div
          className="h-full"
          style={{ backgroundColor: backgroundScene || "#FFFFFF", color: foreGround || "#FFFFFF" }}>
          <div
            className="h-full"
            style={{
              background:
                "transparent radial-gradient(closest-side at 50% 50%, rgba(183, 186, 185, 0.2) 0%, rgba(0, 0, 0, 0.3) 100%) 0% 0% no-repeat padding-box"
            }}>
            <div className="h-full flex flex-col items-stretch h-full">
              <BannerModel
                currency={currency}
                onReserve={() =>
                  navigate({
                    pathname: `./model/${currentModel?.modelId}`
                  })
                }
              />
              <div className="flex py-3 px-5 justify-between items-center space-x-3 sm:space-x-5">
                <PageSegment
                  selectedItem={0}
                  onChange={(index) => index === 1 && navigate("/models")}
                />
                <div className="hidden xl:block flex-1 ">
                  <p className="text-center">{noteDetail}</p>
                </div>
                <a
                  href={config.zapp_main_page_url}
                  className=" text-sm md:text-base text-white cursor-pointer">
                  <span className="underline">{t("Back to homepage")}</span>
                </a>
              </div>
              <div className="flex-1 hidden xl:inline-block overflow-y-auto">
                <HorizontalTemplate
                  currentModel={currentModel}
                  currency={currency}
                  models={productModelData}
                  noteDetail={noteDetail}
                  onSelected={handleOnSelectModel}
                  onSelectOption={(image) => setDialog({ isOpen: true, image: image })}
                />
              </div>
              <div ref={templateRef} className="flex-1 block overflow-hidden xl:hidden">
                {/* <VerticalTemplate
                  height={heightTemplate}
                  currentIndex={currentModel?.index}
                  currency={currency}
                  models={productModelData}
                  noteDetail={noteDetail}
                  onPrev={onPrev}
                  onNext={onNext}
                  onSelectPage={onSelectPage}
                /> */}
                <VerticalTemplateV2
                  currentModel={currentModel}
                  currentIndex={currentModel?.index}
                  currency={currency}
                  noteDetail={noteDetail}
                  models={productModelData}
                  onSelectModel={handleOnSelectModel}
                />
              </div>
            </div>
          </div>
        </div>
      </RootTemplate>
      <Transition appear show={dialog.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setDialog((prev) => ({ isOpen: false, image: prev.image }))}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <img src={dialog.image} className="object-cover" />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AllModelSelection;
