import http from "../core/http.crm.core";
import httpFormData from "../core/http.crm.form-data.core";
export class FileService {
  async uploadModelBase64(base64) {
    const { data } = await http.post("order/upload64Img", { base64: base64 });
    return data;
  }

  async uploadFile(file) {
    const { data } = await httpFormData.post("order/uploadImg", { file: file });
    return data;
  }
}
