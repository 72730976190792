import ErrorInputText from "../../../shared/components/ErrorInputText";

const FormInput = ({ title, type, inputProps, attributeProp = {}, error }) => {
  return (
    <div className="flex flex-col space-y-2">
      {title ? <p className="font-bold text-xs md:text-base">{title}</p> : <></>}
      <input
        maxLength={attributeProp.maxLength}
        id={attributeProp.id}
        type={type}
        className="px-3 py-2 border-2 rounded bg-white bg-opacity-40 w-full"
        {...inputProps}
        // pattern="\d*" maxlength="4"
      />
      <ErrorInputText text={error || ""} />
    </div>
  );
};

export default FormInput;
