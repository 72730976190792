import { NumericFormat } from "react-number-format";
import ChevronRight from "../../../shared/Icons/CheronRight";
import ChevronLeft from "../../../shared/Icons/ChevronLeft";

const CarouselIndicator = ({
  model,
  prev,
  next,
  pages = 0,
  onSelectPage,
  currentPage,
  currency
}) => {
  const isActive = (index) => (index === currentPage ? "bg-white" : "border border-white");

  return (
    <div className="flex flex-col items-center space-y-5 pb-3">
      <div className="h-[72px] flex flex-col justify-end">
        <p className="text-xl md:text-3xl font-bold text-white text-center w-[240px]">
          {model?.name}
        </p>
        {model?.price && (
          <p className="text-base md:text-2xl font-bold text-white text-center mt-2">
            <span>{currency.prefix}</span>{" "}
            <NumericFormat
              value={model?.price[currency.dbKey]}
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        )}
      </div>
      <div className="flex items-center space-x-8">
        <button className="text-white" onClick={() => prev()}>
          <ChevronLeft />
        </button>
        <ul role="button" className="flex space-x-3">
          {Array.from({ length: pages })?.map((_, i) => (
            <li
              key={i}
              className={`w-3 h-3 rounded-full ${isActive(i)}`}
              onClick={() => onSelectPage(i)}></li>
          ))}
        </ul>
        <button className="text-white" onClick={() => next()}>
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default CarouselIndicator;
