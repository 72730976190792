/* eslint-disable no-unused-vars */
import { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchProductModelAll } from "../../modules/productModel/thunk";
import { useMemo } from "react";

import { StatusData } from "../../models/module";
import RootTemplate from "../shared/templates/RootTemplate";
import { Breakpoint } from "../../theme/config.them";
import i18next from "i18next";
import config from "../../core/env.core";
import { useTranslation } from "react-i18next";

import CarouselIndicator from "./components/CarouselIndicator";
import { useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import PageSegment from "../shared/components/pageSegment";
import BannerModel from "../shared/components/BannerModel";

const ModelSelection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [swiperRef, setSwiperRef] = useState(null);

  const {
    status: productModelStatus,
    data: productModelData
    // error: productModelError
  } = useSelector((state) => state.productModel.productList);

  const authenticationState = useSelector((state) => state.authentication);

  const [currentModel, setCurrentModel] = useState();
  const [currentIndex, setCurrenIndex] = useState(0);

  const currentCurrency = useSelector((state) => state.currency);

  const [dialog, setDialog] = useState({ image: "", isOpen: false });

  useEffect(() => {
    if (!authenticationState.status || !authenticationState.token) return;
    if (productModelStatus !== StatusData.idle) return;
    dispatch(fetchProductModelAll());
  }, [authenticationState.status, authenticationState.token, dispatch, productModelStatus]);

  useEffect(() => {
    if (!productModelData) return;
    setCurrentModel(productModelData[0]);
    setCurrenIndex(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productModelData]);

  const backgroundScene = useMemo(() => {
    return productModelData[currentIndex]?.environment?.backgroundScene || "";
  }, [currentIndex, productModelData]);

  const foreGround = useMemo(() => {
    return productModelData[currentIndex]?.environment?.foreground || "";
  }, [currentIndex, productModelData]);

  const [width, setWidth] = useState(window.innerWidth);

  const screenResponsiveEvent = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };

  useEffect(() => {
    screenResponsiveEvent();
  }, []);

  const getIncludePart = (parts = []) => {
    const includePart = [...parts].sort((a, b) => a.order - b.order);
    return includePart;
  };

  const onPrev = useCallback(() => {
    const page = currentIndex === 0 ? productModelData.length - 1 : Math.max(currentIndex - 1, 0);
    setCurrenIndex(page);
  }, [currentIndex, productModelData.length]);

  const onNext = useCallback(() => {
    const total = productModelData.length - 1;
    const page = total === currentIndex ? 0 : Math.min(currentIndex + 1, total);
    setCurrenIndex(page);
  }, [currentIndex, productModelData.length]);

  const onSelectPage = useCallback((index) => {
    setCurrenIndex(index);
  }, []);

  useEffect(() => {
    if (!productModelData) return;
    setCurrentModel(productModelData[currentIndex]);
  }, [currentIndex, productModelData]);

  useEffect(() => {
    if (!swiperRef) return;
    swiperRef.slideTo(currentIndex, 300);
  }, [currentIndex, swiperRef]);

  const noteDetail = useMemo(() => {
    if (!currentModel) return "";
    return currentModel?.extendDemoPartInformation?.noteDetail[i18next.language] || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModel, i18next.language]);

  return (
    <>
      <RootTemplate>
        <div
          className="h-full"
          style={{ backgroundColor: backgroundScene || "#FFFFFF", color: foreGround || "#FFFFFF" }}>
          <div
            className="h-full"
            style={{
              background:
                "transparent radial-gradient(closest-side at 50% 50%, rgba(183, 186, 185, 0.2) 0%, rgba(0, 0, 0, 0.3) 100%) 0% 0% no-repeat padding-box"
            }}>
            <div className="flex flex-col h-full">
              <BannerModel
                currency={currentCurrency}
                onReserve={() =>
                  navigate({
                    pathname: `../model/${productModelData[currentIndex].modelId}`
                  })
                }
              />
              <div className="flex py-3 px-5">
                <PageSegment selectedItem={1} onChange={(index) => index === 0 && navigate("/")} />
              </div>
              <div className="flex-1 overflow-hidden">
                {productModelStatus == StatusData.succeeded && productModelData ? (
                  <div className="flex flex-col w-full relative h-full  overflow-y-auto lg:items-center xl:justify-center xl:flex-row  xl:overflow-hidden">
                    {width >= Breakpoint.xl ? (
                      <div className="flex-1 flex flex-col justify-center h-full items-center space-y-5 md:mt-5">
                        <CarouselIndicator
                          model={currentModel || {}}
                          currentPage={currentIndex}
                          pages={productModelData?.length}
                          prev={onPrev}
                          next={onNext}
                          currency={currentCurrency}
                          onSelectPage={onSelectPage}
                        />
                        <div className="w-[300px]">
                          <p className="text-xs md:text-sm max-w-md text-center px-2">
                            {noteDetail}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Carousels */}
                    <div className="flex-grow xl:flex-none w-full lg:w-[688px] space-y-2 lg:space-y-5 xl:space-y-0 h-auto xl:h-full flex flex-col items-stretch xl:justify-center">
                      <Swiper
                        className="w-full aspect-4/3 xl:h-[calc(100vh-380px)]"
                        onSwiper={setSwiperRef}
                        spaceBetween={0}
                        allowTouchMove={false}>
                        {productModelData?.map((data, i) => (
                          <SwiperSlide
                            key={i.toString()}
                            className="w-screen h-full md:w-[600px] lg:w-[688px]">
                            <div className="flex flex-col items-center justify-end md:justify-center h-full ">
                              <img src={data.image} className="w-full h-auto object-cover " />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      {currentModel?.extendDemoPartInformation?.parts ? (
                        <div className="flex flex-col space-y-3 lg:space-y-5 xl:space-y-3 text-white overflow-hidden items-stretch sm:items-center lg:items-stretch w-full md:w-auto">
                          <p className="text-center md:text-left px-2">
                            {
                              currentModel?.extendDemoPartInformation?.description?.name[
                                i18next.language
                              ]
                            }
                          </p>
                          <ul
                            role="button"
                            className="flex space-x-3 whitespace-nowrap md:whitespace-normal  overflow-x-auto md:overflow-hidden pb-5 px-2">
                            {getIncludePart(currentModel?.extendDemoPartInformation?.parts).map(
                              (part, index) => (
                                <li
                                  key={index.toString()}
                                  onClick={() => setDialog({ isOpen: true, image: part.image })}>
                                  <div className="flex flex-col items-stretch space-y-1 outline-none border-none w-[80px] md:w-[120px] whitespace-normal">
                                    <img
                                      src={part.image}
                                      className="h-[80px] md:h-[100px] object-cover border"
                                    />
                                    <p className="text-xs md:text-sm text-center text-white">
                                      {part.name[i18next.language]}
                                    </p>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      ) : (
                        <div className="h-0 xl:h-[200px]"></div>
                      )}
                      {width < Breakpoint.xl ? (
                        <div className="py-5 flex flex-col items-center">
                          <CarouselIndicator
                            model={currentModel || {}}
                            currentPage={currentIndex}
                            pages={productModelData?.length}
                            prev={onPrev}
                            next={onNext}
                            onSelectPage={onSelectPage}
                            currency={currentCurrency}
                          />
                          <div className=" w-[300px]">
                            <p className="text-xs md:text-sm max-w-md text-center px-2">
                              {noteDetail}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="w-full xl:flex-1 flex flex-col items-center justify-center space-y-5 h-full ">
                      <div className="flex flex-col gap-5 lg:space-y-0 lg:flex-row xl:flex-col ">
                        <button
                          onClick={() =>
                            navigate({
                              pathname: `../model/${productModelData[currentIndex].modelId}`,
                              search: "?start=personalize"
                            })
                          }
                          className=" rounded bg-white bg-opacity-40 text-black flex items-center justify-center w-[276px] h-[47px] md:h-[67px] text-base md:text-2xl font-medium">
                          {t("Personalise")}
                        </button>
                        <button
                          onClick={() =>
                            navigate({
                              pathname: `../model/${productModelData[currentIndex].modelId}`
                            })
                          }
                          className=" rounded bg-white h-[47px] text-black md:h-[67px] bg-opacity-40 flex items-center justify-center w-[276px] text-base md:text-2xl font-medium">
                          {t("Reserve")}
                        </button>
                      </div>
                      <a
                        href={config.zapp_main_page_url}
                        className=" text-white cursor-pointer pb-5">
                        <span className="underline">{t("Back to homepage")}</span>
                      </a>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </RootTemplate>
      <Transition appear show={dialog.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setDialog({ isOpen: false })}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <img src={dialog.image} className="object-cover" />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModelSelection;
