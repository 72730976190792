/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState, Fragment } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "../../core/firebase.core";
import { signOut, onAuthStateChanged } from "firebase/auth";
import Menu from "../../components/Menu";
import downloadPic from "../Reservation/assets/images/Download.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18next from "i18next";
import { t } from "i18next";
import { Currency } from "../../models/currency";
import { Listbox, Transition, Combobox } from "@headlessui/react";
import { fetchCountryListAsset } from "../../../src/modules/assets/thunk";

function Reservation() {
  const auth = firebaseAuth;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [orderCode, setOrderCode] = useState("");
  const [openOrderSummary, setOpenOrderSummary] = useState(false);
  const [openOrderAddress, setOpenOrderAddress] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formDataPay, setformDataPay] = useState([]);
  const [formDataSummary, setformDataSummary] = useState([]);
  const [currency, setCurrency] = useState("");

  const [countryName, setCountryName] = useState(null);
  const [errorCountry, setErrorCountry] = useState(null);
  const [errorCity, setErrorCity] = useState(null);
  const [errorStreet, setErrorStreet] = useState(null);
  const [errorZipCode, setErrorZipCode] = useState(null);
  const [errorCardNumber, setErrorCardNumber] = useState(null);
  const [errorCardName, setErrorCardName] = useState(null);
  const [errorExpMonth, setErrorExpMonth] = useState(null);
  const [errorExpYear, setErrorExpYear] = useState(null);
  const [errorCvc, setErrorCvc] = useState(null);
  const [amountShowFormPay, setAmountShowFormPay] = useState(null);
  const [applyFormPay, setApplyFormPay] = useState(null);
  // const [langCurrent, setLangCurrent] = useState(i18next.languages[1]);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const {
    setValue
    // getValues
  } = useForm();
  const [isLoadingDelivery, setIsLoadingDelivery] = useState(false);
  const [isLoadingPay, setIsLoadingPay] = useState(false);

  const MySwal = withReactContent(Swal);

  const [country, setCountry] = useState([]);

  useEffect(() => {
    dispatch(fetchCountryListAsset());
    onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        if (user.isAnonymous == true) {
          navigate("/");
        }
      } else {
        navigate("/sign-in");
      }
    });
    const token = window.sessionStorage.getItem("authenticationToken");
    const getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/order/list";
    axios
      .get(getValURL, {
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.code === 1) {
            setData(response.data.orders);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line
    }, [auth]);

  const { status: countriesStatus, data: countiesData } = useSelector(
    (state) => state.assets.countries
  );

  const filteredCountry = useMemo(() => {
    if (!countiesData) return [];
    return query === ""
      ? countiesData
      : countiesData.filter((country) =>
          country.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  }, [countiesData, query]);

  const ClickOrderSummary = (event) => {
    event.preventDefault();
    setOrderId(event.target.value);

    // console.log('id ',event.target.value)
    if (event.target.value) {
      const getValURL =
        process.env.REACT_APP_DOMAIN_NAME + "/crm/order/fullDetail/" + event.target.value;
      const token = window.sessionStorage.getItem("authenticationToken");

      const request = {
        url: getValURL,
        method: "get",
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.code === 1) {
              setformDataSummary(response.data);
              setCurrency(response.data.currency_code);
              setOrderCode(response.data.order_code);
              setOpenOrderSummary(!openOrderSummary);
            }
          }
        })
        .catch(function (error) {
          //
        });
    }
  };
  const ClickOrderAddress = (event) => {
    setOpenOrderAddress(!openOrderAddress);
    setOrderId(event.target.value);
    setErrorCountry("");
    setErrorCountry("");
    setErrorCity("");
    setErrorZipCode("");
    setErrorStreet("");
    if (event.target.value) {
      const getValURL =
        process.env.REACT_APP_DOMAIN_NAME + "/crm/order/address/" + event.target.value;
      const token = window.sessionStorage.getItem("authenticationToken");

      const request = {
        url: getValURL,
        method: "get",
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.code === 1) {
              setFormData({
                ...formData,
                country_name: response.data.country_name,
                country_code: response.data.country_code,
                street_address: response.data.street_address,
                city: response.data.city,
                state: response.data.state,
                zip_code: response.data.zip_code,
                use_customer_address: response.data.use_customer_address
              });
              setOrderCode(response.data.order_code);
            }
          }
        })
        .catch(function (error) {
          //
        });
    }
  };
  const ClickDeposit = (event) => {
    event.preventDefault();
    setOpenOrderSummary(false);
    setOpenDeposit(!openDeposit);
    setErrorCardNumber(null);
    setErrorCardName(null);
    setErrorExpMonth(null);
    setErrorExpYear(null);
    setErrorCvc(null);
    setformDataPay({
      ...formDataPay,
      payment_method: "card",
      currency_code: currency,
      card_number: "",
      card_name: "",
      card_exp_month: "",
      card_exp_year: "",
      card_cvc: ""
    });
    setApplyFormPay(event.target.value);

    if (event.target.value == 1) {
      //deposit
      // GET https://backend.zappev.com/crm/payment/preFullPrice/deposite/usd
      const getValURL =
        process.env.REACT_APP_DOMAIN_NAME + "/crm/payment/preFullPrice/deposite/" + currency;
      const token = window.sessionStorage.getItem("authenticationToken");

      const request = {
        url: getValURL,
        method: "get",
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.code === 1) {
              setAmountShowFormPay(response.data.currency_symbol + response.data.price);
            }
          }
        })
        .catch(function (error) {
          //
        });
    } else {
      //paid off
      setAmountShowFormPay(formDataSummary.remain_amount);
    }
  };
  function handleCheckboxChange(event) {
    // setFormData({ ...formData, use_customer_address: event.target.checked });
    const token = window.sessionStorage.getItem("authenticationToken");
    if (event.target.checked == true) {
      axios
        .get(process.env.REACT_APP_DOMAIN_NAME + "/crm/account/info", {
          headers: {
            "content-type": "application/json",
            Authorization: token
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            const d = response.data.customer_address;

            // setFormData(d);
            setFormData({
              ...formData,
              country_name: d.country_name,
              country_code: d.country_code,
              street_address: d.street_address,
              city: d.city,
              state: d.state,
              zip_code: d.zip_code,
              use_customer_address: true
            });
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      const request = {
        url: process.env.REACT_APP_DOMAIN_NAME + "/crm/order/address/" + orderId,
        method: "get",
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.code === 1) {
              setFormData({
                ...formData,
                country_name: response.data.country_name,
                country_code: response.data.country_code,
                street_address: response.data.street_address,
                city: response.data.city,
                state: response.data.state,
                zip_code: response.data.zip_code,
                use_customer_address: false
              });
            }
          }
        })
        .catch(function (error) {
          //
        });
    }
  }
  const handleChangeAddress = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });

    if (event.target.name === "country_code") {
      if (event.target.value === null) {
        setErrorCountry(t("This field is required."));
      } else {
        setErrorCountry(null);
        setCountryName(event.target.options[event.target.selectedIndex].getAttribute("data-name"));
      }
    }
    if (event.target.name === "country_name") {
      if (event.target.value === null) {
        setErrorCountry(t("This field is required."));
      } else {
        setErrorCountry(null);
      }
    }
    if (event.target.name === "city") {
      if (event.target.value === null) {
        setErrorCity(t("This field is required."));
      } else {
        setErrorCity(null);
      }
    }
    if (event.target.name === "street_address") {
      if (event.target.value === null) {
        setErrorStreet(t("This field is required."));
      } else {
        setErrorStreet(null);
      }
    }
    if (event.target.name === "zip_code") {
      if (event.target.value === null) {
        setErrorZipCode(t("This field is required."));
      } else {
        setErrorZipCode(null);
      }
    }
  };

  const handleChangeCard = (event) => {
    setformDataPay({
      ...formDataPay,
      [event.target.name]: event.target.value
    });

    if (event.target.name === "card_number") {
      if (event.target.value === null) {
        setErrorCardNumber(t("This field is required."));
      } else if (isNaN(event.target.value)) {
        setErrorCardNumber(t("Number only."));
      } else {
        setErrorCardNumber(null);
      }
    }
    const lettersRegex = /^[a-zA-Z ]+$/;
    if (event.target.name === "card_name") {
      if (event.target.value === null) {
        setErrorCardName(t("This field is required."));
      } else if (lettersRegex.test(event.target.value) == false) {
        setErrorCardName(t("Please enter characters only"));
      } else {
        setErrorCardName(null);
      }
    }
    if (event.target.name === "card_exp_month") {
      if (event.target.value === null) {
        setErrorExpMonth(t("This field is required."));
      } else if (isNaN(event.target.value)) {
        setErrorExpMonth(t("Number only."));
      } else {
        setErrorExpMonth(null);
      }
    }
    if (event.target.name === "card_exp_year") {
      if (event.target.value === null) {
        setErrorExpYear(t("This field is required."));
      } else if (isNaN(event.target.value)) {
        setErrorExpYear(t("Number only."));
      } else {
        setErrorExpYear(null);
      }
    }
    if (event.target.name === "card_cvc") {
      if (event.target.value === null) {
        setErrorCvc(t("This field is required."));
      } else if (isNaN(event.target.value)) {
        setErrorCvc(t("Number only."));
      } else {
        setErrorCvc(null);
      }
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const langCurrent = (data) => {
    if (data.language) {
      if (i18next.languages[0] == "en") {
        return data.language.translation.en;
      } else if (i18next.languages[0] == "fr") {
        return data.language.translation.fr;
      } else if (i18next.languages[0] == "es") {
        return data.language.translation.es;
      } else if (i18next.languages[0] == "it") {
        return data.language.translation.it;
      } else {
        return data.language.translation.en;
      }
    } else {
      return data.name;
    }
  };
  const langCurrentDetail = (data) => {
    if (data.langauge) {
      if (i18next.languages[0] == "en") {
        return data.langauge[0].translation.en;
      } else if (i18next.languages[0] == "fr") {
        return data.langauge[0].translation.fr;
      } else if (i18next.languages[0] == "es") {
        return data.langauge[0].translation.es;
      } else if (i18next.languages[0] == "it") {
        return data.langauge[0].translation.it;
      } else {
        return data.langauge[0].translation.en;
      }
    } else {
      return data.name;
    }
  };
  const CurrencyChk = (price) => {
    if (currency == "eur") {
      return "€" + price.euro;
    } else if (currency == "gbp") {
      return "£" + price.pound;
    } else if (currency == "usd") {
      return "$" + price.usd;
    } else {
      return "€" + price.euro;
    }
  };
  const CurrencySymbol = () => {
    if (currency == "eur") {
      return "€";
    } else if (currency == "gbp") {
      return "£";
    } else if (currency == "usd") {
      return "$";
    } else {
      return "€";
    }
  };
  const IncrementEstimate = (date) => {
    const increment = 56 * 24 * 60 * 60 * 1000;
    const newDate = new Date(date.getTime() + increment);
    return newDate.toISOString();
  };
  const formatDateEng = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleString("en-US", options);
  };
  const handleClickSaveAddress = (event) => {
    event.preventDefault();
    if (!formData.country_code) {
      setErrorCountry(t("This field is required."));
    }
    if (!formData.country_name) {
      setErrorCountry(t("This field is required."));
    }
    if (!formData.city) {
      setErrorCity(t("This field is required."));
    }
    if (!formData.zip_code) {
      setErrorZipCode(t("This field is required."));
    }
    if (!formData.street_address) {
      setErrorStreet(t("This field is required."));
    }

    if (
      formData.country_code &&
      formData.country_name &&
      formData.city &&
      formData.zip_code &&
      formData.street_address
    ) {
      setIsLoadingDelivery(true);
      const getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/order/address/" + orderId;
      const token = window.sessionStorage.getItem("authenticationToken");
      const request = {
        url: getValURL,
        method: "patch",
        data: formData,
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          if (response.status == 200) {
            if (response.data.code == 1) {
              MySwal.fire({
                title: "saved successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                setIsLoadingDelivery(false);
                setOpenOrderAddress(false);
              });
            } else {
              MySwal.fire({
                title: response.data.message,
                icon: "error",
                showConfirmButton: true
              }).then(() => {
                setIsLoadingDelivery(false);
              });
            }
          }
        })
        .catch(function (error) {
          MySwal.fire({
            title: error,
            icon: "error",
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            setIsLoadingDelivery(false);
          });
        });
    }
  };
  const status = (val) => {
    let res = "";
    if (val == "paid off") {
      res = 3;
    } else if (val == "reserved") {
      res = 1;
    } else if (val == "deposited") {
      res = 2;
    } else {
      res = "";
    }
    return res;
  };

  const handleClickSavePayment = (event) => {
    event.preventDefault();
    if (!formDataPay.card_number) {
      setErrorCardNumber(t("This field is required."));
    } else if (isNaN(formDataPay.card_number)) {
      setErrorCardNumber(t("Number only."));
    }
    const lettersRegex = /^[a-zA-Z ]+$/;
    if (!formDataPay.card_name) {
      setErrorCardName(t("This field is required."));
    } else if (lettersRegex.test(formDataPay.card_name) == false) {
      setErrorCardName(t("Please enter characters only"));
    }
    if (!formDataPay.card_exp_month) {
      setErrorExpMonth(t("This field is required."));
    } else if (isNaN(formDataPay.card_exp_month)) {
      setErrorExpMonth(t("Number only."));
    }
    if (!formDataPay.card_exp_year) {
      setErrorExpYear(t("This field is required."));
    } else if (isNaN(formDataPay.card_exp_year)) {
      setErrorExpYear(t("Number only."));
    }
    if (!formDataPay.card_cvc) {
      setErrorCvc(t("This field is required."));
    } else if (isNaN(formDataPay.card_cvc)) {
      setErrorCvc(t("Number only."));
    }

    if (
      formDataPay.card_number &&
      !isNaN(formDataPay.card_exp_month) &&
      !isNaN(formDataPay.card_exp_year) &&
      !isNaN(formDataPay.card_number) &&
      !isNaN(formDataPay.card_cvc) &&
      formDataPay.card_name &&
      formDataPay.card_exp_month &&
      formDataPay.card_exp_year &&
      formDataPay.card_cvc &&
      lettersRegex.test(formDataPay.card_name) == true
    ) {
      setIsLoadingPay(true);
      let getValURL = "";
      if (applyFormPay == 1) {
        //deposit
        getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/payment/deposite";
      } else {
        //paid off
        getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/payment/payoff";
      }
      const token = window.sessionStorage.getItem("authenticationToken");
      const request = {
        url: getValURL,
        method: "post",
        data: {
          order_id: orderId,
          payment_object: formDataPay
        },
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          if (response.status == 200) {
            if (response.data.code == 1) {
              MySwal.fire({
                title: "pay successful",
                icon: "success",
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                setIsLoadingPay(false);
                setOpenDeposit(false);
              });
            } else {
              MySwal.fire({
                title: response.data.message,
                icon: "error",
                showConfirmButton: true
              }).then(() => {
                setIsLoadingPay(false);
              });
            }
          }
        })
        .catch(function (error) {
          MySwal.fire({
            title: error.response.data.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500
          }).then(() => {
            setIsLoadingPay(false);
          });
        });
    }
  };
  return (
    <div>
      <Menu></Menu>
      <div className="xl:container mx-auto pb-5 pt-5 px-2">
        <div className="form-container flex flex-wrap">
          <div className="form-left lg:w-4/4 sm:w-full md:w-full w-full py-4">
            <div className="flex">
              <h2 className="text-2xl mb-3 text-center sm:text-center md:text-center lg:text-left">
                {t("Reservation")}
              </h2>
            </div>
            <hr></hr>
          </div>
        </div>

        {data.length ? (
          data.map((item) => (
            <div
              key={item._id}
              className="xl:container mx-auto mb-4 px-1 md:px-6 py-4 bg-profile-bg border border-solid border-[#F4F4F4] rounded-lg">
              <div className="w-full">
                <div className="flex flex-wrap items-center">
                  <div className="w-full md:w-2/6 px-3 mb-3 md:mb-0">
                    <label
                      className="block text-md tracking-wide text-black font-medium flex justify-center md:justify-start sm:justify-center"
                      htmlFor="email">
                      {t("Order only")} {item.order_code}
                      <span className="font-regular text-gray-500 ml-3">
                        {formatDate(item.order_date)}
                      </span>
                    </label>
                    <label
                      className="block text-lg tracking-wide text-black font-bold flex mt-3 justify-center md:justify-start sm:justify-center"
                      htmlFor="email">
                      {item.model.name}
                    </label>
                    <div className="flex flex-wrap items-center text-center md:text-left sm:text-center">
                      <div className="w-full md:w-1/2 sm:w-1/2 w-1/2  md:mb-0">
                        <button
                          className="underline mt-2"
                          onClick={ClickOrderSummary}
                          value={item._id}>
                          {" "}
                          {t("Order Summary")}
                        </button>
                      </div>
                      <div className="w-full md:w-1/2 sm:w-1/2 w-1/2   md:mb-0">
                        <button
                          className="underline mt-2"
                          onClick={ClickOrderAddress}
                          value={item._id}>
                          {" "}
                          {t("Delivery address")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-3/6 px-3 mb-3 md:mb-0">
                    <div className="flex flex-wrap items-center text-center md:text-left sm:text-center">
                      <div className="w-full md:w-1/3 sm:w-1/3 w-1/3  block text-center md:mb-0">
                        <div className="limit-text-1">{t("Reserve")}</div>
                        <button
                          className={`${
                            item.status_code == 1 || item.status_code == 2 || item.status_code == 3
                              ? "btn-green"
                              : "btn-gray"
                          } mt-2 btn  `}>
                          {item.status_code == 1 || item.status_code == 2 || item.status_code == 3
                            ? t("Paid")
                            : t("Pending")}
                        </button>
                      </div>
                      <div className="w-full md:w-1/3 sm:w-1/3 w-1/3 text-center  md:mb-0">
                        <div className="limit-text-1">{t("Deposit")}</div>
                        <button
                          className={`${
                            item.status_code == 2 || item.status_code == 3
                              ? "btn-green"
                              : "btn-gray"
                          } mt-2 btn  `}>
                          {item.status_code == 2 || item.status_code == 3
                            ? t("Paid")
                            : t("Pending")}
                        </button>
                      </div>
                      <div className="w-full md:w-1/3 sm:w-1/3 w-1/3 text-center  md:mb-0">
                        <div className="limit-text-1">{t("Paid all")}</div>
                        <button
                          className={`${
                            item.status_code == 3 ? "btn-green" : "btn-gray"
                          } mt-2 btn  `}>
                          {item.status_code == 3 ? t("Paid") : t("Pending")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/6 px-3 mb-3 md:mb-0">
                    <div className="flex flex-wrap items-center">
                      <div className="w-full md:w-1/1 sm:w-1/1 w-1/1 block text-center md:text-left sm:text-center md:mb-0">
                        <div>{t("Estimate Delivery")}</div>
                        <p className="text-xl font-medium">
                          {formatDateEng(new Date(IncrementEstimate(new Date(item.order_date))))}{" "}
                        </p>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`/model/order/` + item._id}
                          className=" btn-red">
                          <span className="limit-text-1">{t("Customize Order")}</span>{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left"
                            viewBox="0 0 16 16">
                            {" "}
                            <path
                              fillRule="evenodd"
                              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                            />{" "}
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="xl:container text-center mx-auto mt-3 px-1 md:px-6 py-2 bg-profile-bg border border-solid border-[#F4F4F4] rounded-lg">
            <div className="w-full block items-center text-center">
              <span className="text-[#EC2028] text-center">Order not found.</span>
            </div>
          </div>
        )}

        {/* add order */}
        <div className="xl:container mx-auto mt-10 px-1 md:px-6 py-2 bg-profile-bg border border-solid border-[#F4F4F4] rounded-lg">
          <div className="w-full flex flex-wrap items-center">
            <a href="/" className="w-full md:w-1/1 px-3 mb-3 md:mb-0 text-center">
              <div className="btn-new-order">
                <div>+</div>
              </div>
              <span className="text-[#EC2028]">{t("New order")}</span>
            </a>
          </div>
        </div>

        {/* Modal summary */}
        {openOrderSummary && (
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none box-open-modal">
            <div className="relative w-auto my-6 mx-auto md:w-5/6 ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-900 rounded-t ">
                  <div className="block">
                    <h3 className="text-black text-md font-semibold">
                      {t("Order only")} {formDataSummary.order_code}
                    </h3>
                    <p className="text-gray-500 text-sm">
                      {t("Order Date")}: {formatDate(formDataSummary.order_date)}
                    </p>
                  </div>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setOpenOrderSummary(false)}>
                    <span className="text-black opacity-7 h-6 w-6 text-xl block  rounded-full">
                      x
                    </span>
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <form className="rounded w-full">
                    <div className="flex">
                      <div className="w-full md:w-5/6">
                        <label className="block text-black text-md font-bold mb-1">
                          {formDataSummary.model.name || ""}
                        </label>
                      </div>
                      <div className="w-full md:w-1/6">
                        <label className="block text-black text-right text-md font-bold mb-1">
                          {CurrencySymbol()}
                          {formDataSummary.remain_amount}
                        </label>
                      </div>
                    </div>
                    <div className="flex pb-4 border-b border-solid border-blueGray-200">
                      <div className="w-full md:w-1/6 md:flex items-center mb-2">
                        <label className="block text-black text-md font-bold limit-text-1">
                          {t("Order Status")} :
                        </label>
                      </div>
                      <div className="w-full md:w-5/6 mb-2">
                        {/* formDataSummary.order_status */}
                        <div
                          className={`${
                            status(formDataSummary.order_status) == 1 ||
                            status(formDataSummary.order_status) == 2 ||
                            status(formDataSummary.order_status) == 3
                              ? "btn-green"
                              : "btn-gray"
                          } mx-2 my-2 btn inline-block text-center`}>
                          {status(formDataSummary.order_status) == 1 ||
                          status(formDataSummary.order_status) == 2 ||
                          status(formDataSummary.order_status) == 3
                            ? t("Reserved")
                            : t("Reserve")}
                        </div>
                        <div
                          className={`${
                            status(formDataSummary.order_status) == 2 ||
                            status(formDataSummary.order_status) == 3
                              ? "btn-green"
                              : "btn-gray"
                          } mx-2 my-2 btn inline-block text-center`}>
                          {status(formDataSummary.order_status) == 2 ||
                          status(formDataSummary.order_status) == 3
                            ? t("Deposited")
                            : t("Deposit")}
                        </div>
                        <div
                          className={`${
                            status(formDataSummary.order_status) == 3 ? "btn-green" : "btn-gray"
                          } mx-2 my-2 btn inline-block text-center`}>
                          {status(formDataSummary.order_status) == 3 ? t("Paid all") : t("Pay All")}
                        </div>
                      </div>
                    </div>

                    {formDataSummary.properties.map((item) => (
                      <div
                        key={item._id}
                        className={`${
                          item.properties.length > 0
                            ? "border-b border-solid border-blueGray-20 py-2"
                            : ""
                        } flex 0 `}>
                        {item.properties.length > 0 && (
                          <>
                            <div className="md:w-1/6 sm:w-2/6 w-2/6">
                              <p className="block text-black text-md font-bold mb-1">
                                {langCurrent(item)} :
                              </p>
                            </div>

                            <div className="md:w-5/6 sm:w-4/6 w-5/6">
                              {item.properties.map((row) => (
                                <div key={row._id} className="flex">
                                  <div className="w-full md:w-5/6 ">
                                    <p className="block text-black text-md font-light mb-1">
                                      {langCurrentDetail(row)}
                                    </p>
                                  </div>
                                  <div className="w-full md:w-1/6">
                                    <label className="block text-black text-right text-md font-bold mb-1">
                                      {CurrencyChk(row.price)}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </form>
                </div>
                <div className="flex items-center  p-4 border-t border-solid border-gray-900 rounded-b">
                  {!formDataSummary.customization_pdf && (
                    <>
                      <div className="w-full md:w-4/6 flex items-center"></div>
                      <div className="w-full md:w-2/6">
                        <label className="block text-black text-right text-md font-bold mb-1">
                          {t("Net Total")}{" "}
                          <span className="ml-2">
                            {CurrencySymbol()}
                            {formDataSummary.remain_amount}
                          </span>
                        </label>
                      </div>
                    </>
                  )}
                  {formDataSummary.customization_pdf && (
                    <>
                      <div className="w-full md:w-4/6 flex items-center">
                        <img src={downloadPic} />
                        <a
                          href={formDataSummary.customization_pdf}
                          target="_blank"
                          rel="noreferrer"
                          className="block text-black text-md font-regular ml-2 underline limit-text-1">
                          <span className="limit-text-1">{t("Download order information")}</span>
                        </a>
                      </div>
                      <div className="w-full md:w-2/6">
                        <label className="block text-black text-right text-md font-bold mb-1">
                          {t("Net Total")}{" "}
                          <span className="ml-2">
                            {CurrencySymbol()}
                            {formDataSummary.remain_amount}
                          </span>
                        </label>
                      </div>
                    </>
                  )}
                </div>
                {status(formDataSummary.order_status) != 3 && (
                  <div className="flex items-center justify-center p-6  rounded-b">
                    <button
                      className="text-white bg-[#EC2028] active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      onClick={ClickDeposit}
                      value={status(formDataSummary.order_status)}>
                      {status(formDataSummary.order_status) == 1 ? t("Deposit") : ""}
                      {status(formDataSummary.order_status) == 2 ? t("Paid all") : ""}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {openOrderAddress && (
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none box-open-modal">
            <div className="relative w-auto my-6 mx-auto md:w-4/6 ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-900 rounded-t ">
                  <div className="block">
                    <h3 className="text-black text-lg font-bold">{t("Deliver address")}</h3>
                  </div>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setOpenOrderAddress(false)}>
                    <span className="text-black opacity-7 h-6 w-6 text-xl block  rounded-full">
                      x
                    </span>
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <form className="rounded w-full">
                    <div className="flex px-3 mb-3">
                      <div className="w-full md:w-5/6">
                        <label className="block text-black text-lg font-bold mb-1">
                          {t("Order only")} {orderCode}
                        </label>
                      </div>
                      <div className="w-full md:w-1/6">
                        <label className="flex text-gray-500 font-bold">
                          <input
                            className="mr-2 leading-tight h-5 w-5 pt-2"
                            name="use_customer_address"
                            onChange={handleCheckboxChange}
                            checked={formData.use_customer_address || false}
                            value={formData.use_customer_address || ""}
                            type="checkbox"
                          />
                          <span className="text-sm font-normal limit-text-1">
                            {t("Use default address")}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-wrap  mb-3 items-center">
                      <div className="w-full md:w-1/6 mb-4 md:mb-0">
                        <label
                          className="block mb-2 text-lg tracking-wide px-3 text-gray-700 font-medium"
                          htmlFor="country">
                          {t("Street Addr.")}
                        </label>
                      </div>
                      <div className="w-full md:w-2/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="street_address"
                          value={formData.street_address || ""}
                          onChange={handleChangeAddress}
                          type="text"
                          placeholder="125/79 Ladprao rd."
                        />
                        {errorStreet && <p className="text-red text-sm">{errorStreet}</p>}
                      </div>
                      <div className="w-full md:w-1/6 mb-4 md:mb-0 px-3">
                        <label
                          className="block mb-2 text-lg tracking-wide text-gray-700 font-medium"
                          htmlFor="country">
                          {t("City")}
                        </label>
                      </div>
                      <div className="w-full md:w-2/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="city"
                          value={formData.city || ""}
                          onChange={handleChangeAddress}
                          type="text"
                          placeholder="Chatuchak"
                        />
                        {errorCity && <p className="text-red text-sm">{errorCity}</p>}
                      </div>
                    </div>
                    <div className="flex flex-wrap  mb-3 items-center">
                      <div className="w-full md:w-1/6 mb-4 md:mb-0">
                        <label
                          className="block mb-2 text-lg tracking-wide px-3 text-gray-700 font-medium"
                          htmlFor="country">
                          {t("State")}
                        </label>
                      </div>
                      <div className="w-full md:w-2/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="state"
                          value={formData.state || ""}
                          onChange={handleChangeAddress}
                          type="text"
                          placeholder="Bangkok"
                        />
                      </div>
                      <div className="w-full md:w-1/6 mb-4 md:mb-0 px-3">
                        <label
                          className="block mb-2 text-lg tracking-wide text-gray-700 font-medium"
                          htmlFor="country">
                          {t("Postal code")}
                        </label>
                      </div>
                      <div className="w-full md:w-2/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="zip_code"
                          value={formData.zip_code || ""}
                          onChange={handleChangeAddress}
                          type="text"
                          placeholder="34571"
                        />
                        {errorZipCode && <p className="text-red text-sm">{errorZipCode}</p>}
                      </div>
                    </div>
                    <div className="flex flex-wrap  mb-3 items-center">
                      <div className="w-full md:w-1/6 mb-4 md:mb-0">
                        <label
                          className="block mb-2 text-lg tracking-wide px-3 text-gray-700 font-medium"
                          htmlFor="country">
                          {t("Country")}
                        </label>
                      </div>
                      <div className="w-full md:w-2/6 px-3">
                        <Combobox
                          value={formData}
                          onChange={(v) => {
                            setCountry(v);
                            setValue("country", country.name);
                            setFormData({
                              ...formData,
                              country_name: v.name,
                              country_code: v.code
                            });
                          }}>
                          <div className="relative mt-1">
                            <div className="relative w-full cursor-default overflow-hidden rounded bg-white border-2 bg-opacity-40 text-left  ">
                              <Combobox.Input
                                className="style-country w-full border-none py-[10px] pl-3 pr-10 text-sm leading-5 text-gray-900 bg-transparent focus:ring-0"
                                displayValue={(person) => person.country_name}
                                onChange={(event) => setQuery(event.target.value)}
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg
                                  className="w-4 h-4 ml-1"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                                </svg>
                                {/* <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                              </Combobox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}>
                              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredCountry.length === 0 && query !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredCountry.map((country, index) => (
                                    <Combobox.Option
                                      key={index}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                          active ? "bg-teal-600 text-white" : "text-gray-900"
                                        }`
                                      }
                                      value={country}>
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`block truncate ${
                                              selected ? "font-medium" : "font-normal"
                                            }`}>
                                            {country.name}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                active ? "text-white" : "text-teal-600"
                                              }`}>
                                              {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-center p-6  rounded-b">
                  <button
                    className="text-white bg-[#EC2028] btn-profile active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={handleClickSaveAddress}>
                    {isLoadingDelivery ? <span className="loader" /> : t("Save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {openDeposit && (
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none box-open-modal">
            <div className="relative w-auto my-6 mx-auto md:w-2/6 ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-900 rounded-t ">
                  <div className="block">
                    <h3 className="text-black text-lg font-bold">{t("Credit Card")}</h3>
                  </div>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setOpenDeposit(false)}>
                    <span className="text-black opacity-7 h-6 w-6 text-xl block  rounded-full">
                      x
                    </span>
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <form className="rounded w-full">
                    <div className="flex px-3 mb-3">
                      <div className="w-full md:w-5/6">
                        <label className="block text-black text-lg font-bold mb-1">
                          {t("Pay for order")} : {orderCode}
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-wrap  mb-3 items-center">
                      <div className="w-full md:w-2/6 mb-4 md:mb-0">
                        <label
                          className="block mb-2 text-lg tracking-wide px-3 text-gray-700 font-medium"
                          htmlFor="country">
                          {t("Card Number")}
                        </label>
                      </div>
                      <div className="w-full md:w-4/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="card_number"
                          value={formDataPay.card_number || ""}
                          onChange={handleChangeCard}
                          type="text"
                          placeholder=""
                        />
                        {errorCardNumber && <p className="text-red text-sm">{errorCardNumber}</p>}
                      </div>
                    </div>
                    <div className="flex flex-wrap  mb-3 items-center">
                      <div className="w-full md:w-2/6 mb-4 md:mb-0">
                        <label
                          className="block mb-2 text-lg tracking-wide px-3 text-gray-700 font-medium"
                          htmlFor="country">
                          {t("Name on card")}
                        </label>
                      </div>
                      <div className="w-full md:w-4/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="card_name"
                          value={formDataPay.card_name || ""}
                          onChange={handleChangeCard}
                          type="text"
                          placeholder=""
                        />
                        {errorCardName && <p className="text-red text-sm">{errorCardName}</p>}
                      </div>
                    </div>
                    <div className="flex flex-wrap  mb-3 items-center">
                      <div className="w-full md:w-2/6 mb-4 md:mb-0">
                        <label
                          className="block mb-2 text-lg tracking-wide px-3 text-gray-700 font-medium"
                          htmlFor="country">
                          {t("Expiration")}
                        </label>
                      </div>
                      <div className="w-full md:w-2/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="card_exp_month"
                          value={formDataPay.card_exp_month || ""}
                          onChange={handleChangeCard}
                          type="text"
                          maxLength={2}
                          placeholder="Month"
                        />
                        {errorExpMonth && <p className="text-red text-sm">{errorExpMonth}</p>}
                      </div>
                      <div className="w-full md:w-2/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="card_exp_year"
                          value={formDataPay.card_exp_year || ""}
                          onChange={handleChangeCard}
                          type="text"
                          maxLength={2}
                          placeholder="Year"
                        />
                        {errorExpYear && <p className="text-red text-sm">{errorExpYear}</p>}
                      </div>
                    </div>
                    <div className="flex flex-wrap  mb-3 items-center">
                      <div className="w-full md:w-2/6 mb-4 md:mb-0">
                        <label
                          className="block mb-2 text-lg tracking-wide px-3 text-gray-700 font-medium"
                          htmlFor="country">
                          {t("CVC")}
                        </label>
                      </div>
                      <div className="w-full md:w-4/6 px-3">
                        <input
                          className="appearance-none block w-full mb-2 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          name="card_cvc"
                          value={formDataPay.card_cvc || ""}
                          onChange={handleChangeCard}
                          type="text"
                          placeholder=""
                        />
                        {errorCvc && <p className="text-red text-sm">{errorCvc}</p>}
                      </div>
                    </div>
                    <div className="flex flex-wrap  mb-3 items-center">
                      <div className="w-full md:w-2/6 mb-4 md:mb-0">
                        <label
                          className="block mb-2 text-lg tracking-wide px-3 text-gray-700 font-medium"
                          htmlFor="country">
                          {t("Amount")}
                        </label>
                      </div>
                      <div className="w-full md:w-4/6 px-3">{amountShowFormPay || 0}</div>
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-center p-6  rounded-b">
                  <button
                    className="btn-profile text-white bg-[#EC2028] active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={handleClickSavePayment}>
                    {isLoadingPay ? <span className="loader" /> : t("Apply")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Reservation;
