/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import logo from "./assets/images/logo.png";
import { Breakpoint } from "../../theme/config.them";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../../components/Menu";
import "./index.css";
import picVerify from "./assets/images/mail.png";
import { t } from "i18next";
const CheckEmail = () => {
  return (
    // /Users/ploy/งาน/zapp_frontend/src/view/Profile/components/Menu.jsx
    <div className="h-fit bg-login ">
      <Menu></Menu>
      <div className="px-4 md:px-14 grid grid-cols-1 md:grid-cols-1 justify-items-center  gap-6 content-center py-10">
        <div className="w-full max-w-md">
          <form className=" shadow-md rounded px-8 md:px-10 py-10 box-login">
            <div className="flex justify-center mb-6">
              <img src={logo} alt="Logo" className="h-20" />
            </div>
            <div className="flex justify-center mb-6">
              <img src={picVerify} alt="Logo" />
            </div>
            <h1 className="text-4xl font-normal text-center text-white pb-8 text-xl">
              {t("Check your mail")}
            </h1>
            <div className="mb-40 text-white">{t("Check your mail Detail")}</div>
            <div>
              <a
                href="/"
                className="w-full flex justify-center items-center btn-login text-white  py-2 px-4 focus:outline-none focus:shadow-outline">
                {t("OK")}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckEmail;
