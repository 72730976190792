// import ToolBar from "../../components/Toolbar";

import Menu from "../../../../components/Menu";

const RootTemplate = ({ className, children, style }) => {
  return (
    <div style={style} className="h-screen overflow-hidden">
      <div className="fixed top-0 left-0 w-full z-20">
        {/* <ToolBar /> */}
        <Menu />
      </div>
      <div className="h-full flex flex-col overflow-hidden">
        <div className="h-[48px] md:h-[64px]" />
        <div className={`h-rootMobileBody md:h-rootBody overflow-hidden ${className}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default RootTemplate;
