/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState, Fragment } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "../index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Listbox, Transition, Combobox } from "@headlessui/react";
import { StatusData } from "../../../../src/models/module";
import { fetchCountryListAsset } from "../../../../src/modules/assets/thunk";
import { t } from "i18next";
function ProfileAddress(props) {
  const MySwal = withReactContent(Swal);
  const [formData, setFormData] = useState([]);
  const [errorCity, setErrorCity] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [errorStreet, setErrorStreet] = useState(null);
  const [errorCountry, setErrorCountry] = useState(null);
  const [country, setCountry] = useState([]);
  const [countryName, setCountryName] = useState(null);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const {
    setValue
    // getValues
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // if (countriesStatus === StatusData.succeeded) return;
    dispatch(fetchCountryListAsset());

    const getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/account/info";
    const token = window.sessionStorage.getItem("authenticationToken");

    axios
      .get(getValURL, {
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      })
      .then(function (response) {
        if (response.status === 200) {
          const d = response.data.customer_address;
          setFormData(d);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });

    // eslint-disable-next-line
    }, []);

  const { status: countriesStatus, data: countiesData } = useSelector(
    (state) => state.assets.countries
  );

  const filteredCountry = useMemo(() => {
    if (!countiesData) return [];
    return query === ""
      ? countiesData
      : countiesData.filter((country) =>
          country.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  }, [countiesData, query]);

  const actionEdit = props.status;
  const tab = props.tab === "address" ? true : false;
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    if (event.target.name === "city") {
      if (event.target.value === null) {
        setErrorCity(t("This field is required."));
      } else {
        setErrorCity(null);
      }
    }
    if (event.target.name === "zip_code") {
      if (event.target.value === null) {
        setErrorCode(t("This field is required."));
      } else {
        setErrorCode(null);
      }
    }
    if (event.target.name === "country_code") {
      if (event.target.value === null) {
        setErrorCountry(t("This field is required."));
      } else {
        setErrorCountry(null);
        setCountryName(event.target.options[event.target.selectedIndex].getAttribute("data-name"));
      }
    }
    if (event.target.name === "country_name") {
      if (event.target.value === null) {
        setErrorCountry(t("This field is required."));
      } else {
        setErrorCountry(null);
      }
    }
    if (event.target.name === "street_address") {
      if (event.target.value === null) {
        setErrorStreet(t("This field is required."));
      } else {
        setErrorStreet(null);
      }
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (!formData.city) {
      setErrorCity(t("This field is required."));
    }
    if (!formData.zip_code) {
      setErrorCode(t("This field is required."));
    }
    if (!formData.country_name) {
      setErrorCountry(t("This field is required."));
    }
    if (!formData.street_address) {
      setErrorStreet(t("This field is required."));
    }

    if (formData.city && formData.zip_code && formData.country_name && formData.street_address) {
      setIsLoading(true);
      const getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/account/customerAddress";
      const token = window.sessionStorage.getItem("authenticationToken");
      const request = {
        url: getValURL,
        method: "patch",
        data: formData,
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          setFormData(response.data.customer_address);
          MySwal.fire({
            title: "saved successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            setIsLoading(false);
            window.location.reload();
          });
        })
        .catch(function (error) {
          MySwal.fire({
            title: error,
            icon: "error",
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            setIsLoading(false);
          });
        });
    }
  };

  return (
    <div className="xl:container mx-auto  px-1 md:px-5 py-5 pt-8">
      {actionEdit == true && (
        <form onSubmit={handleClick}>
          <div className="form-container flex flex-wrap justify-center">
            <div className="lg:w-full md:w-full">
              <div>
                <label className="block tracking-wide text-gray-700 text-lg font-normal mb-10">
                  {t("This address will be")}
                </label>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="country">
                    {t("Street Addr.")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <input
                    className="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    name="street_address"
                    type="text"
                    placeholder=""
                    value={formData.street_address || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full md:w-2/6 px-3">
                  {errorStreet && <p className="text-red text-sm">{errorStreet}</p>}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="city">
                    {t("City")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <input
                    className="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    name="city"
                    type="text"
                    placeholder=""
                    value={formData.city || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full md:w-2/6 px-3">
                  {errorCity && <p className="text-red text-sm">{errorCity}</p>}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="state">
                    {t("State")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <input
                    className="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    name="state"
                    type="text"
                    placeholder=""
                    value={formData.state || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="code">
                    {t("Postal code")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <input
                    className="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    name="zip_code"
                    type="text"
                    placeholder=""
                    value={formData.zip_code || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full md:w-2/6 px-3">
                  {errorCode && <p className="text-red text-sm">{errorCode}</p>}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="country">
                    {t("Country")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <Combobox
                    value={formData}
                    onChange={(v) => {
                      setCountry(v);
                      setValue("country", country.name);
                      setFormData({ ...formData, country_name: v.name, country_code: v.code });
                    }}>
                    <div className="relative mt-1">
                      <div className="relative w-full cursor-default overflow-hidden rounded bg-white border-2 bg-opacity-40 text-left  ">
                        <Combobox.Input
                          className="style-country w-full border-none py-[10px] pl-3 pr-10 text-sm leading-5 text-gray-900 bg-transparent focus:ring-0"
                          displayValue={(person) => person.country_name}
                          onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <svg
                            className="w-4 h-4 ml-1"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"></path>
                          </svg>
                          {/* <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery("")}>
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredCountry.length === 0 && query !== "" ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              Nothing found.
                            </div>
                          ) : (
                            filteredCountry.map((country, index) => (
                              <Combobox.Option
                                key={index}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active ? "bg-teal-600 text-white" : "text-gray-900"
                                  }`
                                }
                                value={country}>
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}>
                                      {country.name}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active ? "text-white" : "text-teal-600"
                                        }`}>
                                        {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
                <div className="w-full md:w-2/6 px-3">
                  {errorCountry && <p className="text-red text-sm">{errorCountry}</p>}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-3 py-3">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0"></div>
                <div className="w-full md:w-3/6 px-3">
                  <button
                    type="submit"
                    className="btn-profile rounded-md bg-main text-white font-regular py-2 px-8">
                    {isLoading ? <span className="loader" /> : t("Save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {actionEdit == false && (
        <form>
          <div className="form-container flex flex-wrap justify-center">
            <div className="lg:w-full md:w-full">
              <div>
                <label className="block tracking-wide text-gray-700 text-lg font-normal mb-10">
                  {t("This address will be")}
                </label>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="country">
                    {t("Street Addr.")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <p className=" text-regular">{formData.street_address || "-"}</p>
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="city">
                    {t("City")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <p className=" text-regular">{formData.city || "-"}</p>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="state">
                    {t("State")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <p className=" text-regular">{formData.state || "-"}</p>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="code">
                    {t("Postal code")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <p className=" text-regular">{formData.zip_code || "-"}</p>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3 items-center">
                <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                  <label
                    className="block text-lg tracking-wide text-gray-700 font-bold"
                    htmlFor="country">
                    {t("Country")}
                  </label>
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <p className=" text-regular">{formData.country_name || "-"}</p>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default ProfileAddress;
