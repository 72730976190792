import { t } from "i18next";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../components/FormInput";
import { Listbox, Transition, Combobox } from "@headlessui/react";
import { fetchCountryListAsset } from "../../../../modules/assets/thunk";
import { StatusData } from "../../../../models/module";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { validateCardNumber } from "../../../../utils/creditCard.util";
import ErrorInputText from "../../../shared/components/ErrorInputText";

const PaymentTemplate = ({ onSubmitPayment, isSignedIn, userInfo }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  const dispatch = useDispatch();

  const [dialCode, setDialCode] = useState(null);
  const [country, setCountry] = useState({});
  const [expireDate, setExpireDate] = useState(null);
  const { status: countriesStatus, data: countiesData } = useSelector(
    (state) => state.assets.countries
  );

  const [query, setQuery] = useState("");

  const onSubmit = (data) => {
    onSubmitPayment({ ...data, countryInfo: country, expireDate });
  };

  useEffect(() => {
    if (countriesStatus === StatusData.succeeded) return;
    dispatch(fetchCountryListAsset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userInfo || !countiesData) return;

    setValue("first_name", userInfo.first_name);
    setValue("last_name", userInfo.last_name);
    setValue("city", userInfo.customer_address.city);
    setValue("country", userInfo.customer_address.country_name);
    setValue("postalCode", userInfo.customer_address.zip_code);
    setValue("state", userInfo.customer_address.state);
    setValue("streetAddress", userInfo.customer_address.street_address);
    setValue("phoneNumber", userInfo.phone);
    const selectCountry = countiesData.find(
      (country) => country.name === userInfo.customer_address.country_name
    );

    setCountry(selectCountry);
    const selectDialCode = countiesData.find((country) => country.dial_code === userInfo.dial_code);
    setDialCode(selectDialCode);
    setValue("dialCode", userInfo.dial_code);
    // setValue('country')
    // setValue('country', )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, countiesData]);

  const filteredCountry = useMemo(() => {
    if (!countiesData) return [];
    return query === ""
      ? countiesData
      : countiesData.filter((country) =>
          country.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  }, [countiesData, query]);

  return (
    <form
      className="w-full flex flex-col space-y-5 px-5 py-5 lg:mx-auto lg:container lg:max-w-3xl xl:max-w-none"
      onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-stretch space-y-3">
        <p className="text-xl pb-1 font-bold">{t("Payment Details")}</p>
        <div className="border mb-2"></div>
        <FormInput
          title={t("First name")}
          attributeProp={{ id: "first_name" }}
          inputProps={register("first_name", {
            required: { value: true, message: t("First name is required") }
          })}
          error={errors?.first_name?.message}
        />
        <FormInput
          title={t("Last name")}
          attributeProp={{ id: "last_name" }}
          inputProps={register("last_name", {
            required: { value: true, message: t("Last name is required") }
          })}
          error={errors?.last_name?.message}
        />
        {!isSignedIn ? (
          <FormInput
            title={t("Email Address")}
            type="email"
            inputProps={register("email", {
              required: { value: true, message: t("Email address is required") },
              pattern: {
                value: new RegExp(
                  // eslint-disable-next-line no-control-regex
                  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
                ),
                message: t("Email is invalid.")
              }
            })}
            error={errors?.email?.message}
          />
        ) : (
          <></>
        )}
        <div className="flex flex-col space-y-2">
          <p className="font-bold  text-xs md:text-base">{t("Phone Number")}</p>
          <input
            className="hidden"
            {...register("dialCode", {
              // required: { value: true, message: t("Dial Code is required.") }
            })}
          />
          <div className="flex items-start space-x-2">
            <div className="w-32 flex flex-col space-y-1">
              <Listbox
                value={dialCode}
                onChange={(v) => {
                  setDialCode(v);
                  setValue("dialCode", v.dial_code, { shouldValidate: true });
                }}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded bg-white bg-opacity-40 h-[43px] px-3 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <div className=" flex justify-between space-x-1">
                      <p className="block truncate">{dialCode?.code}</p>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                      <p className="">{dialCode?.dial_code}</p>
                    </div>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    {countiesData ? (
                      <Listbox.Options className="absolute mt-1 max-h-60 overflow-auto rounded divide-y bg-white py-1 text-base z-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {countiesData?.map((country, idx) => (
                          <Listbox.Option
                            key={idx}
                            role="button"
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 px-2 flex ${
                                active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                              }`
                            }
                            value={country}>
                            {({ selected }) => (
                              <>
                                <div className="flex-1">
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}>
                                    {country.name}
                                  </span>
                                </div>
                                <span>{country.dial_code}</span>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    ) : (
                      <></>
                    )}
                  </Transition>
                </div>
              </Listbox>
              <p style={{ textShadow: "1px 1px 5px white" }} className="  text-[#7C0004] font-bold">
                {errors?.dialCode?.message || ""}
              </p>
            </div>
            <div className="flex-1 pt-1">
              <FormInput
                type="number"
                inputProps={register("phoneNumber", {
                  type: "text"
                  // required: {
                  //   value: true,
                  //   message: t("Phone No. is required.")
                  // }
                })}
                error={errors?.phoneNumber?.message}
              />
            </div>
          </div>
        </div>

        {/* ====================== */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
          <div className="col-span-2">
            <FormInput
              title={t("Street Address")}
              inputProps={register("streetAddress", {
                required: { value: true, message: t("Street address is required") }
              })}
              error={errors?.streetAddress?.message}
            />
          </div>
          <div className="col-span-1">
            <FormInput
              title={t("State")}
              inputProps={register("state")}
              error={errors?.state?.message}
            />
          </div>
          <div className="col-span-1">
            <FormInput
              title={t("City")}
              inputProps={register("city", {
                required: { value: true, message: t("City is required") }
              })}
              error={errors?.city?.message}
            />
          </div>
          <div className="col-span-1">
            <div className="flex flex-col space-y-2">
              <p className="font-bold text-xs md:text-base">{t("Country")}</p>
              <input
                className="hidden"
                {...register("country", {
                  required: true
                })}
              />
              <Combobox
                value={country}
                onChange={(v) => {
                  setCountry(v);
                  setValue("country", v?.name, { shouldValidate: true });
                }}>
                <div className="relative mt-1">
                  <div className="relative w-full cursor-default overflow-hidden rounded bg-white border-2 bg-opacity-40 text-left  ">
                    <Combobox.Input
                      className="w-full border-none py-[10px] pl-3 pr-10 text-sm leading-5  bg-transparent focus:ring-0"
                      displayValue={(person) => person.name}
                      onChange={(event) => {
                        setQuery(event.target.value);
                        setValue("country", event.target.value, { shouldValidate: true });
                      }}
                    />
                    <Combobox.Button
                      role="button"
                      className="absolute inset-y-0 right-0 flex items-center pr-2">
                      {/* <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery("")}>
                    <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredCountry.length === 0 && query !== "" ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          {t("Nothing found")}
                        </div>
                      ) : (
                        filteredCountry.map((country, index) => (
                          <Combobox.Option
                            key={index}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active ? "bg-teal-600 text-white" : "text-gray-900"
                              }`
                            }
                            value={country}>
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}>
                                  {country.name}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? "text-white" : "text-teal-600"
                                    }`}>
                                    {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
              <ErrorInputText text={errors.country ? t("Country is required") : ""} />
            </div>
          </div>
          <div className="col-span-1">
            <FormInput
              title={t("Postal code")}
              inputProps={register("postalCode", {
                required: { value: true, message: t("Postal code is required") }
              })}
              error={errors?.postalCode?.message}
            />
          </div>
        </div>

        {/* ====================== */}
      </div>

      <div className="flex flex-col">
        <p className="text-xl pb-1 font-bold">{t("Credit Card")}</p>
        <div className="border mb-2"></div>
        <div className="flex flex-col space-y-2">
          <FormInput
            title={t("Name")}
            inputProps={register("cardName", {
              required: { value: true, message: t("Card name is required") }
            })}
            error={errors.cardName?.message}
          />
          <FormInput
            title={t("Card Number")}
            type="number"
            inputProps={register("cardNumber", {
              required: { value: true, message: t("Card number is required") },
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: t("Card Number must be number")
              },
              validate: validateCardNumber
            })}
            error={
              errors?.cardNumber?.message ||
              (errors?.cardNumber?.type === "validate" && t("Card number is invalid"))
            }
          />

          <div className="flex items-start space-x-5">
            <div className="flex flex-col space-y-2">
              <p className=" font-bold  text-xs md:text-base">{t("Expiration")}</p>
              <input
                className="hidden"
                {...register("expiration", {
                  required: { value: true, message: t("Expired date is required") }
                })}></input>
              <DatePicker
                showMonthYearPicker
                className="px-3 py-2 border-2 rounded bg-white bg-opacity-40 w-full"
                selected={expireDate}
                onChange={(date) => {
                  setExpireDate(date);
                  setValue("expiration", date, { shouldValidate: true });
                }}
                dateFormat="MM / yy"
              />
              <ErrorInputText text={errors?.expiration?.message || ""} />
            </div>
            <div className="flex-1">
              <FormInput
                title="CVC"
                type="number"
                inputProps={register("cvc", {
                  required: { value: true, message: t("CVC is required") },
                  pattern: /^\d*$/
                })}
                error={errors?.cvc?.message}
              />
            </div>
          </div>
        </div>
      </div>

      <button className=" h-12 rounded-md bg-white bg-opacity-40 text-black mt-10">
        {t("Reserve")}
      </button>
    </form>
  );
};

export default memo(PaymentTemplate);
