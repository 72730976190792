export const srgbToLinear = (c) => {
  const gamma = 2.4;
  return c < 0.04045 && c >= 0.0 ? c * (1.0 / 12.92) : Math.pow((c + 0.055) * (1.0 / 1.055), gamma);
};

export const convertHexToRGB = (hex) => {
  try {
    const t = hex.match(/^#([0-9a-f]{6})$/i);
    if (t) {
      return [
        srgbToLinear(parseInt(t[1].substr(0, 2), 16) / 255),
        srgbToLinear(parseInt(t[1].substr(2, 2), 16) / 255),
        srgbToLinear(parseInt(t[1].substr(4, 2), 16) / 255)
      ];
    }
  } catch (e) {
    throw new Error("Invalid color: " + e);
  }
};

export const covertHexToGRBNormal = (hex) => {
  try {
    const t = hex.match(/^#([0-9a-f]{6})$/i);
    if (t) {
      return [
        parseInt(t[1].substr(0, 2), 16) / 255,
        parseInt(t[1].substr(2, 2), 16) / 255,
        parseInt(t[1].substr(4, 2), 16) / 255
      ];
    }
  } catch (e) {
    throw new Error("Invalid color: " + e);
  }
};
