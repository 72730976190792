import { createAsyncThunk } from "@reduxjs/toolkit";
import SharedCustomization from "../../services/sharedCustomization.service";
import { ShardCustomizationModule } from "../share";

const sharedCustomization = new SharedCustomization();

export const fetchSharedCustomization = createAsyncThunk(
  `${ShardCustomizationModule}/fetchSharedCustomization`,
  async (shareId, thunkAPI) => {
    try {
      const result = await sharedCustomization.getSharedCustomization(shareId);
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);
