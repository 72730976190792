import { forwardRef } from "react";
import FullScreenIcon from "../../../shared/Icons/FullScreenIcon";
import ShareIcon from "../../../shared/Icons/ShareIcon";
import ZoomInIcon from "../../../shared/Icons/ZoomInIcon";
import ZoomOutIcon from "../../../shared/Icons/ZoomOutIcon";

const ThreeDScreen = forwardRef(function ThreeDScreen(props, ref) {
  const {
    isFullScreen,
    setIsFullScreen,
    onSharedModel,
    zoomIn,
    zoomOut,
    menuButtonTheme,
    isReadyView
  } = props;
  return (
    <div className="w-full h-full relative shadow-none">
      <iframe
        className={`w-full h-full ${
          isFullScreen ? "fixed top-0 left-0 z-10" : "relative z-0"
        } transform duration-300 shadow-none`}
        ref={ref}
        id="api-frame"
        allow="autoplay; fullscreen; xr-spatial-tracking"
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
        allowFullScreen
        width="100%"
        height="100%"
        title="car-model"></iframe>
      <div className={`absolute top-20 right-5 ${isFullScreen ? "block z-10" : "hidden"}`}>
        <button onClick={() => setIsFullScreen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-7 h-7">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
      {isReadyView ? (
        <div className={`absolute top-0 right-0 inset-y-3 pr-5 ${isFullScreen ? "hidden" : ""}`}>
          <div className="h-full flex flex-col items-center justify-center gap-5">
            <button
              style={{
                background: menuButtonTheme.backgroundColor,
                borderColor: menuButtonTheme.border,
                color: menuButtonTheme.foregroundColor
              }}
              onClick={() => setIsFullScreen(true)}
              className="w-7 h-7 md:w-10 md:h-10 rounded border-2  flex items-center justify-center">
              <FullScreenIcon className="w-4 h-4 md:w-6 md:h-6" />
            </button>
            <button
              style={{
                background: menuButtonTheme.backgroundColor,
                borderColor: menuButtonTheme.border,
                color: menuButtonTheme.foregroundColor
              }}
              onClick={() => zoomIn()}
              className="w-7 h-7 md:w-10 md:h-10 rounded border-2  flex items-center justify-center">
              <ZoomInIcon className="w-4 h-4 md:w-6 md:h-6" />
            </button>
            <button
              style={{
                background: menuButtonTheme.backgroundColor,
                borderColor: menuButtonTheme.border,
                color: menuButtonTheme.foregroundColor
              }}
              onClick={() => zoomOut()}
              className="w-7 h-7 md:w-10 md:h-10 rounded border-2 flex items-center justify-center">
              <ZoomOutIcon className="w-4 h-4 md:w-6 md:h-6" />
            </button>
            <button
              style={{
                background: menuButtonTheme.backgroundColor,
                borderColor: menuButtonTheme.border,
                color: menuButtonTheme.foregroundColor
              }}
              onClick={onSharedModel}
              className="w-7 h-7 md:w-10 md:h-10 rounded flex items-center border-2 justify-center">
              <ShareIcon className="w-4 h-4 md:w-6 md:h-6" />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
});

export default ThreeDScreen;
