import { createSlice } from "@reduxjs/toolkit";
import { StatusData } from "../../models/module";
import { AssetModule } from "../share";
import { fetchAllPreFullPricesReserve, fetchCountryListAsset } from "./thunk";

const initialNodeData = {
  status: StatusData.idle,
  data: null,
  error: null
};

const initialState = {
  countries: initialNodeData,
  allPreFullPricesReserve: initialNodeData
};

const assetSlice = createSlice({
  name: AssetModule,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountryListAsset.pending, (state) => {
      state.countries.status = StatusData.pending;
    });
    builder.addCase(fetchCountryListAsset.fulfilled, (state, action) => {
      state.countries = {
        status: StatusData.succeeded,
        data: action.payload
      };
    });
    builder.addCase(fetchCountryListAsset.rejected, (state, action) => {
      state.countries.status = StatusData.failed;
      state.countries.error = action.payload;
    });

    builder.addCase(fetchAllPreFullPricesReserve.pending, (state) => {
      state.allPreFullPricesReserve.status = StatusData.pending;
    });
    builder.addCase(fetchAllPreFullPricesReserve.fulfilled, (state, action) => {
      state.allPreFullPricesReserve = {
        status: StatusData.succeeded,
        data: action.payload
      };
    });
    builder.addCase(fetchAllPreFullPricesReserve.rejected, (state, action) => {
      state.allPreFullPricesReserve.status = StatusData.failed;
      state.allPreFullPricesReserve.error = action.payload;
    });
  }
});

export default assetSlice.reducer;
