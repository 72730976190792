export const CurrencyPrefixType = {
  pound: "£",
  euro: "€",
  usd: "$"
};

export const Currency = {
  pound: {
    dbKey: "pound",
    value: "GBP",
    code: "gbp",
    name: "Pound",
    prefix: CurrencyPrefixType.pound
  },
  euro: { dbKey: "euro", value: "EUR", code: "eur", name: "EUR", prefix: CurrencyPrefixType.euro },
  usd: { dbKey: "usd", value: "USD", code: "usd", name: "USD", prefix: CurrencyPrefixType.usd }
};
