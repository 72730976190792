import { createAsyncThunk } from "@reduxjs/toolkit";
import CustomizationTemporary from "../../services/customizationTemporary.service";
import { TempCustomizationModule } from "../share";

const customizationTemporary = new CustomizationTemporary();

export const createAndFetchTempCustomization = createAsyncThunk(
  `${TempCustomizationModule}/createAndFetchTempCustomization`,
  async (modelId, thunkAPI) => {
    try {
      const custom = await customizationTemporary.getTempCustomization({ modelId });
      if (custom) return custom;

      const createdCustom = await customizationTemporary.createTempCustomization({
        modelId,
        properties: []
      });
      return createdCustom;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);
