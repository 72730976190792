import { createAsyncThunk } from "@reduxjs/toolkit";
import CustomizationPartService from "../../services/customization.service";
import { CustomizationPartsNameModule } from "../share";

const customizationPartService = new CustomizationPartService();

export const fetchCustomizationByModelId = createAsyncThunk(
  `${CustomizationPartsNameModule}/fetchCustomizationByModelId`,
  async (modelId, thunkAPI) => {
    try {
      const result = await customizationPartService.getCustomizationPartsByModelId(modelId);
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const fetchCustomizationCategoriesByModelId = createAsyncThunk(
  `${CustomizationPartsNameModule}/fetchCustomizationCategoriesByModelId`,
  async (modelId, thunkAPI) => {
    try {
      const result = await customizationPartService.getCustomizationCategoriesByModelId(modelId);
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);
