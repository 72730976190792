export const PerformType = {
  active: "active",
  inactive: "inactive"
};

export const ActionType = {
  selector: "selector",
  toggle: "toggle",
  multipleSelector: "multipleSelector"
};

export const PropertyType = {
  Color: "Color",
  Texture: "Texture",
  Visibility: "Visibility",
  Glossiness: "Glossiness",
  Roughness: "Roughness",
  Metalness: "Metalness"
};

export const DisplayLayout = {
  RowSelectorLayout: "RowSelectorLayout",
  GridSelectorLayout: "GridSelectorLayout",
  GridSelectorLayoutSumCost: "GridSelectorLayoutSumCost",
  DynamicSelectorLayout: "DynamicSelectorLayout"
};
