import { createSlice } from "@reduxjs/toolkit";
import { AuthenticationModule } from "../share";

const initialState = {
  status: false,
  token: null,
  user: null
};

const authenticationSlice = createSlice({
  name: AuthenticationModule,
  initialState,
  reducers: {
    authenticate(state, action) {
      // state = {
      //   status: true,
      //   token: action.payload.token,
      //   user: action.payload.user
      // };
      // return state;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.status = true;
    },
    unAuthenticate(state) {
      state.status = false;
      state.token = null;
      state.user = null;
    }
  }
});

export default authenticationSlice.reducer;
export const { authenticate, unAuthenticate } = authenticationSlice.actions;
