import i18next, { t } from "i18next";
import { memo } from "react";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { useMemo } from "react";

const ReserveDefaultTemplate = ({
  productDetail,
  reservePrices = [],
  onPersonalize,
  onCheckOut
}) => {
  const currency = useSelector((state) => state.currency);

  const reservePrice = useMemo(() => {
    if (!reservePrices) return 0;
    const _reservePrice = reservePrices.find(
      (reservePrice) => reservePrice.currency_code === currency.code
    );
    return _reservePrice.price || 0;
  }, [currency.code, reservePrices]);

  return (
    <div className="h-auto md:h-full flex flex-col items-stretch md:py-10 lg:mx-auto lg:container lg:max-w-3xl xl:max-w-none">
      <div className="flex flex-col space-y-3 px-5">
        <p className="text-4xl border-b pb-5 font-bold">{productDetail?.name || ""}</p>

        {productDetail?.extendDemoPartInformation?.parts ? (
          <div className="flex flex-col space-y-5 py-5">
            <p className="font-semibold">{t("Includes :")}</p>
            <ul className="flex overflow-x-auto whitespace-nowrap  gap-5 pb-5">
              {productDetail?.extendDemoPartInformation?.parts.map((property, index) => (
                <li key={index.toString()} className="">
                  <div className="flex flex-col space-y-2 items-stretch w-[100px]">
                    <img src={property.image} className=" w-[100px] h-[100px] border" />
                    <p className=" text-xs whitespace-normal break-words text-center">
                      {property.name[i18next.language]}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="flex-1"></div>
      <button
        className="bg-button-color h-[67px] bg-opacity-40 text-xl my-3"
        onClick={() => onPersonalize()}>
        <p className="underline">{t("Personalise yours")}</p>
      </button>
      <div className="flex-none md:flex-1"></div>
      <div className="flex flex-col px-5 pb-5 space-y-3">
        <div className="flex justify-between items-center text-xl">
          <p className="">{t("Vehicle Price")}</p>
          <p className="inline-flex space-x-2 font-medium">
            <span>{currency.prefix}</span>
            {productDetail?.price ? (
              <NumericFormat
                value={productDetail?.price[currency.dbKey] || 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            ) : (
              <></>
            )}

            {/* <FormattedNumber value={productDetail?.price[currency.dbKey] || 0} style="decimal" /> */}
          </p>
        </div>
        <div className="flex justify-between items-center text-xl">
          <p>{t("Reserve Now")}</p>
          <p className="inline-flex space-x-2 font-medium">
            <span>{currency.prefix}</span>
            <NumericFormat value={reservePrice} displayType={"text"} thousandSeparator={true} />
            {/* <FormattedNumber value={100} style="decimal" /> */}
          </p>
        </div>
      </div>
      <button
        onClick={() => onCheckOut()}
        className=" self-center bg-button-color px-5 w-2/3 text-xl bg-opacity-40 h-[67px] flex  font-semibold justify-center items-center rounded mx-5 text-black">
        <p>{`${t("Reserve Now")} ${currency?.prefix} 100 `}</p>
      </button>
      <Link to="/" className="self-center text-white underline mt-5">
        {t("Back to select model")}
      </Link>
    </div>
  );
};

export default memo(ReserveDefaultTemplate);
