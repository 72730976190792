import http from "../core/http.core";

class SharedCustomization {
  constructor() {
    this.path = "customization-shared";
  }

  async createShareCustomization(data) {
    const result = await http.post(this.path, data);
    return result.data;
  }

  async getSharedCustomization(id) {
    const { data } = await http.get(`${this.path}/${id}`);
    return data;
  }
}

export default SharedCustomization;
