import { createSlice } from "@reduxjs/toolkit";
import { SummaryModule } from "../share";

const initialState = {
  customization: [],
  payment: {},
  customer: {}
};

const summarySlice = createSlice({
  name: SummaryModule,
  initialState,
  reducers: {
    saveCustomization(state, action) {
      state.customization = action.payload;
    },
    savePayment(state, action) {
      state.payment = action.payload;
    }
  }
});

export default summarySlice.reducer;
export const { saveCustomization, savePayment } = summarySlice.actions;
