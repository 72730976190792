import { createSlice } from "@reduxjs/toolkit";
import { StatusData } from "../../models/module";
import { ShardCustomizationModule } from "../share";
import { fetchSharedCustomization } from "./thunk";
const initialState = {
  status: StatusData.idle,
  data: null,
  error: null
};

const sharedCustomizationSlice = createSlice({
  name: ShardCustomizationModule,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSharedCustomization.pending, (state) => {
      state.status = StatusData.pending;
    });
    builder.addCase(fetchSharedCustomization.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = StatusData.succeeded;
    });
    builder.addCase(fetchSharedCustomization.rejected, (state, action) => {
      state.error = action.payload;
      state.status = StatusData.failed;
    });
  }
});

export default sharedCustomizationSlice.reducer;
