/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { FormType } from "../../../../models/formType";
import { ActionType, DisplayLayout } from "../../../../models/property";
import CardProperty from "../CardProperty";
import { NumericFormat } from "react-number-format";
import i18next, { t } from "i18next";
import TextNameSection from "../../../shared/components/TextNameSection";
const GridSelectorLayout = ({
  title,
  options = [],
  values = [],
  currency,
  onSelectProperty,
  layout,
  formType,
  formGroups,
  languageForms = [],
  actionType
}) => {
  const isActive = (_id) => values.some((e) => e._id === _id);

  function handleOnChange(v, _actionType) {
    const hasProperty = values.some((ele) => ele._id === v._id);
    if (_actionType === ActionType.selector && hasProperty) {
      return;
    }

    if (!hasProperty) {
      onSelectProperty(v, "add", _actionType);
    } else {
      onSelectProperty(v, hasProperty ? "remove" : "add", _actionType);
    }
  }

  const totalPrice = useMemo(() => {
    return values.reduce((acc, cur) => acc + cur.price[currency.dbKey], 0) || 0;
  }, [currency.dbKey, values]);

  const groupName = (group) => {
    if (!languageForms) return "";

    const lang = languageForms.find((form) => form.docId === group.translationId);
    if (!lang) return "";
    return lang?.translation[i18next.language] || "";
  };

  return (
    <div className="flex flex-col border-b py-4 px-3">
      <div className="flex justify-between items-center pb-2">
        <p className="font-bold text-base md:text-2xl">{title}</p>
      </div>

      <div
        className={`flex flex-col transform transition-all duration-300 ease-out overflow-hidden`}>
        <div className="flex justify-between item-center pb-3">
          {layout === DisplayLayout.GridSelectorLayout ? (
            <>
              <p className="inline-flex space-x-2 text-[14px] md:text-base items-center ">
                <span className=" font-normal">
                  {values[0]?.language?.translation[i18next.language]}
                </span>
              </p>

              {values[0]?.price ? (
                <p className="inline-flex space-x-1">
                  <span>{currency.prefix}</span>
                  <NumericFormat
                    value={values[0]?.price[currency.dbKey]}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </p>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <p className="text-[14px] md:text-base">
                {t("Selected")} {title}
              </p>
              <p className="inline-flex space-x-1">
                <span>{currency.prefix}</span>
                <NumericFormat value={totalPrice} displayType={"text"} thousandSeparator={true} />
              </p>
            </>
          )}
        </div>
        {[FormType.MultipleRelation, FormType.MultipleNoneRelation].includes(formType) ? (
          <div className="flex flex-col space-y-3">
            {formGroups?.map((group) => (
              <div className="flex flex-col space-y-1" key={group?.formId}>
                <p className="font-bold text-base md:text-xl">{groupName(group) || ""}</p>
                <ul
                  role="button"
                  className="grid grid-cols-4 h-tablet:grid-cols-3 gap-2 md:gap-x-5 md:gap-y-4 pt-3 pb-2">
                  {options
                    .filter((option) => option.formId === group.formId)
                    .map((item, index) => (
                      <li
                        onClick={() => handleOnChange(item, group?.actionType || actionType)}
                        key={index}>
                        <CardProperty
                          image={item?.image}
                          name={item.language?.translation[i18next.language] || ""}
                          currencyPrefix={currency.prefix}
                          price={item?.price[currency.dbKey]}
                          isShowCost={layout === DisplayLayout.GridSelectorLayoutSumCost}
                          isActive={isActive(item._id)}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <ul
            role="button"
            className="grid grid-cols-4  h-tablet:grid-cols-3 gap-2 md:gap-x-5 md:gap-y-4 pt-3 pb-2">
            {options.map((item, index) => (
              <li onClick={() => handleOnChange(item, actionType)} key={index}>
                <CardProperty
                  image={item?.image}
                  name={item.language?.translation[i18next.language] || ""}
                  currencyPrefix={currency.prefix}
                  price={item?.price[currency.dbKey]}
                  isShowCost={layout === DisplayLayout.GridSelectorLayoutSumCost}
                  isActive={isActive(item._id)}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default GridSelectorLayout;
