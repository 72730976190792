import { createAsyncThunk } from "@reduxjs/toolkit";
import ModelPropertyService from "../../services/modelProperty.service";
import { ModelPropertyNameModule } from "../share";

const modelPropertyService = new ModelPropertyService();

export const fetchPropertiesByModelId = createAsyncThunk(
  `${ModelPropertyNameModule}/fetchPropertiesByModelId`,
  async (modelId, thunkAPI) => {
    try {
      const result = await modelPropertyService.getModelPropertyListByModelId(modelId);
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);
