import TextNameSection from "../../../shared/components/TextNameSection";

const CardProperty = ({ isActive, image, name, currencyPrefix, price, isShowCost }) => {
  return (
    <div className="relative h-full">
      <div
        className={`flex flex-col h-full py-2 md:p-3 rounded-md space-y-1 md:space-y-3 items-center ${
          isActive ? "bg-white bg-opacity-40 " : "bg-white bg-opacity-0  hover:bg-opacity-40 "
        }`}>
        <div className="relative">
          <img
            className={` ${
              isShowCost ? "w-16 h-16" : "w-10 h-10"
            }  md:w-[100px] md:h-[100px] object-cover relative`}
            src={image}
          />
          {isShowCost ? (
            <div className="absolute bottom-0 md:bottom-2 w-full flex justify-center">
              <div className=" bg-opacity-80 md:bg-opacity-100 bg-gray-500 self-center rounded-full md:rounded-lg px-1 md:px-2 text-white">
                <p className="inline-flex space-x-1 text-[10px] md:text-sm leading-0">
                  <span>+</span>
                  <span>{currencyPrefix}</span> <span>{price}</span>
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <p className="text-center text-xs  whitespace-pre-line">
          <TextNameSection value={name} />
        </p>
      </div>
      <div className="bottom-0 w-full flex justify-center">
        <div
          className={`${isActive ? "bg-white" : " bg-transparent"} h-1 w-[50px] rounded-lg `}></div>
      </div>
    </div>
  );
};

export default CardProperty;
