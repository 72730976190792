import http from "../core/http.core";

export class ProductModel {
  constructor() {
    this.path = "product-models";
  }

  async getList() {
    const { data } = await http.get(this.path);
    return data;
  }

  async getOne(modelId) {
    const { data } = await http.get(`${this.path}/model/${modelId}`);
    return data;
  }
}
