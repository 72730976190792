import { t } from "i18next";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../../core/env.core";
import { StatusData } from "../../models/module";
import OrderService from "../../services/order.service";
import ErrorDialog from "../shared/components/ErrorDialog";

import LoadingTemplate from "../shared/templates/LoadingTemplate";
import RootTemplate from "../shared/templates/RootTemplate";

const orderService = new OrderService();

const CompleteOrder = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState();

  const [state, setState] = useState(StatusData.idle);
  const [errorDialog, setErrorDialog] = useState({ isOpen: false });

  const authenticationState = useSelector((state) => state.authentication);

  useEffect(() => {
    if (orderId) return;
    navigate("/");
  }, [navigate, orderId]);

  const fetchingOrderApi = useCallback(async () => {
    try {
      // setIsLoading(true);
      setState(StatusData.pending);
      const order = await orderService.getOrderDetail(orderId);

      setOrder(order);
      setState(StatusData.succeeded);
    } catch (error) {
      setState(StatusData.failed);
      setErrorDialog({
        isOpen: true,
        message: t("Cannot open this page. please click the close button to redirect to main page.")
      });
    }
  }, [orderId]);

  useEffect(() => {
    if (!authenticationState.status || !authenticationState.token || !orderId) return;
    fetchingOrderApi();
  }, [authenticationState.status, authenticationState.token, fetchingOrderApi, orderId]);

  const getCssBackground = (env) => {
    return env.backgroundColor;
  };

  const getCssCard = (env) => {
    const [from, to] = env.backgroundCard;
    const bg = `linear-gradient(315deg, ${from} 0%, ${to} 100%)`;
    return { backgroundImage: bg, color: env.primaryFontColor };
  };

  return (
    <>
      {state === StatusData.pending ? <LoadingTemplate /> : <></>}
      {state === StatusData.succeeded ? (
        <RootTemplate
          style={{ backgroundColor: order.env_config.backgroundColor }}
          className={`flex flex-col  xl:flex-row  xl:items-stretch relative  ${getCssBackground(
            order.env_config
          )}`}>
          <div className="h-auto lg:h-1/2  xl:flex-1 ">
            <img
              src={order.customization_img}
              className="w-full  object-cover aspect-4/3 lg:aspect-none xl:h-screen  xl:aspect-auto bg-transparent"
            />
          </div>

          <div
            style={getCssCard(order.env_config)}
            className="flex-1 w-full lg:h-1/2 xl:h-full xl:w-[507px] md:flex-none overflow-auto">
            <div className="flex flex-col h-full gap-y-1 md:gap-y-8 px-5 py-2 md:py-5 justify-center lg:mx-auto lg:container lg:max-w-3xl xl:max-w-none">
              <p className="text-4xl font-bold pb-1">{t("THANK YOU")}</p>
              <div className="flex flex-col space-y-5">
                <p className="text-xl md:text-2xl font-bold">
                  {t("Order number")} : {order.order_code}
                </p>
                <p className="py-3  md:pt-0">
                  {t(
                    "Your payment was successful and your order is completed. Order confirmation has been sent to your e-mail"
                  )}
                </p>
              </div>
              <div className="flex flex-col space-y-5 items-stretch  md:pb-10">
                <a
                  href={order.customization_pdf}
                  target="_blank"
                  rel="noreferrer"
                  className="h-[57px] bg-button-color bg-opacity-40 rounded flex justify-center items-center">
                  {t("Download order information")}
                </a>
                <div className="flex flex-col items-stretch md:flex-row gap-5 md:items-center">
                  <a
                    href={config.zapp_main_page_url}
                    className="md:flex-1 h-[57px] bg-button-color bg-opacity-40 rounded flex justify-center items-center">
                    {t("Go to main page")}
                  </a>
                  <Link
                    to="../reservation"
                    className=" md:flex-1 h-[57px] bg-button-color bg-opacity-40 rounded flex justify-center items-center">
                    {t("Order reservation")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </RootTemplate>
      ) : (
        <></>
      )}
      <ErrorDialog
        isOpen={errorDialog.isOpen}
        message={errorDialog.message}
        closeModal={() => {
          setErrorDialog({ isOpen: false });
          navigate("/");
        }}
      />
    </>
  );
};

export default CompleteOrder;
