import { t } from "i18next";
import ModalBarrier from "../ModalBarrier";

const ShareLinkModal = ({ link, onClose }) => {
  const clipboard = () => {
    navigator.clipboard.writeText(link);
    onClose();
  };
  return (
    <ModalBarrier onClose={onClose}>
      <div className="border-none shadow-lg z-50 relative flex flex-col w-96 pointer-events-auto px-5 bg-white bg-clip-padding rounded-md outline-none text-current">
        <div className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
          <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
            {t("Share your bike")}
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onClose}>
            close
          </button>
        </div>
        <div className="modal-body relative p-4 flex flex-col items-stretch space-y-2">
          <p className=" text-sm">{t("Copy link to share")}</p>
          <div className=" px-2 py-2 rounded bg-[#F9F9F9] border border-[#E1E1E1]">
            <p className="text-left truncate text-sm text-[#989898]">{link}</p>
          </div>
        </div>
        <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
          <button
            type="button"
            className="px-6
      py-2.5
      bg-primary
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-primary hover:shadow-lg
      focus:bg-primary focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-primary active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1"
            onClick={clipboard}>
            {t("Copy Link")}
          </button>
        </div>
      </div>
    </ModalBarrier>
  );
};

export default ShareLinkModal;
