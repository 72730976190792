import { createSlice } from "@reduxjs/toolkit";
import { StatusData } from "../../models/module";
import { OrdersModule } from "../share";
import { fetchOrderInfoById } from "./thunk";

const initialState = {
  currentOrderInfo: {
    status: StatusData.idle,
    data: null,
    error: null
  }
};

const orderSlice = createSlice({
  name: OrdersModule,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchOrderInfoById.pending, (state) => {
      state.currentOrderInfo.status = StatusData.pending;
    });
    builder.addCase(fetchOrderInfoById.fulfilled, (state, action) => {
      state.currentOrderInfo.status = StatusData.succeeded;
      state.currentOrderInfo.data = action.payload;
      // state.currentOrderInfo = {
      //   status: StatusData.,
      //   data: action.payload
      // };
    });
    builder.addCase(fetchOrderInfoById.rejected, (state, action) => {
      state.currentOrderInfo.status = StatusData.failed;
      state.currentOrderInfo.error = action.payload;
    });
  }
});

export default orderSlice.reducer;
