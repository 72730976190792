export const ProductModelModule = "productModel";
export const CustomizationPartsNameModule = "customization";
export const ModelPropertyNameModule = "modelProperty";
export const SummaryModule = "summary";
export const AuthenticationModule = "authentication";
export const TempCustomizationModule = "tempCustomization";
export const CurrencyModule = "currency";
export const AssetModule = "assets";
export const ShardCustomizationModule = "sharedCustomization";
export const OrdersModule = "order";
export const UserModule = "user";
