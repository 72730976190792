/* eslint-disable no-undef */

const config = {
  node_env: process.env.REACT_APP_ENV,
  zapp_model_url: process.env.REACT_APP_ZAPP_MODEL_URL,
  zapp_domain_name: process.env.REACT_APP_DOMAIN_NAME,
  zapp_crm_url: process.env.REACT_APP_ZAPP_CRM_URL,
  domain: process.env.REACT_APP_DOMAIN_NAME,
  domain_front_end: process.env.REACT_APP_DOMAIN_NAME_FRONT,
  zapp_main_page_url: process.env.REACT_APP_ZAPP_MAIN_PAGE_URL,
  gtm_id: process.env.REACT_APP_GTM_TAG_ID
};

export default config;
