import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import config from "./env.core";
// TODO: Replace the following with your app's Firebase project configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyAWSv_UA5xKVSuJl40cts-d8YXyRA52Smc",
//     authDomain: "cosmos-1bc9a.firebaseapp.com",
//     projectId: "cosmos-1bc9a",
//     storageBucket: "cosmos-1bc9a.appspot.com",
//     messagingSenderId: "949248222285",
//     appId: "1:949248222285:web:97c0de0d6ccaf7ad862804"
// };

const firebaseConfig =
  config.node_env === "prod"
    ? {
        apiKey: "AIzaSyCkS9N5QmxsIyenr2u4qm_IB0tAf8j0nUg",
        authDomain: "zappev-b073f.firebaseapp.com",
        projectId: "zappev-b073f",
        storageBucket: "zappev-b073f.appspot.com",
        messagingSenderId: "1056220373835",
        appId: "1:1056220373835:web:7c3060dd6dec5b0ea63ece",
        measurementId: "G-M5DW9257S7"
      }
    : {
        apiKey: "AIzaSyBGZT_Ev6mD6udZ8MDOx6oUBaO8pxOPbPk",
        authDomain: "zappev-crm.firebaseapp.com",
        projectId: "zappev-crm",
        storageBucket: "zappev-crm.appspot.com",
        messagingSenderId: "52632289105",
        appId: "1:52632289105:web:20f1fdf08aa009d6c81d70"
      };

const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
export default auth;
