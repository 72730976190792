import { NumericFormat } from "react-number-format";
const SummaryItem = ({ title, value, currency }) => {
  return (
    <div className="flex items-center justify-between">
      <p className="font-light">{title}</p>
      <p className="font-bold inline-block space-x-2">
        <span>{currency}</span>
        <NumericFormat value={value} displayType={"text"} thousandSeparator={true} />
      </p>
    </div>
  );
};

export default SummaryItem;
