import { memo } from "react";
import { useTranslation } from "react-i18next";
import { StepperPage } from "../../../../models/stepper";

const StepItem = ({ title, active }) => {
  return (
    <div className={`flex space-x-2 items-center ${active ? "text-black" : "text-white"}`}>
      <div
        className={`rounded-full transition duration-500 ease-in-out p-1 ${
          active ? "bg-black text-white" : " hidden"
        }`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-3 h-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
      </div>
      <p className="text-sm text-center">{title}</p>
    </div>
  );
};

const MiddleLine = ({ active }) => {
  return <p className={`${active ? "text-black" : "text-white"}`}>/</p>;
};

const StepperHeader = ({ value, onChange, onClickSelectModel }) => {
  const { t } = useTranslation();

  return (
    <ul
      role="button"
      className="flex items-center px-3 py-3 space-x-3 justify-center"
      data-mdb-stepper="stepper">
      <li onClick={onClickSelectModel}>
        <StepItem active={value >= StepperPage.selectModel} title={t("Select")} />
      </li>
      <MiddleLine active={value > StepperPage.selectModel} />
      <li onClick={() => onChange(StepperPage.customization)}>
        <StepItem active={value >= StepperPage.customization} title={t("Personalise")} />
      </li>
      <MiddleLine active={value > StepperPage.personalize} />
      <li onClick={() => onChange(StepperPage.reserve)}>
        <StepItem active={value >= StepperPage.reserve} title={t("Reserve")} />
      </li>
    </ul>
  );
};

export default memo(StepperHeader);
