export const SketchFabUIConfig = {
  ui_controls: false,
  ui_animations: false,
  ui_inspector: false,
  ui_ar: false,
  ui_help: false,
  ui_settings: false,
  ui_fullscreen: false,
  ui_annotations: false,
  ui_vr: false,
  ui_infos: false,
  ui_general_controls: false,
  ui_fadeout: false,
  ui_watermark: false,
  ui_stop: false
};
