import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProductModel } from "../../services/productModel.service";
import { ProductModelModule } from "../share";

const productModel = new ProductModel();

export const fetchProductModelAll = createAsyncThunk(
  `${ProductModelModule}/fetchProductModelAll`,
  async (thunkAPI) => {
    try {
      const result = await productModel.getList();
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const fetchProductDetail = createAsyncThunk(
  `${ProductModelModule}/fetchProductDetail`,
  async (modelId, thunkAPI) => {
    try {
      const result = await productModel.getOne(modelId);
      return result;
    } catch (error) {
      thunkAPI.rejectWithValue(error.toString());
    }
  }
);
