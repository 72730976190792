import http from "../core/http.crm.core";

class OrderService {
  constructor() {}

  async reserveOrderAndSignUp(props) {
    const { data } = await http.post("customer/signUpAndReserve", props);
    return data;
  }

  async getOrderDetail(orderId) {
    const { data } = await http.get(`order/${orderId}`);
    return data;
  }

  async updateConfiguration(orderId, body) {
    const { data } = await http.patch(`order/${orderId}`, body);
    return data;
  }

  async reserveOrder(props) {
    const { data } = await http.post("payment/reserve", props);
    return data;
  }
}

export default OrderService;
