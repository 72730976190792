/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState, Fragment, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import { Listbox, Transition, Combobox } from "@headlessui/react";
import { fetchCountryListAsset } from "../../../../src/modules/assets/thunk";
import { useForm } from "react-hook-form";
import withReactContent from "sweetalert2-react-content";
import "../index.css";
import { t } from "i18next";
function ProfileUser() {
  const MySwal = withReactContent(Swal);
  const [codePhoneList, setCodePhoneList] = useState([]);
  const [codePhone, setCodePhone] = useState("");
  const [ActionEdit, setActionEdit] = useState(false);
  const [formData, setFormData] = useState([]);
  const [errorFname, setErrorFname] = useState(null);
  const [errorLname, setErrorLname] = useState(null);
  const [errorPhone, setErrorPhone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dialCode, setDialCode] = useState(null);
  const { setValue } = useForm();
  const [query, setQuery] = useState("");
  const { status: countriesStatus, data: countiesData } = useSelector(
    (state) => state.assets.countries
  );
  const ClickEdit = () => {
    setActionEdit(!ActionEdit);
  };
  useEffect(() => {
    const token = window.sessionStorage.getItem("authenticationToken");
    axios
      .get(process.env.REACT_APP_DOMAIN_NAME + "/crm/countryCode/all", {
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      })
      .then(function (response) {
        if (response.status === 200) {
          setCodePhoneList(response.data);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
    axios
      .get(process.env.REACT_APP_DOMAIN_NAME + "/crm/account/info", {
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      })
      .then(function (response) {
        if (response.status === 200) {
          const d = response.data;
          const Value = {
            email: d.email,
            first_name: d.first_name,
            last_name: d.last_name,
            dial_code: d.dial_code,
            phone: d.phone
          };
          setCodePhone(d.dial_code);
          setFormData(Value);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);
  const filteredCountry = useMemo(() => {
    if (!countiesData) return [];
    return query === ""
      ? countiesData
      : countiesData.filter((country) =>
          country.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  }, [countiesData, query]);
  const handleClick = (event) => {
    event.preventDefault();
    if (!formData.dial_code) {
      setErrorPhone(t("This field is required."));
    } else if (!formData.phone) {
      setErrorPhone(t("This field is required."));
    } else if (isNaN(formData.phone)) {
      setErrorPhone(t("Number only."));
    }
    if (!formData.first_name) {
      setErrorFname(t("This field is required."));
    }
    if (!formData.last_name) {
      setErrorLname(t("This field is required."));
    }

    if (formData.dial_code && formData.phone && formData.first_name && formData.last_name) {
      setIsLoading(true);
      const getValURL = process.env.REACT_APP_DOMAIN_NAME + "/crm/account/info";
      const token = window.sessionStorage.getItem("authenticationToken");
      const request = {
        url: getValURL,
        method: "patch",
        data: formData,
        headers: {
          "content-type": "application/json",
          Authorization: token
        }
      };
      axios(request)
        .then(function (response) {
          setFormData(response.data);
          setIsLoading(false);
          MySwal.fire({
            title: "saved successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            setActionEdit(!ActionEdit);
          });
        })
        .catch(function (error) {
          MySwal.fire({
            title: error,
            icon: "error",
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            setIsLoading(false);
          });
        });
    }
  };
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    if (event.target.name === "dial_code") {
      setCodePhone(event.target.value);
      if (event.target.value === null) {
        setErrorPhone(t("This field is required."));
      } else {
        setErrorPhone(null);
      }
    }
    if (event.target.name === "first_name") {
      if (event.target.value === null) {
        setErrorFname(t("This field is required."));
      } else {
        setErrorFname(null);
      }
    }
    if (event.target.name === "last_name") {
      if (event.target.value === null) {
        setErrorLname(t("This field is required."));
      } else {
        setErrorLname(null);
      }
    }
    if (event.target.name === "phone") {
      if (isNaN(event.target.value)) {
        setErrorPhone(t("Number only."));
      } else if (event.target.value === null) {
        setErrorPhone(t("This field is required."));
      } else {
        setErrorPhone(null);
      }
    }
  };
  return (
    <div>
      <div className="flex">
        <h2 className="text-2xl mb-3 text-center sm:text-center md:text-center lg:text-left">
          {t("Profile")}
        </h2>
        <button className="text-sm underline text-lg font-bold ml-auto mt-1" onClick={ClickEdit}>
          {ActionEdit === false && t("Edit")}
          {ActionEdit === true && t("Cancel")}
        </button>
      </div>
      <hr></hr>
      <div className="form-container flex flex-wrap justify-center py-6">
        {ActionEdit == true && (
          <div className="xl:container mx-auto  px-1 md:px-5 py-5 pt-8">
            <div className="w-full">
              <form onSubmit={handleClick}>
                <div className="flex flex-wrap -mx-3 mb-3 items-center">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                    <label
                      className="block text-lg tracking-wide text-gray-700 font-bold"
                      htmlFor="first_name">
                      {t("First Name")}
                    </label>
                  </div>
                  <div className="w-full md:w-3/6 px-3">
                    <input
                      className="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      value={formData.first_name || ""}
                      name="first_name"
                      onChange={handleChange}
                      type="text"
                      placeholder={t("First Name")}
                    />
                  </div>
                  <div className="w-full md:w-2/6 px-3">
                    {errorFname && <p className="text-red text-sm">{errorFname}</p>}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3 items-center">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                    <label
                      className="block text-lg tracking-wide text-gray-700 font-bold"
                      htmlFor="last_name">
                      {t("Last name")}
                    </label>
                  </div>
                  <div className="w-full md:w-3/6 px-3">
                    <input
                      className="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      value={formData.last_name || ""}
                      name="last_name"
                      onChange={handleChange}
                      type="text"
                      placeholder={t("Last name")}
                    />
                  </div>
                  <div className="w-full md:w-2/6 px-3">
                    {errorLname && <p className="text-red text-sm">{errorLname}</p>}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3 items-center">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                    <label
                      className="block text-lg tracking-wide text-gray-700 font-bold"
                      htmlFor="email">
                      {t("E-mail")}
                    </label>
                  </div>
                  <div className="w-full md:w-3/6 px-3">
                    <p className=" text-regular">{formData.email || "-"}</p>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3 items-center">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                    <label
                      className="block text-lg tracking-wide text-gray-700 font-bold"
                      htmlFor="phone">
                      {t("Mobile phone")}
                    </label>
                  </div>
                  <div className="w-full md:w-3/6 px-3 flex flex-wrap">
                    <div className="w-full md:w-1/6">
                      <div className="relative">
                        {/* <select
                          className="block mh46 appearance-none w-full bg-white border border-gray-400  px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline"
                          onChange={handleChange}
                          value={codePhone || ""}
                          name="dial_code">
                          <option key="1" value="">
                            {" "}
                            --
                          </option>
                          {codePhoneList.map((option) => (
                            <option key={option._id} value={option.dial_code}>
                              {option.dial_code}
                            </option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true">
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div> */}
                        <Listbox
                          value={dialCode}
                          onChange={(v) => {
                            setDialCode(v);
                            setValue("dialCode", v.dial_code, { shouldValidate: true });
                            setFormData({ ...formData, dial_code: v.dial_code });
                            console.log("v.dial_code", formData);
                          }}>
                          <div className="relative">
                            <Listbox.Button className="relative w-full mh46 cursor-default rounded bg-white  h-[43px] px-3 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <div className=" flex justify-between space-x-1">
                                <p className="block truncate">{formData?.dial_code}</p>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5">
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0">
                              {countiesData ? (
                                <Listbox.Options className="absolute mt-1 max-h-60 overflow-auto rounded divide-y bg-white py-1 text-base z-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {countiesData?.map((country, idx) => (
                                    <Listbox.Option
                                      key={idx}
                                      role="button"
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 px-2 flex ${
                                          active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                                        }`
                                      }
                                      value={country}>
                                      {({ selected }) => (
                                        <>
                                          <div className="flex-1">
                                            <span
                                              className={`block truncate ${
                                                selected ? "font-medium" : "font-normal"
                                              }`}>
                                              {country.name}
                                            </span>
                                          </div>
                                          <span>{country.dial_code}</span>
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              ) : (
                                <></>
                              )}
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                    <div className="w-full md:w-5/6 ">
                      <input
                        className="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        value={formData.phone || ""}
                        name="phone"
                        onChange={handleChange}
                        type="text"
                        placeholder={t("Mobile phone")}
                      />
                    </div>
                  </div>

                  <div className="w-full md:w-2/6 px-3">
                    {errorPhone && <p className="text-red text-sm">{errorPhone}</p>}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3 py-3">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0"></div>
                  <div className="w-full md:w-3/6 px-3">
                    <button
                      type="submit"
                      className="btn-profile rounded-md bg-main text-white font-regular py-2 px-8">
                      {isLoading ? <span className="loader" /> : t("Save")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {ActionEdit == false && (
          <div className="xl:container mx-auto  px-1 md:px-5 py-5 pt-8">
            <div className="w-full">
              <form>
                <div className="flex flex-wrap -mx-3 mb-3 items-center">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                    <label className="block text-lg tracking-wide text-gray-700 font-bold">
                      {t("First Name")}
                    </label>
                  </div>
                  <div className="w-full md:w-3/6 px-3">
                    <p className=" text-regular">{formData.first_name || ""}</p>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3 items-center">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                    <label className="block text-lg tracking-wide text-gray-700 font-bold">
                      {t("Last name")}
                    </label>
                  </div>
                  <div className="w-full md:w-3/6 px-3">
                    <p className=" text-regular">{formData.last_name || ""}</p>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3 items-center">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                    <label className="block text-lg tracking-wide text-gray-700 font-bold">
                      {t("E-mail")}
                    </label>
                  </div>
                  <div className="w-full md:w-3/6 px-3">
                    <p className=" text-regular">{formData.email || ""}</p>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3 items-center">
                  <div className="w-full md:w-1/6 px-3 mb-4 md:mb-0">
                    <label className="block text-lg tracking-wide text-gray-700 font-bold">
                      {t("Mobile phone")}
                    </label>
                  </div>
                  <div className="w-full md:w-3/6 px-3">
                    <p className=" text-regular">
                      {formData.dial_code || ""} {formData.phone || "-"}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileUser;
